import { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import listRoutes from "routes";
import { useSoftUIController } from "context";
import { AdminRouter } from "router/AdminRoute";
import { AppBar, Box } from "@mui/material";
import { Layout_Config } from "config";
import CNavTop from "components/CNavTop";
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { useState } from "react";
import { useDispatch } from "react-redux";


export default function App() {
  const [controller] = useSoftUIController();
  const { direction } = controller;
  const { pathname } = useLocation();
  const [modeView, setModeView] = useState(null)
 

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  


  // === UI RENDER ===
  const CommonLayout = ({ children }) => {

    return (
      <>
        <AppBar
          position="sticky"
          color="inherit"
          sx={{
            backgroundColor: rgba(colors['white'].main, 0.8),
            paddingBottom: pxToRem(8),
            top: 0,
            backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
            minWidth: `${Layout_Config.minWidth} !important`
          }}
        >
          <CNavTop companyDetail={null} />

        </AppBar>
        <Box component={'div'} sx={{ width: `${Layout_Config.minWidth}`, margin: '0 auto' }}>
          {children}
        </Box>
      </>

    )
  }

  const getRoutes = (allRoutes, role) =>
    allRoutes.map((route) => {
      if (role === 'admin') {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.route) {
          return <AdminRouter exact path={route.route} component={route.component} key={route.key} />;
        }
      } else {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.route) {
          return <Route exact path={route.route} component={route.component} key={route.key} />;
        }
      }
      return null;
    });

  // const MobileLayout = () =>{
  //   return (
  //     <h1>
  //       Test
  //       {getRoutes(listRoutes.guest, 'guest')}
  //     </h1>
      
  //   )
  // }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>

        {/* <CommonLayout> */}
        {getRoutes(listRoutes.guest, 'guest')}
        {/* </CommonLayout> */}
        {/* <Route path="/" component={MobileLayout} /> */}

        {getRoutes(listRoutes.admin, 'admin')}
        {getRoutes(listRoutes.guard, 'guard')}
        <Redirect from="*" to="/" />
      </Switch>
    </ThemeProvider>
  );
}
