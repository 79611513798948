import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material"
import Header from "components/Header"
import Footer from "components/Footer"
import SuiBox from "components/SuiBox"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import commonNewsServices from "redux/common/news"
import SuiTypography from "components/SuiTypography"
import i18n from "services/language/i18n"
import moment from "moment"
import TodayIcon from '@mui/icons-material/Today';
import CBreadCrumbs from "components/CBreadCrumbs"
import NewsCategory from "../news-category"
import styles from "./styles"
import { Data_Pageable } from "config"
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { rewriteUrlPath } from "utils/convertPathUrl"
import ProductCategory from "layouts/pages/product/product-category"
import { getModeView } from "utils/modeView"
import commonStyles from 'common/styles'
import CSelector from 'components/CSeletor'
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const NewsDetails = (props) => {
    const { t } = useTranslation();
    const newsId = Number(props.match.params.newsId);
    const queryParams = new URLSearchParams(window.location.search)
    const isCompanyRef = Boolean(queryParams.get('companyRef'))

    const classes = styles()

    const [modeView, setModeView] = useState(null)
    const [newsInfo, setNewsInfo] = useState(null)
    const [newsList, setNewsList] = useState([])
    const [category, setCategory] = useState(null)
    const [subCategories, setSubCategories] = useState(null)
    const [sameNews, setSameNews] = useState(null)
    const commonClasses = commonStyles()
    const history = useHistory();

    useEffect(() => {
        setModeView(getModeView())
    }, [])

    useEffect(() => {
        getNewsDetails()
        getNewsList()

    }, [])

    const onChangeSubCategory = (item) => {
        history.push(`/list-news/${rewriteUrlPath(item?.nameVi)}.html&query=${item?.newsCategoryId}`)
    }

    const getNewsList = async () => {
        let res = await commonNewsServices.listNews();
        setNewsList(res)
    }

    const getNewsDetails = async () => {
        let res = await commonNewsServices.getNewsById(newsId);
        if (res) {
            //get category

            await getCategory(res?.subNewsCategory?.newsCategoryId, res?.newsId)
            // 
            setNewsInfo(res)
        }
    }
    const getCategory = async (categoryId, currentNewsId) => {
        if (categoryId) {
            let res = await commonNewsServices.getNewsCategoryById(categoryId)
            if (res) {
                setCategory(res)

                if (res.subNewCategory && res.subNewCategory.length > 0) {
                    setSubCategories(res.subNewCategory)
                }

            }
            await getNewsByCategory(categoryId, currentNewsId)
        }
    }

    const getNewsByCategory = async (categoryId, currentNewsId) => {
        if (categoryId) {
            let param = {
                page: 1,
                maxItems: `${Data_Pageable.itemPerPage}`,
                categoryId: categoryId
            }
            let res = await commonNewsServices.getNewsByFilter(param)
            if (res) {
                // ignore current new
                let data = [...res.data]
                console.log(data)
                let sameNews = data.filter((newsItem, idex) => newsItem.newsId != currentNewsId)
                setSameNews(sameNews)
            }
        }
    }
    return (
        <>
            <Header />
            <Box component={'div'} className={classes.conWrapper} display={modeView?.desktop}>
                <Box component={'div'} mb={2}>
                    {category && <CBreadCrumbs isNewsPage={true} category={category} />}
                </Box>
                {
                    isCompanyRef ?
                        <Box component={'div'} width={'90px'} sx={{ float: 'left' }}>
                            <ProductCategory />
                        </Box>
                        :
                        <Box component={'div'} className={classes.boxSideNavLeft} mt={2} mb={10}>
                            {subCategories && <NewsCategory
                                listCategory={subCategories}
                            />}

                        </Box>
                }
                {
                    isCompanyRef &&
                    <Box component={'div'} sx={{ width: '1170px', float: 'right' }} >
                        <Typography component={'h1'} sx={{ textTransform: 'uppercase' }} className={classes.textCompanyRefTag}>
                            {i18n.language === 'en' ? newsInfo?.titleEn : newsInfo?.titleVi}
                        </Typography>
                    </Box>
                }
                <Box component={'div'} sx={{ width: isCompanyRef ? '1170px' : '990px' }} className={classes.conContent} px={3} mb={3}>
                    {newsInfo &&
                        <SuiBox>
                            {/* <SuiBox
                                component="img"
                                src={newsInfo?.preview ? newsInfo?.preview : ''}
                                height={300}
                                width={'100%'}
                                sx={{ cursor: "pointer", objectFit: "cover", objectPosition: "center" }}
                            /> */}
                            <SuiBox display="flex" flexDirection="column">
                                {
                                    !isCompanyRef && (
                                        <Typography variant="h3" className={classes.textNewsTitle}>{i18n.language === 'en' ? newsInfo.titleEn : newsInfo.titleVi}</Typography>
                                    )
                                }
                                {
                                    !isCompanyRef && (
                                        <SuiBox display="flex" alignItems="center" >
                                            <TodayIcon />
                                            <SuiTypography variant="span" className={classes.textSubInfo}>{moment(newsInfo.lastEdit).format('HH:mm, MMMM Do YYYY')}</SuiTypography>
                                        </SuiBox>
                                    )
                                }
                                <SuiBox mb={2}>
                                    <Typography sx={{ fontFamily: 'Arial,Helvetica,sans-serif !important' }} variant="body2" dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? newsInfo.descriptionEn : newsInfo.descriptionVi }} />
                                </SuiBox>
                            </SuiBox>

                        </SuiBox>
                    }
                    {
                        (sameNews && !isCompanyRef) &&
                        <Box>
                            <Typography component={'h4'} className={classes.textSameNewsTitle}>
                                <i className={classes.iconSameNews} />
                                {t('same_news')}
                            </Typography>
                            <Box mt={2}>
                                <List>
                                    {sameNews.map((news, index) => {
                                        return (
                                            <ListItem>
                                                <KeyboardDoubleArrowRightIcon sx={{ color: "#95AAD1 !important" }} />
                                                <Typography mr={1} component={'a'} href={`/news/${rewriteUrlPath(news.titleVi)}.html&query=${news.newsId}`}>{i18n.language === 'vi' ? news.titleVi : news.titleEn}</Typography>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Box>

                        </Box>
                    }
                </Box>
                <div style={{ clear: 'both' }} />
                <Footer />
            </Box>

            <Box display={modeView?.mobile} className={commonClasses.conMobileWrapper}>
                <Box component={'div'}>
                    <CBreadCrumbs isNewsPage={true} category={category} />
                </Box>
                <Box component={'div'} sx={{ borderBottom: '1px solid #d3d3d3' }}>
                    {
                        subCategories && category &&
                        <CSelector
                            listItems={subCategories}
                            placeholder={{
                                vi: category?.nameVi,
                                en: category?.nameEn
                            }}
                            fieldNameRenders={
                                {
                                    vi: 'nameVi',
                                    en: 'nameEn'
                                }
                            }
                            onChangeSelector={onChangeSubCategory}
                        />

                    }

                </Box>
                <Box component={'div'}>
                    <Typography component={'h1'} className={classes.mTitleText}>
                        {i18n.language === 'en' ? newsInfo?.titleEn : newsInfo?.titleVi}
                    </Typography>
                    <SuiTypography variant="span" className={classes.textSubInfo}>{newsInfo?.lastEdit && moment(newsInfo?.lastEdit).format('HH:mm, MMMM Do YYYY')}</SuiTypography>
                    <Typography sx={{ fontFamily: 'Arial,Helvetica,sans-serif !important' }} variant="body2" dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? newsInfo?.descriptionEn : newsInfo?.descriptionVi }} />
                </Box>
                <Footer />
            </Box>

        </>
    )
}

export default NewsDetails