import { Box,  List, ListItem, Typography } from '@mui/material'
import { t } from 'i18next'
import ContactDetail from 'layouts/pages/customer/contact/component/ContactDetail'
import i18n from 'services/language/i18n'
import { makeStyles } from '@mui/styles';
import { COLOR } from 'constant'
import colors from 'assets/theme/base/colors'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { rewriteUrlPath } from 'utils/convertPathUrl'


const NewsCategory = ({ listCategory = [], categorySelected = null, subCategorySelected = null, onSelectCategory = () => { }, onSelectSubCategory = () => { } }) => {
    const classes = styles();
    const { t } = useTranslation()
    return (
        <Box component={'div'} className={classes.conBox}>
            <Box component={'div'} className={classes.conTitle} display={'flex'} alignItems={'center'}>
                <NewspaperIcon sx={{ fontSize: '18px !important' }} />
                {t('list_new')}
            </Box>
            <Box component={'div'}>
                <List>
                    {
                        listCategory.map((category, index) => {
                            return (
                                <ListItem key={`new_category_${index}`} className={classes.conNewsItem}>
                                    <Typography component={'a'} href={`/list-news/${rewriteUrlPath(category.nameVi)}.html&query=${category.newsCategoryId}`} className={classes.linkNewsCategory}>
                                        {i18n.language === 'en' ? category.nameEn : category.nameVi}
                                    </Typography>
                                    <NavigateNextIcon />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Box>
        </Box>
    )
}

export default NewsCategory