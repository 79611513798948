import instance from "services/axios"
import { PUBLIC_API } from 'redux/api'
import { SET_COMPANY_DETAILS } from "redux/types";
import { setItemWithExpiry } from "utils/localStorage";
import { getItemWithExpiry } from "utils/localStorage";

const commonCompanyServices = {
    getInformation: async (dispatch) => {
        try {
            let res = await instance.get(PUBLIC_API.COMPANY);
            dispatch({ type: SET_COMPANY_DETAILS, payload: res.data.data })
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    getCompanyInfoWithCache: async (dispatch) => {
        try {
            //get in cache
            let companyInfo = getItemWithExpiry('companyInfo')

            if (!companyInfo) {
                let res = await instance.get(PUBLIC_API.COMPANY);

                companyInfo = res.status === 200 && res.data ? res.data.data : null

                setItemWithExpiry('companyInfo', companyInfo, 86400) //24h
            }

            dispatch({ type: SET_COMPANY_DETAILS, payload: companyInfo })

            return companyInfo
        } catch (error) {
            return null
        }
    }

}

export default commonCompanyServices