import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import styles from './styles'
import CCardItemProduct from 'components/CCardItemProduct'
import { item } from 'examples/Sidenav/styles/sidenavItem'
import i18n from 'services/language/i18n'


const CListCardItem = (
    {
        title = 'Sản phẩm mới',
        hasBannerHead = false,
        maxItems = 8,
        listItems = [],
        isNewProduct = false,
        checkBoxCompare = false
    }
) => {

    const classes = styles()
    return (
        <Box>
            <Box mt={1}>
                <Grid container spacing={0}>
                    {listItems.map((item, index) => {
                        return (
                            <Grid item xs={3} md={3} key={`item_product_${index}`}>
                                <CCardItemProduct productItem={item} isNewProduct={isNewProduct} checkBoxCompare={checkBoxCompare} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </Box>
    )
}

export default CListCardItem