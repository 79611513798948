import instance from "services/axios"
import { ADMIN_API } from 'redux/api' 
import { SET_COMPANY_DETAILS } from "redux/types";

const companyServices = {
    getInformation: async (dispatch) => {
        try {
            let res = await instance.get(ADMIN_API.COMPANY);
            dispatch({ type: SET_COMPANY_DETAILS, payload: res.data.data})
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },
    editCompanyInfo: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.UPDATE_COMPANY, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },
}

export default companyServices