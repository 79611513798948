import { Grid, Modal } from "@mui/material"
import modal from "assets/theme/base/modal"
import SuiBox from "components/SuiBox"
import { Player } from '@lottiefiles/react-lottie-player';
import SuiTypography from "components/SuiTypography";
import DeleteIcon from '@mui/icons-material/Delete';
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";
import { LottieAnimation } from "constant";
import { useRef, useState, useEffect } from "react";

const ModalSuccess = ({ open = false, onClose = () => { }, title = '', autoClose = true, duration = 3 }) => {
    const { t } = useTranslation();
    const intervalRef = useRef();
    const [timeLeft, setTimeLeft] = useState(0)

    useEffect(() => {
        if (open) {
            if (autoClose) {
                countTime(duration)
            }
        }
    }, [open])

    useEffect(() => {
        if (timeLeft <= 0) {
            clearInterval(intervalRef.current);
        }
    }, [timeLeft]);

    const countTime = (time) => {
        let seconds = time;
        intervalRef.current = setInterval(() => {
            setTimeLeft(seconds)
            if (--seconds < 0) {
                clearInterval(intervalRef.current);
                onClose()
            }
        }, 1000);
    }

    return (
        <Modal open={open} onClose={onClose} onBackdropClick={onClose} disableAutoFocus>
            <SuiBox sx={{ ...modal }} bgColor="white" borderRadius="md" shadow="md">
                <SuiBox p={3} >
                    <SuiTypography variant="h3" fontWeight="bold" color="success" sx={{ textAlign: 'center'}}>{title !== '' ? title : t('success')}</SuiTypography>
                    <SuiBox my={2}>
                        <Player
                            autoplay={true}
                            loop={true}
                            src={LottieAnimation.success}
                            style={{ width: 200 }}
                        />
                    </SuiBox>
                </SuiBox>
            </SuiBox>
        </Modal>
    )
}

export default ModalSuccess