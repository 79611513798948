import { Grid, Card, Tooltip } from '@mui/material'
import AdminLayout from 'components/Layout/AdminLayout'
import SuiAvatar from 'components/SuiAvatar'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import DataTable from 'examples/Tables/DataTable'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalDelete from 'components/Modal/Delete'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalAddManufacture from './modal/add'
import ModalEditManufacture from './modal/edit'
import manufactuterServices from 'redux/admin/manufacturer'
import SuiTypography from 'components/SuiTypography'
import i18n from 'services/language/i18n'
import ModalSuccess from 'components/Modal/Success'
import ModalError from 'components/Modal/Error'

const ManufactureList = () => {
    const { t } = useTranslation()
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState({ visible: false, data: null })
    const [modalDelete, setModalDelete] = useState({ visible: false, data: null })
    const [responseStatus, setResponseStatus] = useState({ status: '', msg: '' })
    const [data, setData] = useState({
        columns: [
            { Header: "manufacturer_name", accessor: "", align: "left", Cell: ({ row }) => renderCellName(row.original) },
            { Header: "", accessor: "action", align: "center", width: '10%', Cell: ({ row }) => RenderCellActions(row.original) },
        ],
        rows: []
    })

    useEffect(() => {
        getListManufacture()
    }, [])

    const getListManufacture = async () => {
        let res = await manufactuterServices.list();
        setData({ ...data, rows: res })
    }

    const renderCellName = (data) => {
        return (
            <SuiTypography variant="button" fontWeight="medium">
                {i18n.language === 'en' ? data.nameEn : data.nameVi}
            </SuiTypography>
        )
    }

    const RenderCellActions = (data) => {
        return (
            <SuiBox display="flex" gap={1} sx={{ cursor: 'pointer' }}>
                <Tooltip title={t('edit')} placement="top">
                    <SuiBox bgColor="warning" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <EditIcon onClick={() => setModalEdit({ status: true, data: data })} />
                    </SuiBox>
                </Tooltip>
                <Tooltip title={t('delete')} placement="top">
                    <SuiBox bgColor="error" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <DeleteIcon onClick={() => setModalDelete({ visible: true, data: data.manufacturerId })} />
                    </SuiBox>
                </Tooltip>
            </SuiBox>
        )
    }

    const onDeleteManufacturer = async () => {
        let res = await manufactuterServices.delete(modalDelete.data);
        setModalDelete({ visible: false, data: null })
        setResponseStatus({ status: res ? "success" : "error" })
    }

    return (
        <AdminLayout>
            <SuiBox my={10}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <SuiBox pt={3} px={3} display="flex" justifyContent="flex-end">
                                <SuiButton variant="gradient" color="info" size="small" onClick={() => setModalAdd(true)}>
                                    + {t('new_manufacturer')}
                                </SuiButton>
                            </SuiBox>
                            <SuiBox py={1}>
                                <DataTable
                                    table={data}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    isSorted={false}
                                    noEndBorder
                                />
                            </SuiBox>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox>
            <ModalAddManufacture open={modalAdd} onClose={() => setModalAdd(false)} />
            <ModalEditManufacture open={modalEdit.visible} onClose={() => setModalEdit({ visible: false, data: null })} />
            <ModalDelete
                title={t('delete_manufacture_title')}
                open={modalDelete.visible}
                onClose={() => setModalDelete({ visible: false, data: null })}
                onDelete={onDeleteManufacturer}
            />
            <ModalSuccess
                open={responseStatus.status === 'success'}
                onClose={() => setResponseStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={responseStatus.status === 'error'}
                onClose={() => setResponseStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default ManufactureList