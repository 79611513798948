import { Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import styles from './styles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CBreadCrumbs from 'components/CBreadCrumbs'
import { useEffect } from 'react'
import commonNewsServices from 'redux/common/news'
import { useState } from 'react'
import NewsCategory from '../news-category'
import { Data_Pageable } from 'config'
import SuiBox from 'components/SuiBox'
import i18n from 'services/language/i18n'
import TopNewsItem from '../top-news-item'
import { useTranslation } from 'react-i18next'
import { rewriteUrlPath } from "utils/convertPathUrl"
import { getModeView } from "utils/modeView"
import commonStyles from 'common/styles'
import CSelector from 'components/CSeletor'
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const ListNewsPage = (props) => {
    const categoryId = Number(props.match.params.categoryId)
    const classes = styles()
    const { t } = useTranslation()
    const [category, setCategory] = useState(null)
    const [subCategories, setSubCategories] = useState([])
    const [listNews, setListNews] = useState([])
    const [page, setPage] = useState(1)
    const [modeView, setModeView] = useState(null)
    const commonClasses = commonStyles()
    const history = useHistory();


    // Hanlde Api
    const getCategory = async () => {
        if (categoryId) {
            let res = await commonNewsServices.getNewsCategoryById(categoryId)
            console.log(res)
            if (res) {
                setCategory(res)
                if (res.subNewCategory && res.subNewCategory.length > 0) {
                    setSubCategories(res.subNewCategory)
                }
            }
        }
    }

    const onChangeSubCategory = (item) => {
        history.push(`/list-news/${rewriteUrlPath(item?.nameVi)}.html&query=${item?.newsCategoryId}`)
    }

    useEffect(() => {
        setModeView(getModeView())
    }, [])

    const getListNews = async () => {
        if (categoryId) {
            let param = {
                page: page,
                maxItems: `${Data_Pageable.itemPerPage}`,
                categoryId: categoryId
            }
            let res = await commonNewsServices.getNewsByFilter(param)
            if (res && res.data?.length > 0) {
                setListNews(res.data)
            }
        }
    }

    // UI handle
    useEffect(() => {
        if (!category) {
            getCategory()
        }
    }, [category])

    useEffect(() => {
        if (listNews.length === 0) {
            getListNews()
        }
    }, [listNews])

    // === UI RENDER ===
    const renderNewsItem = (news) => {
        return (
            <>
                <ListItem sx={{ height: '130px' }}>
                    <Box component={'a'} href={`/news/${rewriteUrlPath(news.titleVi)}.html&query=${news.newsId}`} className={classes.conListItem}>
                        <ListItemAvatar>
                            <Box component={'img'} src={news?.preview} sx={{ width: '148px' }}>

                            </Box>
                        </ListItemAvatar>
                        <ListItemText className={classes.conTextTitle}>
                            <Typography className={classes.textTitle} component={'a'} href='#'>{i18n.language === 'vi' ? news.titleVi : news.titleEn}</Typography>
                            <Box component={'div'} className={classes.conInfo}>
                                {news.views} {t('views')} - {news.lastEdit}
                            </Box>
                        </ListItemText>
                    </Box>
                </ListItem>
                <Divider />
            </>
        )
    }

    const returnNewsItem = (news, index) => {
        return (
            <Box component={'a'} href={`/news/${rewriteUrlPath(news?.titleEn)}.html&query=${news?.newsId}`} className={classes.conBoxProductItem} key={`news_item_${index}`}>
                <Box component={'div'} sx={{ minWidth: '125px' }}>
                    <Box className={classes.conBoxProductImg}
                        component={'img'}
                        src={news?.preview ? news?.preview : 'http://vietkhanggroup.vn/content/product/product_50_0.jpg'}
                    />
                </Box>
                <Box component={'div'} className={classes.conBoxProductInfo} ml={1}>
                    <Typography className={classes.textProductName}>{i18n.language === 'vi' ? news?.titleVi : news?.titleEn}</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Header />
            <Box component={'div'} className={classes.conWrapper} display={modeView?.desktop}>
                <Box component={'div'}>
                    <CBreadCrumbs isNewsPage={true} category={category} />
                </Box>
                <Box component={'div'} className={classes.boxSideNavLeft} mt={2} mb={10}>
                    <NewsCategory
                        listCategory={subCategories}
                    />
                </Box>
                <Box component={'div'} className={classes.conContent}>
                    <SuiBox bgColor="white" mt={2}>
                        {listNews.length > 0 &&
                            <Box item>
                                <TopNewsItem
                                    title={i18n.language === 'en' ? listNews[0].titleEn : listNews[0].titleVi}
                                    image={listNews[0]?.preview}
                                    sx={{ height: '12rem' }}
                                    id={listNews[0].newsId}
                                    lastEdit={listNews[0].lastEdit}
                                    views={listNews[0].views}
                                />
                            </Box>
                        }
                        <Divider />
                        <Box>
                            <List>
                                {listNews.map((news, index) => {
                                    return renderNewsItem(news)
                                })}
                            </List>
                        </Box>
                    </SuiBox>
                </Box>
                <div style={{ clear: 'both' }} />
                <Footer />
            </Box>
            <Box display={modeView?.mobile} className={commonClasses.conMobileWrapper}>
                <Box component={'div'}>
                    <CBreadCrumbs isNewsPage={true} category={category} />
                </Box>
                <Box component={'div'} sx={{ borderBottom: '1px solid #d3d3d3' }}>
                    {
                        subCategories && category &&
                        <CSelector
                            listItems={subCategories}
                            placeholder={{
                                vi: category?.nameVi,
                                en: category?.nameEn
                            }}
                            fieldNameRenders={
                                {
                                    vi: 'nameVi',
                                    en: 'nameEn'
                                }
                            }
                            onChangeSelector={onChangeSubCategory}
                        />

                    }

                </Box>
                <Box component={'div'}>
                    {
                        listNews?.map((news, index) => {
                            return returnNewsItem(news, index)
                        })
                    }
                    {/* {totalPage > 0 &&
                        <Box component={'div'} sx={{ width: '100%', background: '#eee', alignContent: 'center' }} mt={2}>
                            <Pagination
                                sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                count={totalPage}
                                page={currentPage}
                                onChange={handleChangePage}
                                shape="rounded"
                                siblingCount={0}
                                renderItem={(item) => <PaginationItem {...item} sx={{ '&.Mui-selected': { color: '#000', background: 'white', border: '#000 1px solid' } }} />}
                            />
                        </Box>
                    } */}
                </Box>

                <Footer />
            </Box>
        </>
    )
}

export default ListNewsPage