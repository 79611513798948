import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conBoxTitle: {
            background: '#024AA0 !important',
            padding: '3px',
            lineHeight: '28px',
            paddingLeft: '1rem',
            borderRadius: '5px 0 5px 0'
        }
    }
})