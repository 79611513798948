/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SoftUIControllerProvider } from "context";
import i18n from "services/language/i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "redux/store";
import moment from "moment";
moment.locale(i18n.language)

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
