import React from "react";

const SVG = ({
    size = "100%",
    viewBox = "0 0 24 24",
    fill = "#3A416F",
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M12 19L19 12L12 5M19 12L5 12" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default SVG;
