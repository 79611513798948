import { makeStyles } from "@mui/styles";

export default makeStyles(() =>{
    return{
        conProductStatus:
        {
            color: '#656565',
            lineHeight: '20px',
            fontWeight: 'bold',
            marginTop: '5px'
        },
        conProductDescription:{
            display: 'block',
            marginTop: '10px',
            lineHeight: '150%',
            color: '#333',
            fontWeight: 'normal'
        },
        conProductWarranty:{

        },
        conProductPrice:{
            display: 'block',
            width: '100%',
            fontSize: '18px',
            fontWeight: 'normal',
            marginTop: '10px',
            color: '#c70101'
        },
        conOrder:{
            display: 'block',
            marginTop: '27px',
            width: '100%'
        },
        buttonOrder:{
            float: 'left',
            width: '115px',
            padding: '12px 20px',
            fontSize: '14px',
            fontWeight: '500',
            marginTop: '40px',
            color: '#fff',
            textTransform: 'uppercase',
            border: 0,
            WebkitBorderRadius: '5px',
            boxShadow: 'inset 0 -3px 0 0 #28790F',
            background: '#258905',
            marginRight: '12px',
            cursor: 'pointer',
            marginBottom: '35px'
        },
        conShip:{
            float: 'left',
            padding: '10px',
            width: '400px',
            background: '#6E6566',
            WebkitBoxShadow: 'inset 0 -6px 0 0 #B1000E',
            borderRadius: '5px'
        },
        conCheckList:{
            lineHeight: '20px',
            position: 'relative',
            paddingLeft: '18px',
            color: 'white',
            marginLeft: '10px'
        },
        iconCheckShip:{
            position: 'absolute',
            width: '12px',
            height: '10px',
            display:'block',
            left: 0,
            top: '6px'
        },
        boxContact:{
            display: 'block',
            marginTop: '20px',
            width: '100%'
        },
        textContact:{
            float: 'left',
            width: '100%',
            paddingLeft: '34px',
            color: '#0a62ac',
            fontSize: '15px',
            position: 'relative'
        },
        iconPhoneContact: {
            height: '30px !important',
            width: '30px !important',
            color: 'red !important',
            position: 'absolute',
            display: 'block !important',
            top: '-6px',
            left: 0
        }
    }
})