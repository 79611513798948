
import { Route, Redirect } from "react-router-dom";
import AdminProvider from "./AdminProvider";

export const AdminRouter = ({ component: Component, ...res }) => (
    <AdminProvider>
        <Redirect to={res.location.pathname} />
        <Route
            {...res}
            render={(props) =>
                <Component {...props} />
            }
        />
    </AdminProvider>
);
