import { Container, Grid } from "@mui/material"
import Header from "components/Header"
import Footer from "components/Footer"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import { useTranslation } from "react-i18next"
import image from "assets/images/vietkhang/about.jpg";

const description1 = `Được thành lập và điều hành bởi đội ngũ lãnh đạo giàu kinh nghiệm, Công ty TNHH Thương Mại và Đầu tư Việt Khang vươn mình trở thành đơn vị hàng đầu trong lĩnh vực cung cấp giải pháp thiết bị phòng thí nghiệm, đáp ứng nhu cầu phân tích của quý khách hàng trong mọi lĩnh vực như dược phẩm, thực phẩm đồ uống, môi trường, hoá chất, khoa học đời sống.`

const description2 = `Với tiêu chí “Luôn bắt kịp các công nghệ hiện đại trên thế giới”, các sản phẩm của Việt Khang đã và đang hỗ trợ đắc lực cho các cơ sở khoa học công nghệ tại các địa phương, đóng góp một phần sức lực trong công cuộc hiện đại hóa công nghệ tại Việt Nam. `;

const description3 = `Công ty TNHH Thương Mại và Đầu Tư Việt Khang có mạng lưới đối tác rộng khắp trên toàn thế giới, các  sản phẩm – giải pháp đa dạng và phong phú, chúng tôi cam kết luôn mang tới những sản phẩm chất lượng nhất và dịch vụ kỹ thuật tận tâm để làm hài lòng quý khách hàng.`;
const About = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <Container >
                <SuiBox bgColor="white" p={3} borderRadius="lg" my={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5}>
                            <SuiBox
                                component="img"
                                src={image}
                                alt="Product Image"
                                shadow="lg"
                                borderRadius="lg"
                                width="100%"
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <SuiBox>
                                <SuiBox mb={1}>
                                    <SuiTypography variant="h3" fontWeight="bold">{t('about_us')}</SuiTypography>
                                </SuiBox>
                                <SuiBox pl={2} mb={2} textAlign="justify"  >
                                    <SuiTypography variant="body1" color="text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{description1}</SuiTypography>
                                    <br></br>
                                    <SuiTypography variant="body1" color="text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{description2}</SuiTypography>
                                    <br></br>
                                    <SuiTypography variant="body1" color="text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{description3}</SuiTypography>
                                </SuiBox>
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>
            </Container>
            <Footer />
        </>
    )
}

export default About