import { Box, Typography } from '@mui/material';
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import styles from './styles'
import { useTranslation } from 'react-i18next';
import i18n from 'services/language/i18n';
import { rewriteUrlPath } from 'utils/convertPathUrl';

const MListProduct = (
    {
        listProducts = []
    }
) => {

    const classes = styles()
    const { t } = useTranslation()


    const renderProduct = (product) => {
        let productImg = product?.images && product?.images.length > 0 ? product?.images[0] : 'undefine'
        return (

            <Box component={'a'} sx={{ color: '#000' }} href={`/product/${rewriteUrlPath(product?.nameEn)}.html&query=${product?.productId}`} className={classes.conProduct} p={2}>
                <Box component={'div'} className={classes.boxImg}>
                    <Box className={classes.image} component={'img'} src={productImg} />
                </Box>
                <Box component={'div'} className={classes.boxProductInfo}>
                    <Typography component={'div'} className={classes.textProductName}>
                        {i18n.language === 'vi' ? product.nameVi : product.nameEn}
                    </Typography>
                    <Typography className={classes.textProductInfo}>
                        <Typography component={'span'} sx={{ fontWeight: 'bold !important' }}>
                            {t('product_status')} :&nbsp;
                        </Typography>
                        {t('product_check')}
                    </Typography>
                    <Typography>
                        <Typography component={'span'} sx={{ fontWeight: 'bold !important' }}>
                            {t('product_insurance')}&nbsp;
                        </Typography>
                        {t('product_insurance_none')}
                    </Typography>
                    <Typography className={classes.textProductInfo}>
                        <Typography component={'span'} sx={{ fontWeight: 'bold !important' }}>
                            {t('product_price')}&nbsp;
                        </Typography>
                        {t('product_check')}
                    </Typography>
                </Box>
            </Box>

        )
    }
    return (
        <Carousel animation='slide' indicators={false} sx={{ height: '100%' }} autoPlay={false}>
            {listProducts.map((product, index) => (
                <Box key={index} height={'100%'} width={'100%'} component={'div'}>
                    {renderProduct(product)}
                </Box>

            ))}
        </Carousel>
    )
}

export default MListProduct