import { PUBLIC_API } from "redux/api";
import { SET_PRODUCT_CATEGORY, SET_PRODUCT_LIST } from "redux/types";
import instance from "services/axios";

const commonProductServices = {
    getListProduct: async (dispatch) => {
        try {
            let res = await instance.get(PUBLIC_API.PRODUCT.GET_LIST_PRODUCT);
            dispatch({ type: SET_PRODUCT_LIST, payload: res.data.data })
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    getProductType: async (dispatch) => {
        try {
            let res = await instance.get(PUBLIC_API.PRODUCT.GET_LIST_CATEGORY);
            dispatch({ type: SET_PRODUCT_CATEGORY, payload: res.data.data })
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    getProductTypeDetail: async (productTypeId) => {
        try{
            let res = await instance.get(PUBLIC_API.PRODUCT.GET_CATEGORY_DETAIL + productTypeId)
            return res.status === 200 && res.data ? res.data.data : null
        }catch(error){
            return null
        }
    },


    getSubProductType: async() => {
        try {
            let res = await instance.get(PUBLIC_API.PRODUCT.GET_LIST_SUB_PRODUCT_TYPE);
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    getProductById: async(productId) => {
        try {
            let res = await instance.get(PUBLIC_API.PRODUCT.GET_PRODUCT + productId);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },
}

export default commonProductServices

