import { PUBLIC_API } from "redux/api";
import { SET_NEWS_CATEGORY } from "redux/types";
import instance from "services/axios";

const commonNewsServices = {
    listCategory: async (dispatch) => {
        try {
            let res = await instance.get(PUBLIC_API.NEWS.GET_LIST_CATEGORY);
            dispatch({ type: SET_NEWS_CATEGORY, payload: res.data.data })
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    listNews: async () => {
        try {
            let res = await instance.get(PUBLIC_API.NEWS.GET_LIST_NEWS);
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    getNewsById: async (id) => {
        try {
            let res = await instance.get(PUBLIC_API.NEWS.GET_NEWS_DETAIL + id);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    getNewsByFilter: async (filterObj) => {
        try {
            let res = await instance.post(PUBLIC_API.NEWS.GET_NEWS_BY_FILTER, filterObj)
            return res.status === 200 && res.data ? res.data : null
        } catch (error) {
            return null
        }
    },
    getNewsCategoryById: async (id) => {
        try {
            let res = await instance.get(`${PUBLIC_API.NEWS.GET_CATEGORY_BY_ID}/${id}`)
            return res.status === 200 && res.data ? res.data : null
        } catch (error) {
            return null
        }
    }
}

export default commonNewsServices

