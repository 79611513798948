import Header from "components/Header"
import { Box,  Grid, Pagination, PaginationItem,  Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import ProductCategory from "../product-category"
import Footer from "components/Footer"
import { useHistory } from "react-router-dom"
import { rewriteUrlPath } from "utils/convertPathUrl"
import i18n from 'services/language/i18n'
import { useSelector } from "react-redux"
import CBreadCrumbs from "components/CBreadCrumbs"
import commonProductServices from "redux/common/product"
import CNavProductHeader from "components/CNavProductHeader"
import CListCardItem from "components/CListCardItem"
import CBrandSlide from "components/CBrandSlide"
import { Layout_Config } from "config"
import { getModeView } from "utils/modeView"
import commonStyles from 'common/styles'
import styles from "./styles"
import CSelector from "components/CSeletor"
import { useTranslation } from "react-i18next"

const ProductList = (props) => {
    const categoryId = Number(props.match.params.categoryId)
    const subCategoryId = Number(props.match.params.subCategoryId)
    const history = useHistory();
    const listProduct = useSelector(state => state.common.product_list)
    const [productList, setProductList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1)
    const itemPerPage = 5;
    const { t } = useTranslation()
    const lastItem = currentPage * itemPerPage;
    const firstItem = lastItem - itemPerPage;
    const data = productList.slice(firstItem, lastItem);
    const [currentCategory, setCurrentCategory] = useState(null)
    const [currentSubCategory, setCurrentSubCategory] = useState(null)
    const [modeView, setModeView] = useState(null)
    const commonClasses = commonStyles()
    const classes = styles()
    const [lang, setLang] = useState(i18n.language)
    const [subCategories, setSubCategories] = useState(null)

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };


    const onChangeSubCategory = (subCategory) => {
        history.push(`/product.html&search=${subCategory.productTypeId}&sub=${subCategory.subProductTypeId}`)

    }

    useEffect(() => {
        if (currentCategory === null && categoryId) {
            getCurrentCategory()
        }
    }, [currentCategory])

    useEffect(() => {
        setModeView(getModeView())
    }, [])

    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language])

    const getCurrentCategory = async () => {
        let res = await commonProductServices.getProductTypeDetail(categoryId)
        if (res) {
            setCurrentCategory(res)
            let subCategories = [...res.subProductType]
            setSubCategories(subCategories)
            if (!isNaN(subCategoryId)) {

                let subCategoryFilter = subCategories.find((item) => {
                    return item.subProductTypeId === subCategoryId
                })

                if (subCategoryFilter) {
                    setCurrentSubCategory(subCategoryFilter)
                }
            }
        }
    }



    useEffect(() => {
        if (categoryId && subCategoryId && listProduct) {
            let newListProduct = listProduct.filter(item => item.subProductType.subProductTypeId === subCategoryId)
            setProductList(newListProduct)
            setTotalPage(Math.ceil(newListProduct.length / itemPerPage))
        } else if (categoryId && !subCategoryId && listProduct) {
            let newListProduct = listProduct.filter(item => item.subProductType.productTypeId === categoryId)
            setProductList(newListProduct)
            setTotalPage(Math.ceil(newListProduct.length / itemPerPage))
        } else if (!categoryId && !subCategoryId && listProduct) {
            setProductList(listProduct)
            setTotalPage(Math.ceil(listProduct.length / itemPerPage))
        }
    }, [categoryId, subCategoryId, listProduct])

    const renderProductItem = (product, index) => {
        return (
            <Box component={'a'} href={`/product/${rewriteUrlPath(product?.nameEn)}.html&query=${product?.productId}`} className={classes.conBoxProductItem} key={`product_item_${index}`}>
                <Box component={'div'} sx={{ minWidth: '125px' }}>
                    <Box className={classes.conBoxProductImg}
                        component={'img'}
                        src={product?.images && product?.images.length > 0 ? product?.images[0] : 'http://vietkhanggroup.vn/content/product/product_50_0.jpg'}
                    />
                </Box>
                <Box component={'div'} className={classes.conBoxProductInfo} ml={1}>
                    <Typography className={classes.textProductName}>{lang === 'vi' ? product?.nameVi : product?.nameEn}</Typography>
                    <Typography sx={{ fontWeight: 'bold !important' }}>
                        {t('price')} :&nbsp;
                        <Typography component={'span'}>
                            Lien he
                        </Typography>
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Header />
            <Box sx={{ width: Layout_Config.minWidth, margin: '0 auto' }} display={modeView?.desktop}>
                <Box component={'div'} my={2}>
                    <CBreadCrumbs category={currentCategory} subCategory={currentSubCategory} />
                </Box>

                {/* RENDER PRODUCT */}
                <Box component={'div'} display={'flex'} justifyContent={'space-around'} my={2}>
                    <Box component={'div'} width={'90px'}>
                        <ProductCategory currentCategoryId={currentCategory?.productTypeId} />
                    </Box>
                    <Box component={'div'} width={'100%'} ml={1}>
                        <Box>
                            <CNavProductHeader category={currentCategory} />
                        </Box>
                        <Box>
                            <CListCardItem title={'Sản Phẩm Khuyến Mãi'} listItems={data} checkBoxCompare={true} />

                        </Box>
                    </Box>
                </Box>
                <CBrandSlide />
                <Box my={2}></Box>
                <Footer />
            </Box>
            <Box display={modeView?.mobile} className={commonClasses.conMobileWrapper}>
                <Box component={'div'} my={2}>
                    <CBreadCrumbs category={currentCategory} subCategory={currentSubCategory} />
                </Box>
                <Box component={'div'} sx={{ borderBottom: '1px solid #d3d3d3' }}>
                    {
                        subCategories &&
                        <CSelector
                            listItems={subCategories}
                            placeholder={{
                                vi: currentCategory?.nameVi,
                                en: currentCategory?.nameEn
                            }}
                            fieldNameRenders={
                                {
                                    vi: 'nameVi',
                                    en: 'nameEn'
                                }
                            }
                            onChangeSelector={onChangeSubCategory}
                        />

                    }
                    <CSelector
                        listItems={[]}
                        placeholder={{
                            vi: 'Thương hiệu',
                            en: 'Branch'
                        }}

                    />
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Grid item xs={5.5}>
                            <CSelector
                                listItems={[]}
                                placeholder={{
                                    vi: 'Mức giá',
                                    en: 'Price'
                                }}

                            />
                        </Grid>
                        <Grid item xs={5.5}>
                            <CSelector
                                listItems={[]}
                                placeholder={{
                                    vi: 'Sắp xếp',
                                    en: 'Sort'
                                }}

                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'}>
                    {
                        data?.map((product, index) => {
                            return renderProductItem(product, index)
                        })
                    }
                    {totalPage > 0 &&
                        <Box component={'div'} sx={{ width: '100%', background: '#eee', alignContent: 'center' }} mt={2}>
                            <Pagination
                                sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                count={totalPage}
                                page={currentPage}
                                onChange={handleChangePage}
                                // color="none"
                                // variant="outlined"
                                shape="rounded"
                                siblingCount={0}
                                renderItem={(item) => <PaginationItem {...item} sx={{ '&.Mui-selected': { color: '#000', background: 'white', border: '#000 1px solid' } }} />}
                            />
                        </Box>
                    }
                </Box>
                <Footer />
            </Box>
        </>


    )
}

export default ProductList