import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        boxCol3Element: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'

        },
        boxCol2Element: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
})