// USERS ROUTES
import ProductList from "layouts/pages/product/product-list";
import ProductItem from "layouts/pages/product/product-item";
import Home from "layouts/pages/customer/home";
import News from "layouts/pages/news";
import Contact from "layouts/pages/customer/contact";
import About from "layouts/pages/customer/about";

// GUARD ROUTES
import SignIn from "layouts/pages/sign-in";

// ADMIN ROUTES - PRODUCT MANAGEMENT
import AdminProductList from "layouts/pages/admin/product-management/product-list";
import AdminAddProduct from "layouts/pages/admin/product-management/add-product";
import AdminEditProduct from "layouts/pages/admin/product-management/edit-product";
import AdminViewProduct from "layouts/pages/admin/product-management/view-product";
import AdminProductCategoryList from "layouts/pages/admin/product-management/category-list";
import AdminOrdersList from "layouts/pages/admin/orders-management/order-list";
import AdminUserList from "layouts/pages/admin/user-management/user-list";

// ADMIN ROUTES - NEWS MANAGEMENT 
import AdminNewsList from "layouts/pages/admin/news-management/news-list";
import AdminAddPost from "layouts/pages/admin/news-management/add-post";
import AdminEditPost from "layouts/pages/admin/news-management/edit-post";
import AdminNewsCategoryList from "layouts/pages/admin/news-management/category-list";

// ADMIN ROUTES - BANNER MANAGEMENT
import AdminBanner from "layouts/pages/admin/banner-management/banner-list";

// ADMIN ROUTES - COMPANY MANAGEMENT
import AdminCompanyManagement from "layouts/pages/admin/company-management";

// ADMIN ROUTES - MANUFACTURER MANAGEMENT
import AdminManufactureManagement from "layouts/pages/admin/manufacture-management";

import Shop from "examples/Icons/Shop";
import NewsDetails from "layouts/pages/news/news-details";
import ListNewsPage from "layouts/pages/news/list-news";
import OrderPage from "layouts/pages/product/order";


const listRoutes = {
  guard: [
    {
      name: "sign_in",
      key: "sign_in",
      route: "/login",
      component: SignIn,
    },
  ],
  admin: [
    // SIDENAV
    {
      type: "title",
      name: "company",
      key: "company",
      route: "/admin/company",
      icon: <Shop size="12px" />,
      component: AdminCompanyManagement,
      noCollapse: true,
    },
    {
      type: "title",
      name: "manufacturer",
      key: "manufacturer",
      route: "/admin/manufacturer",
      icon: <Shop size="12px" />,
      component: AdminManufactureManagement,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "product",
      key: "product",
      icon: <Shop size="12px" />,
      collapse: [
        {
          name: "category",
          key: "category",
          route: "/admin/product/category",
          component: AdminProductCategoryList,
        },
        {
          name: "product_list",
          key: "product_list",
          route: "/admin/product/product-list",
          component: AdminProductList,
        },
        {
          name: "order_list",
          key: "order-list",
          route: "/admin/product/order-list",
          component: AdminOrdersList,
        },
      ],
    },
    {
      type: "collapse",
      name: "news",
      key: "news",
      icon: <Shop size="12px" />,
      collapse: [
        {
          name: "category",
          key: "category",
          route: "/admin/news/category",
          component: AdminNewsCategoryList,
        },
        {
          name: "news_list",
          key: "news_list",
          route: "/admin/news/news-list",
          component: AdminNewsList,
        },
      ],
    },
    {
      type: "title",
      name: "banner",
      key: "banner",
      route: "/admin/banner",
      icon: <Shop size="12px" />,
      component: AdminBanner,
      noCollapse: true,
    },
    {
      type: "title",
      name: "user_list",
      key: "user-list",
      route: "/admin/user-management/user-list",
      icon: <Shop size="12px" />,
      component: AdminUserList,
      noCollapse: true,
    },
    // PRODUCT ROUTES
    {
      name: "new_product",
      key: "new_product",
      route: "/admin/product/new-product",
      component: AdminAddProduct,
    },
    {
      name: "new_product",
      key: "new_product",
      route: "/admin/product/edit/:productId",
      component: AdminEditProduct,
    },
    {
      name: "new_product",
      key: "new_product",
      route: "/admin/product/view/:productId",
      component: AdminViewProduct,
    },
    // NEWS ROUTES
    {
      name: "new_post",
      key: "new_post",
      route: "/admin/news/new-post",
      component: AdminAddPost,
    },
    {
      name: "edit_post",
      key: "edit_post",
      route: "/admin/news/edit/:newsId",
      component: AdminEditPost,
    },

    // ORDER 

  ],
  guest: [
    {
      name: "home",
      key: "home",
      route: "/",
      component: Home,
    },
    {
      name: "product",
      key: "product",
      route: "/product.html",
      component: ProductList,
    },
    {
      type: 'hidden',
      name: "product",
      key: "product",
      route: "/product.html&search=:categoryId&sub=:subCategoryId",
      component: ProductList,
    },
    {
      type: 'hidden',
      name: "product",
      key: "product",
      route: "/product/:title.html&query=:productId",
      component: ProductItem,
    },
    {
      name: "about",
      key: "about",
      route: "/about.html",
      component: About,
    },
    {
      name: "news",
      key: "news",
      route: "/news.html",
      component: News,
    },
    {
      name: "contact",
      key: "contact",
      route: "/contact.html",
      component: Contact,
    },
    {
      type: 'hidden',
      name: "product-item",
      key: "product-item",
      route: `/news/:title.html&query=:newsId`,
      component: NewsDetails,
    },
    {
      type: 'hidden',
      name: "list-news",
      key: "list-news",
      route: `/list-news/:category.html&query=:categoryId`,
      component: ListNewsPage,
    },
     {
      name: "order",
      key: "order",
      route: `/order.html`,
      component: OrderPage,
    }
  ]
}

export default listRoutes;
