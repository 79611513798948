import FormField from "components/FormField"
import ModalError from "components/Modal/Error"
import ModalSuccess from "components/Modal/Success"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiInput from "components/SuiInput"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import userServices from "redux/user"

const ContactForm = () => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [actionStatus, setActionStatus] = useState({ status: '' })

    const clearForm = () => {
        setName('')
        setPhone('')
        setEmail('')
        setMessage('')
        setAddress('')
    }
    const onSend = async () => {
        const params = {
            fullName: name,
            address: address,
            email: email,
            phone: phone,
            productId: null,
            content: message
        }
        let res = await userServices.sendForm(params);
        setActionStatus({ status: res ? 'success' : 'error' })
        clearForm('')
    }
    
    return (
        <SuiBox>
            <SuiBox mb={2}>
                <SuiInput type="text" placeholder={t('name')} size="large" value={name} onChange={e => setName(e.target.value)} />
            </SuiBox>
            <SuiBox mb={2}>
                <SuiInput type="text" placeholder={t("phone")} size="large" value={phone} onChange={e => setPhone(e.target.value)} />
            </SuiBox>
            <SuiBox mb={2}>
                <SuiInput type="email" placeholder={t("email")} size="large" value={email} onChange={e => setEmail(e.target.value)} />
            </SuiBox>
            <SuiBox>
                <SuiInput type="text" placeholder={t("address")} size="large" value={address} onChange={e => setAddress(e.target.value)} />
            </SuiBox>
            <SuiBox mb={2}>
                <FormField
                    value={message}
                    placeholder={t('message')}
                    onChange={e => setMessage(e.target.value)}
                    multiline
                    rows={5}
                />
            </SuiBox>
            <SuiBox mt={4} mb={1}>
                <SuiButton variant="gradient" color="success" size="large" onClick={onSend}>
                    {t('send')}
                </SuiButton>
            </SuiBox>
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </SuiBox>
    )
}

export default ContactForm