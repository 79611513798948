import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return{
        textFieldWrapper:{
            borderRadius: '0px !important',
            paddingLeft: '10px !important',
            padding: '0px !important',
            width: 'auto !important'
            
        },
        textFieldRegisterWrapper: {
           

        },
        textFieldRegister: {
            height: '21px !important'
        },
        textUserComment:{
            width: '99%'
        },
        formLabelStar:{
            display: 'flex',
            alignItems: 'center'
        }
    }
})