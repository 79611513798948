import React from "react";
// PACKAGE ARROWS
import ArrowRight from "./package/arrows/ArrowRight";
// PACKAGE BASIC
import Show from "./package/basic/Show";
import Hide from "./package/basic/Hide";
import Trash from "./package/basic/Trash";

// PACKAGE USER
import User from "./package/user/User";
import SignOut from "./package/user/SignOut";

// PACKAGE SUPPORT
import Help from "./package/support/Help";


const SVGIcon = ({ name, size, fill, opacity }) => {
    switch (name) {
        // ARROWS
        case "arrow-right":
            return <ArrowRight size={size} fill={fill} />;
        // BASIC
        case "trash":
            return <Trash size={size} fill={fill} />;
        case "password-show":
            return <Show size={size} fill={fill} />;
        case "password-hide":
            return <Hide size={size} fill={fill} />;
        // USER
        case "user-outlined":
            return <User size={size} fill={fill} />;
        case "sign-out":
            return <SignOut size={size} fill={fill} />;
        default:
            return <Help size={size} fill={fill} />;
    }
};

export default SVGIcon;
