import { Grid, Card, Tooltip } from '@mui/material'
import AdminLayout from 'components/Layout/AdminLayout'
import SuiAvatar from 'components/SuiAvatar'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import DataTable from 'examples/Tables/DataTable'
import { useState } from 'react'
import ModalAddBanner from 'layouts/pages/admin/banner-management/modal/add'
import { useTranslation } from 'react-i18next'
import ModalDelete from 'components/Modal/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const imageURL = 'https://scontent.fsgn5-10.fna.fbcdn.net/v/t39.30808-6/269596594_10158730629767914_3605753999899199747_n.png?_nc_cat=1&ccb=1-5&_nc_sid=730e14&_nc_ohc=eqhx0X3oCugAX_4VBN9&_nc_ht=scontent.fsgn5-10.fna&oh=00_AT8dwDFDAugiq46dSWpLBEGkxx2Tw8FYLoQQa75WDNV_Tw&oe=61C76172'

const BannerList = () => {
    const { t } = useTranslation()
    const [modalAdd, setModalAdd] = useState(false)
    const [modalDelete, setModalDelete] = useState({ visible: false, data: null })
    const [data, setData] = useState({
        columns: [
            { Header: t("image"), accessor: "image", align: "center", width: '10%', Cell: ({ value }) => RenderCellImage(value) },
            { Header: t("title"), accessor: "title", align: "center" },
            { Header: t("description"), accessor: "description", align: "center" },
            { Header: "", accessor: "action", align: "center", width: '10%', Cell: ({ row }) => RenderCellActions(row.original) },
        ],
        rows: [
            { productId: 'product1', image: imageURL, title: 'Faker', description: 'LOL' },
            { productId: 'product2', image: imageURL, title: 'Faker', description: 'LOL' },
            { productId: 'product3', image: imageURL, title: 'Faker', description: 'LOL' },
            { productId: 'product4', image: imageURL, title: 'Faker', description: 'LOL' },
            { productId: 'product5', image: imageURL, title: 'Faker', description: 'LOL' },
            { productId: 'product6', image: imageURL, title: 'Faker', description: 'LOL' },
        ]
    })

    const RenderCellImage = (image) => {
        return (
            <SuiBox>
                <SuiAvatar src={image} alt={''} variant="rounded" size="xl" />
            </SuiBox>
        )
    }
    const RenderCellActions = (data) => {
        return (
            <SuiBox display="flex" gap={1} sx={{ cursor: 'pointer' }}>
                <Tooltip title={t('edit')} placement="top">
                    <SuiBox bgColor="warning" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <EditIcon />
                    </SuiBox>
                </Tooltip>
                <Tooltip title={t('delete')} placement="top">
                    <SuiBox bgColor="error" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <DeleteIcon onClick={() => setModalDelete({ visible: true, data: data })} />
                    </SuiBox>
                </Tooltip>
            </SuiBox>
        )
    }
    return (
        <AdminLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <SuiBox pt={3} px={3} display="flex" justifyContent="flex-end">
                            <SuiButton variant="gradient" color="info" size="small" onClick={() => setModalAdd(true)}>
                                + {t('new_banner')}
                            </SuiButton>
                        </SuiBox>
                        <SuiBox py={1}>
                            <DataTable
                                table={data}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        </SuiBox>
                    </Card>
                </Grid>
            </Grid>

            <ModalAddBanner open={modalAdd} onClose={() => setModalAdd(false)} />
            <ModalDelete
                title={t('delete_banner_title')}
                open={modalDelete.visible}
                onClose={() => setModalDelete({ visible: false, data: null })}
                onDelete={() => console.log('delete')}
            />
        </AdminLayout>
    )
}

export default BannerList