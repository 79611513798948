import { Card, Grid } from "@mui/material"
import FormField from "components/FormField"
import AdminLayout from "components/Layout/AdminLayout"
import SuiBox from "components/SuiBox"
import SuiEditor from "components/SuiEditor"
import SuiSelect from "components/SuiSelect"
import SuiTypography from "components/SuiTypography"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import productServices from "redux/admin/product"
import commonProductServices from "redux/common/product"
import { BASE_URL } from "services/axios"
import i18n from "services/language/i18n"

const ViewProduct = (props) => {
    const { t } = useTranslation();
    const productId = Number(props.match.params.productId)
    const [image, setImage] = useState(null)
    const [name, setName] = useState({ en: '', vi: '' })
    const [model, setModel] = useState('')
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(null);
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionVi, setDescriptionVi] = useState('');
    const [manufacturer, setManufacturer] = useState({ en: '', vi: '' })
    const [category, setCategory] = useState({ en: '', vi: '' })
    const [subCategory, setSubCategory] = useState({ en: '', vi: '' })

    useEffect(() => {
        getProductDetails();
    }, [])

    const getProductDetails = async () => {
        let res = await commonProductServices.getProductById(productId);
        if (res) {
            setImage(BASE_URL + '/' + res.image.url)
            setName({ en: res.nameEn, vi: res.nameVi })
            setModel(res.model)
            setPrice(res.price)
            setQuantity(res.qty)
            setDescriptionEn(res.descriptionEn)
            setDescriptionVi(res.descriptionVi)
            setManufacturer({ en: res.manufacturer.nameEn, vi: res.manufacturer.nameVi })
            setCategory({ en: res.manufacturer.nameEn, vi: res.manufacturer.nameVi })
            setSubCategory({ en: res.manufacturer.nameEn, vi: res.manufacturer.nameVi })
        }
    }

    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <Card sx={{ height: "100%" }}>
                            <SuiBox p={3}>
                                <SuiTypography variant="h5" fontWeight="bold">
                                    {t('product_image')}
                                </SuiTypography>
                                <SuiBox
                                    component="img"
                                    src={image}
                                    alt="Product Image"
                                    borderRadius="lg"
                                    shadow="lg"
                                    width="100%"
                                    my={3}
                                />

                            </SuiBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Card sx={{ overflow: "visible" }}>



                            <SuiBox p={3}>
                                <SuiTypography variant="h5">{t('product_information')}</SuiTypography>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('product_name') + ' (VN)'} value={name.vi} disabled />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('product_name') + ' (EN)'} value={name.en} disabled />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('model')} value={model} disabled />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('manufacturer')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiBox>
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="regular"
                                                    textTransform="capitalize"
                                                >
                                                    {i18n.language === 'en' ? manufacturer.en : manufacturer.vi}
                                                </SuiTypography>
                                            </SuiBox>
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={3}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('category')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiBox>
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="regular"
                                                    textTransform="capitalize"
                                                >
                                                    {i18n.language === 'en' ? category.en : category.vi}
                                                </SuiTypography>
                                            </SuiBox>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('sub_category')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiBox>
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="regular"
                                                    textTransform="capitalize"
                                                >
                                                    {i18n.language === 'en' ? subCategory.en : subCategory.vi}
                                                </SuiTypography>
                                            </SuiBox>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormField type="number" label={t('price')} value={price} disabled />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormField type="number" label={t('quantity')} value={quantity} disabled />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('description')}&nbsp;&nbsp;
                                                    <SuiTypography variant="caption" fontWeight="regular" color="text">
                                                        (VN)
                                                    </SuiTypography>
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiEditor value={descriptionVi} readOnly />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('description')}&nbsp;&nbsp;
                                                    <SuiTypography variant="caption" fontWeight="regular" color="text">
                                                        (EN)
                                                    </SuiTypography>
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiEditor value={descriptionEn} readOnly />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox>
        </AdminLayout>
    )
}

export default ViewProduct