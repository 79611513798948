import { Modal, Grid, Icon, Tooltip, Divider } from '@mui/material'
import modal from 'assets/theme/base/modal'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiEditor from 'components/SuiEditor'
import FormField from 'components/FormField'
import { useState, useEffect } from 'react'
import SuiButton from 'components/SuiButton'
import SuiSelect from 'components/SuiSelect'
import { useTranslation } from 'react-i18next'
import SuiInput from 'components/SuiInput'
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDelete from "components/Modal/Delete";
import MenuIcon from '@mui/icons-material/Menu';
import i18n from "services/language/i18n";
import productServices from 'redux/admin/product'
import ModalSuccess from 'components/Modal/Success'
import { LottieAnimation } from 'constant'
import { Player } from '@lottiefiles/react-lottie-player'
import ModalError from 'components/Modal/Error'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
const EditProductCategory = ({ open = false, data = null, onClose = () => { } }) => {

    const { t } = useTranslation();
    const [newCategory, setNewCategory] = useState({ en: '', vi: '' })
    const [titleCategory, setTitleCategory] = useState({ en: '', vi: '' })
    const [openDelete, setOpenDelete] = useState({ visible: false, data: null })
    const [subCategory, setSubCategory] = useState([])
    const [actionStatus, setActionStatus] = useState({ status: '' })
    useEffect(() => {
        if (data) {
            setSubCategory(data.subProductType)
            setTitleCategory({ en: data.nameEn, vi: data.nameVi })
        }
    }, [data])

    const addNewCategory = () => {
        let tmp = {
            cacheId: 'tmp_' + subCategory.length,
            nameEn: newCategory.en,
            nameVi: newCategory.vi
        }
        setSubCategory(subCategory.concat(tmp))
        setNewCategory({ en: '', vi: '' })
    }

    const onSave = async () => {
        if (subCategory.length > 0) {
            subCategory.forEach(object => {
                delete object['cacheId'];
            });
        }
        const params = {
            productTypeId: data.productTypeId,
            nameEn: titleCategory.en,
            nameVi: titleCategory.vi,
            subProductType: subCategory
        }
        let res = await productServices.editProductCategory(params);
        setActionStatus({ status: res ? "success" : 'error' })
        onClose()
    }

    const onDeleteSubCategory = async () => {
        let res = await productServices.deleteSubProductCategory(openDelete.data);
        if (res) {
            setActionStatus({ status: res ? "success" : "error" })
            setOpenDelete({ visible: false, data: null })
            setSubCategory(subCategory.filter(item => item.subProductTypeId !== openDelete.data))
        }
    }

    const onRemoveSubCategory = (item) => {
        if (item.subProductTypeId) {
            setOpenDelete({ visible: true, data: item.subProductTypeId })
        } else {
            setSubCategory(subCategory.filter(object => object.cacheId !== item.cacheId))
        }

    }

    return (
        <>
            <Modal open={open} onClose={onClose} onBackdropClick={onClose}>
                <SuiBox sx={{ ...modal, minWidth: 300 }} bgColor="white" borderRadius="md" shadow="md" >
                    <Grid container spacing={3} mb={3}>
                        <Grid item xs={12}>
                            <SuiBox display="flex" alignItems="center">
                                <SuiInput sx={{ borderRadius: 0, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} type="text" value={titleCategory.en} onChange={(e) => setTitleCategory({ ...titleCategory, en: e.target.value })} />
                                <SuiInput sx={{ borderRadius: 0, borderTopRightRadius: 20, borderBottomRightRadius: 20 }} type="text" value={titleCategory.vi} onChange={(e) => setTitleCategory({ ...titleCategory, vi: e.target.value })} />
                            </SuiBox>
                        </Grid>
                        <Grid item xs={12}>
                            <SuiBox display="flex" alignItems="center">
                                <SuiInput sx={{ borderRadius: 0, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} type="text" placeholder={t('enter_sub_category')} value={newCategory.en} onChange={(e) => setNewCategory({ ...newCategory, en: e.target.value })} />
                                <SuiInput sx={{ borderRadius: 0 }} type="text" placeholder={t('enter_sub_category')} value={newCategory.vi} onChange={(e) => setNewCategory({ ...newCategory, vi: e.target.value })} />
                                <SuiButton sx={{ borderRadius: 0, borderTopRightRadius: 20, borderBottomRightRadius: 20 }} variant="gradient" color="info" size="small" onClick={addNewCategory}>{t('add')}</SuiButton>
                            </SuiBox>
                        </Grid>
                        <Grid item xs={12}>
                            {subCategory.map(item => (
                                <SuiBox display="flex" alignItems="center" justifyContent="space-between" mb={1} px={2} py={0.5}>
                                    <SuiBox display="flex">
                                        <MenuIcon />
                                        <SuiTypography variant="button" ml={1}>{i18n.language === 'en' ? item.nameEn : item.nameVi}</SuiTypography>
                                    </SuiBox>
                                    <Tooltip title={'Delete'} placement="top">
                                        <SuiBox bgColor={'error'} borderRadius="md" display="flex" alignItems="center" justifyContent="center" p={0.5}>
                                            <DeleteIcon color="white" onClick={() => onRemoveSubCategory(item)} />
                                        </SuiBox>
                                    </Tooltip>
                                </SuiBox>
                            ))}
                            {subCategory.length === 0 &&
                                <SuiBox>
                                    <Player
                                        autoplay={true}
                                        loop={true}
                                        src={LottieAnimation.no_result}
                                        style={{ width: 200 }}
                                    />
                                </SuiBox>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} justifyContent={'flex-end'} >
                        <Grid item>
                            <SuiButton variant="outlined" color="info" onClick={onClose}>
                                {t('cancel')}
                            </SuiButton>
                        </Grid>
                        <Grid item>
                            <SuiButton
                                variant="gradient"
                                color="info"
                                onClick={onSave}
                            >
                                {t('save')}
                            </SuiButton>
                        </Grid>
                    </Grid>
                </SuiBox>
            </Modal>
            <ModalDelete
                title={'Do you want to delete this sub category'}
                open={openDelete.visible}
                onClose={() => setOpenDelete({ visible: false, data: null })}
                onDelete={onDeleteSubCategory}
            />
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </>
    )
}

export default EditProductCategory