import { makeStyles } from "@mui/styles";


export default makeStyles(() => {
    return {
        boxTitle: {
            height: '26px',
            background: '#ECEEED !important',
            padding: '3px',
            paddingLeft: '1rem',
            borderBottom: '1px solid #CC3838 !important',
            padding: '0 5px 0 5px'

        },
        textTitleHeader: {
            fontSize: '13px !important',
            fontWeight: 'bold !important',
            lineHeight: '28px !important',
            color: '#CC3838 !important',
            display: 'block',
            position: 'absolute',
            float: 'left',
            textTransform: 'uppercase',
            // height: '130px'
        },
        conListItem: {
            // padding: '1rem',
            margin: '1rem',
            display: 'flex',
            alignItems: 'flex-start !important',
            cursor: 'pointer'
        },
        conTextTitle: {
            marginLeft: '1rem',

        },
        textTitle: {
            fontSize: '14px !important',
            fontWeight: 'bold !important',
            padding: '0px 2px',
            color: '#04416d',
            lineHeight: '16px !important',
            overflow: 'hidden',

            "&:hover": {
                textDecoration: 'underline !important'
            }
        },
        conInfo: {
            color: '#666 !important',
            lineHeight: '14px !important',
            fontSize: '11px',
            marginTop: '5px'
        },
        textSimpleLink: {
            color: '#04416d !important',
            fontWeight: '700 !important',
            fontSize: '11px !important',
            "&:hover": {
                textDecoration: 'underline !important'
            }
        },
        conSimpleLink: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }

    }

})