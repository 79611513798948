import instance from "services/axios"
import { ADMIN_API } from 'redux/api'
import { SET_PRODUCT_CATEGORY } from "redux/types";

const productServices = {
    getProductType: async (dispatch) => {
        try {
            let res = await instance.get(ADMIN_API.PRODUCT.GET_LIST_CATEGORY);
            dispatch({ type: SET_PRODUCT_CATEGORY, payload: res.data.data })
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    createProductCategory: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.PRODUCT.CREATE_CATEGORY, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    editProductCategory: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.PRODUCT.EDIT_CATEGORY, params);
            return res.status === 200 && res.data ? res.data.message : null
        } catch (error) {
            return null
        }
    },

    deleteSubProductCategory: async (subProductTypeId) => {
        try {
            let res = await instance.delete(ADMIN_API.PRODUCT.DELETE_SUB_CATEGORY+subProductTypeId);
            return res.status === 200 && res.data ? res.data.message : null
        } catch (error) {
            return null
        }
    },
    
    createProduct: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.PRODUCT.CREATE_PRODUCT, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    editProduct: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.PRODUCT.EDIT_PRODUCT, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    deleteProduct: async (productId) => {
        try {
            let res = await instance.delete(ADMIN_API.PRODUCT.DELETE_PRODUCT + productId);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },
    deleteProductType: async (productTypeId) => {
        try {
            let res = await instance.delete(ADMIN_API.PRODUCT.DELETE_TYPE + productTypeId);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    }

}

export default productServices