import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conBox: {
            width: '100%'
        },
        conTitle: {
            width: '100%',
            height: '30px',
            background: '#F36362',
            paddingLeft: '5px',
            color: 'white',
            position: 'relative',
            textTransform: 'uppercase',
            lineHeight: '32px'
        },
        conNewsItem: {
            background: '#fff',
            padding: '14px 0 !important',
            borderBottom: '1px solid #ddd',
            // height: '10px',
            display: 'block',
            // margin: '0 10px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between !important'
        },
        linkNewsCategory: {
            color: '#444 !important',
            paddingLeft: '5px',
            fontWeight: 'bold !important',
            fontSize: '13px',

        }
    }
})