import { Grid } from "@mui/material"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
// @mui icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useSelector } from "react-redux";

const ContactDetail = ({ iconSize = 20, ...props }) => {
    const companyDetail = useSelector(state => state.common.company_details)

    const social = [
        {
            link: "https://www.facebook.com/",
            icon: <FacebookIcon sx={{ color: '#FFF' }} />,
            color: "#344767",
        },
        {
            link: "https://twitter.com/",
            icon: <TwitterIcon sx={{ color: '#FFF' }} />,
            color: "#47ACDF",
        },
        {
            link: "https://www.instagram.com/",
            icon: <InstagramIcon sx={{ color: '#FFF' }} />,
            color: "#B13589",
        },
    ]
    return (
        <SuiBox>
            <Grid container>
                <Grid item xs={12} mb={1}>
                    <SuiBox display="flex" alignItems="flex-start">
                        <LocationOnIcon />
                        <SuiTypography {...props} ml={1}>{companyDetail?.address}</SuiTypography>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} mb={1}>
                    <SuiBox display="flex" alignItems="center">
                        <LocalPhoneIcon />
                        <SuiTypography {...props} ml={1}>{companyDetail?.hotline}</SuiTypography>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} mb={1}>
                    <SuiBox display="flex" alignItems="center">
                        <EmailIcon />
                        <SuiTypography {...props} ml={1}>{companyDetail?.email}</SuiTypography>
                    </SuiBox>
                </Grid>
                <Grid item xs={12}>
                    <SuiBox display="flex" justifyContent="center" my={2}>
                        {social.map(({ link, icon, color }) => (
                            <SuiBox sx={{ backgroundColor: color }} mr={2} p={2} width={iconSize} height={iconSize} borderRadius="lg" display="flex" alignItems="center" justifyContent="center">
                                {icon}
                            </SuiBox>
                        ))}
                    </SuiBox>
                </Grid>
            </Grid>
        </SuiBox>
    )
}

export default ContactDetail