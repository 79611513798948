import { Box, Typography } from "@mui/material"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import { useHistory } from "react-router-dom"
import { rewriteUrlPath } from "utils/convertPathUrl"
import styles from "./styles"
import { t } from "i18next"

const TopNewsItem = ({ image, title, id = null, lastEdit = '', views = 0, ...res }) => {
    const history = useHistory()
    const classes = styles()


    return (
        // <SuiBox width="100%" {...res} onClick={() => history.push(`/news/${rewriteUrlPath(title)}.html&query=${id}`)}>
        //     <SuiBox width="100%" height="100%" position="relative" borderRadius="lg">
        //         <SuiBox
        //             component="img"
        //             src={image}
        //             position="absolute"
        //             top={0}
        //             left={0}
        //             width="100%"
        //             height="100%"
        //             borderRadius="lg"
        //             sx={{ objectFit: "cover", objectPosition: "center" }}
        //         />
        //         <SuiBox
        //             display="flex"
        //             flexDirection="column"
        //             alignItems="flex-start"
        //             position="absolute"
        //             bottom={0}
        //             left={0}
        //             width="calc(100% - 1rem)"
        //         >
        //             <SuiBox p={2}>
        //                 <SuiTypography variant="h6" fontWeight="bold" color="white">
        //                     {title}
        //                 </SuiTypography>
        //             </SuiBox>
        //         </SuiBox>
        //     </SuiBox>
        // </SuiBox>
        <Box component={'a'} href={`/news/${rewriteUrlPath(title)}.html&query=${id}`}>
            <Box >
                <Box component={'img'} src={image} className={classes.conImg} />
            </Box>
            <Typography component={'h2'} className={classes.textTitle}>{title}</Typography>
            <Typography sx={{ color: '#858585' }}> {views} {t('views')} - {lastEdit}</Typography>
            <Box component={'div'} className={classes.conSubTitle}>
                Xác định được ký chủ của hai loài A.pasiflora và A.tenuissima có ý nghĩa rất quan trọng trong quy hoạch cơ cấu cây trồng, vùng trồng và phòng trừ bệnh do Alternaria gây ra. Việc định danh các loài nấm nói chung bằng cách quan sát các đặc điểm hình thái là một bước quan trọng cho việc xác định tên loài của các đối tượng cần quan sát.
            </Box>
            <br />
            <Box component={'a'} href={`/news/${rewriteUrlPath(title)}.html&query=${id}`} className={classes.btnViewDetail}>
                {t('view_detail')}
            </Box>
        </Box>
    )
}

export default TopNewsItem