import { makeStyles } from "@mui/styles";  

export default makeStyles(() =>{
    return{
        conBox:{
            minHeight: '30px',
            height: 'auto',
            display:'block',
            border: '1px solid #d3d3d3',
            backgroundColor: '#6e6566',
            marginBottom: '5px',
            color: 'white'
        },
        conList:{
            display: 'flex'
        },
        boxItem:{
            margin: '3px 0 0 3px',
            lineHeight: '22px',
            width:'auto',
            padding: '0px 15px'
        },
        boxTitle:{
            background: '#fdd500',
            border: '1px solid #e5c100',
            color: '#4a4a4a',
            margin: '3px 0 0 3px',
            lineHeight: '22px',
            width:'auto',
            padding: '0px 15px'
        },
        iconDown:{
            bottom: '0px'
        },
        conPopover:{
            background: '#f3f3f3',
            border: '1px solid #ddd',
            top: '30px',
            padding: '10px',
        },
        itemSubCategory:{
            color: 'black'
        },
        conItemSubCategory:{
            // border: '1px solid red',
            width: '190px',
            padding: '5px'
        }
    }
})