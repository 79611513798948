import React from "react";

const SVG = ({
    size = "100%",
    viewBox = "0 0 24 24",
    fill = "#3A416F",
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M12 3.53846C10.1472 3.53846 8.64516 5.03085 8.64516 6.8718C8.64516 8.71274 10.1472 10.2051 12 10.2051C13.8528 10.2051 15.3548 8.71274 15.3548 6.8718C15.3548 5.03085 13.8528 3.53846 12 3.53846ZM7.09677 6.8718C7.09677 4.18118 9.29202 2 12 2C14.708 2 16.9032 4.18118 16.9032 6.8718C16.9032 9.56241 14.708 11.7436 12 11.7436C9.29202 11.7436 7.09677 9.56241 7.09677 6.8718Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.46618 15.3333C7.30244 15.3333 5.54839 17.0761 5.54839 19.226C5.54839 19.3389 5.56915 19.4186 5.59092 19.465C5.60929 19.5041 5.62506 19.5153 5.63821 19.5224C6.23988 19.8477 7.92682 20.4615 12 20.4615C16.0732 20.4615 17.7601 19.8477 18.3618 19.5224C18.3749 19.5153 18.3907 19.5041 18.4091 19.465C18.4309 19.4186 18.4516 19.3389 18.4516 19.226C18.4516 17.0761 16.6976 15.3333 14.5338 15.3333H9.46618ZM4 19.226C4 16.2265 6.44729 13.7949 9.46618 13.7949H14.5338C17.5527 13.7949 20 16.2265 20 19.226C20 19.7766 19.7994 20.4966 19.1019 20.8737C18.1779 21.3733 16.1959 22 12 22C7.80414 22 5.82206 21.3733 4.89812 20.8737C4.20063 20.4966 4 19.7766 4 19.226Z" fill={fill} />
        </svg>
    );
};

export default SVG;
