import { getItemWithExpiry } from "./localStorage"

export const getModeView = () => {
    let modeView = {
        mode: null,
        desktop: {
            xs: 'none',
            sm: 'block'
        },
        mobile: {
            xs: 'block',
            sm: 'none'
        }
    }

    let modeChange = getItemWithExpiry('modeView')
    if (modeChange === 'desktop') {
        modeView.mode = 'desktop'
        modeView.desktop = {
            xs: 'block',
            sm: 'block'
        }
        modeView.mobile =
        {
            xs: 'none',
            sm: 'none'
        }
    }
    if (modeChange === 'mobile') {
        modeView.mode = 'mobile'
        modeView.desktop = {
            xs: 'none',
            sm: 'none'
        }
        modeView.mobile =
        {
            xs: 'block',
            sm: 'block'

        }
    }

    return modeView;
}