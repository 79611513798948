import { makeStyles } from "@mui/styles";
import { Layout_Config } from "config";

export default makeStyles(() => {
    return {
        conMobileWrapper: {
            maxWidth: `${Layout_Config.mobile_maxWidth}`,
            margin: '0 auto'
        }
    }
})