import { Box, Grid, createTheme, useMediaQuery } from "@mui/material"
import Footer from "components/Footer"
import Header from "components/Header"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Assets } from "constant"
import { useEffect, useState } from "react"
import commonProductServices from "redux/common/product"
import i18n from "services/language/i18n"
import commonNewsServices from "redux/common/news"
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux"
import { PRODUCT_DETAILS } from "redux/types"
import CHeaderBanner from "components/CHeaderBanner"
import CListCardItem from "components/CListCardItem"
import CListItem from "components/CListItem"
import CAdFrame from "components/CAdFrame"
import CTitleHeader from "components/CTitleHeader"
import CListNews from "components/CListNews"
import CBrandSlide from "components/CBrandSlide"
import { Layout_Config } from "config"
import MListProduct from "components/MListProduct"
import CListCategory from "components/CListCategory"
import styles from "./styles"
import commonCompanyServices from "redux/common/company"
import { setItemWithExpiry } from "utils/localStorage"
import { getModeView } from "utils/modeView"


const useStyles = makeStyles({
    productDetail: {
        "&:hover > .product-image": {
            transition: '0.3s ease-in',
            transform: 'scale(1.1)'
        },
        "&:hover > .product-more": {
            transition: '0.3s ease-in',
            transform: 'scale(1)'
        },
    },

});

const Home = () => {
    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const company = useSelector(state => state.common.company_details)
    const { t } = useTranslation()
    const classes = useStyles();
    const custClasses = styles()
    const history = useHistory();
    const dispatch = useDispatch()
    const dataAbout = [
        { bgColor: '#E3F0FF', image: Assets.manuFacture, title: t('card_intro_1'), },
        { bgColor: '#FFEFD7', image: Assets.services24, title: t('services_24/7') },
        { bgColor: '#D0F4DE', image: Assets.quality, title: t('quality_product') },
        { bgColor: '#E4C1F9', image: Assets.onlineSupport, title: t('online_support') },
    ]
    const [listProduct, setListProduct] = useState([])
    const [listNews, setListNews] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [allProducts, setAllProduct] = useState([])
    const [newsCategories, setNewsCategories] = useState(null)

    const [hightLightCategories, setHightLightCategories] = useState([])
    const [modeView, setModeView] = useState(null)
    const [lang, setLang] = useState(i18n.language);
    const DEFAULT_SIZE_HIGHLIGHT = 4

    // === LOGIC ===
    useEffect(() => {
        // getNewsList()
        getListProduct()
        setModeView(getModeView())
        if (!company) {
            getCompanyInfo()
        }

    }, [])

    useEffect(() => {
        if (listCategory.length === 0) {
            getListCategory()

        }
    }, [listCategory])

    useEffect(() => {
        if (!newsCategories) {
            getNewsCategories()
        }
    }, [newsCategories])

    const getListCategory = async () => {
        let res = await commonProductServices.getProductType(dispatch)

        setListCategory(res)
        if (res?.length > 0) {
            let param = {
                page: 1,
                maxItems: 25,
                categoryId: 2
            }

            let newData = await commonNewsServices.getNewsByFilter(param)
            setListNews(newData.data)
            if (res?.length > DEFAULT_SIZE_HIGHLIGHT) {
                setHightLightCategories(res.slice(3, 3 + DEFAULT_SIZE_HIGHLIGHT))
            }
            else {
                setHightLightCategories(res.slice(0, res.length - 1))
            }
        }

    }

    const getNewsCategories = async () => {
        let res = await commonNewsServices.listCategory(dispatch)
        if (res) {
            setListCategory(res)
        }
    }

    const getListProduct = async () => {
        let res = await commonProductServices.getListProduct(dispatch);
        setAllProduct(res)
        setListProduct(res.slice(0, 4))
    }

    const getNewsList = async () => {
        let res = await commonNewsServices.listNews();
        setListNews(res)
    }

    const onSelectProduct = (productId) => {
        dispatch({ type: PRODUCT_DETAILS, payload: productId })
        history.push(`/product.html`)
    }

    const getProductsByCategory = (categoryId) => {
        let all = [...allProducts]
        let data = all.filter((item) => {
            return item?.subProductType?.productTypeId === categoryId
        })

        return data
    }

    const getCompanyInfo = async () => {
        let res = await commonCompanyServices.getCompanyInfoWithCache(dispatch);
    }

    const changeModeView = (mode) => {
        setItemWithExpiry('modeView', mode, 600)
        setModeView(getModeView())
    }

    const onChangeLanguage = () => {
        let value = 'vi'
        if (lang === 'vi') {
            value = 'en'
        }
        setLang(value)
        i18n.changeLanguage(value);
        localStorage.setItem('lang', value)
    }


    const renderColByHightLightCategories = (item, index) => {

        let listProductsByFilter = getProductsByCategory(item?.productTypeId)

        let newsCategory = null

        if (newsCategories) {
        }

        // if (index < newsCategories.length) {
        //     newsCategory = newsCategories[index]
        // }

        // console.log(newsCategory)

        return (
            <Box>
                <CTitleHeader title={i18n.language === 'vi' ? item?.nameVi : item?.nameEn} category={item} />
                <Box mt={0.5}>
                    <Box component={'img'} width={'100%'} src="http://redstarvietnam.com/media/category/cb_d3306ab36c10519e174cd70561740e69.jpg"></Box>
                </Box>
                <Grid container>
                    <Grid item xs={8} md={8} >
                        <CListCardItem title={'Sản Phẩm Khuyến Mãi'} listItems={listProductsByFilter} />
                    </Grid>
                    <Grid item xs={4} md={4} pl={1}>
                        {
                            listNews &&
                            <CListNews title={i18n.language === 'vi' ? `Giải pháp ${item?.nameVi}` : `${item?.nameEn} solution`} listNews={listNews} />
                        }
                    </Grid>
                </Grid>

            </Box>
        )
    }


    return (
        <>
            <Header modeView={modeView} />
            <Box sx={{ background: 'white', height: '100%', minWidth: `${Layout_Config.minWidth}` }} display={modeView?.desktop}>

                <Box sx={{ width: `${Layout_Config.minWidth}`, margin: '0 auto' }}>
                    <Box>
                        <CHeaderBanner />
                    </Box>
                    <Box sx={{ background: 'white', margin: '1rem 0' }}>

                        <Grid container >
                            <Grid xs={8} md={8} item>
                                <Box>
                                    <CTitleHeader title={t('new_product_label')} />
                                    <CListCardItem title={t('new_product_label')} listItems={listProduct} isNewProduct={true} />
                                </Box>
                                <Box mt={2} />
                                <Box>
                                    <CTitleHeader title={t('sale_product_label')} />
                                    <CListCardItem title={t('sale_product_label')} listItems={listProduct} />
                                </Box>
                            </Grid>
                            <Grid xs={4} md={4} item pl={1}>
                                <Box component={'div'} textAlign={'center'} padding={'5px'} sx={{ border: '1px solid #146796' }}>
                                    <Box component={'a'} href="#">
                                        <Box component={'img'} src="http://redstarvietnam.com/media/lib/button-shop-nowds.png" />
                                    </Box>
                                </Box>
                                <Box component={'div'} mt={1} textAlign={'center'} >
                                    <Box component={'a'} href="#">
                                        <Box component={'img'} width={'100%'} src="http://redstarvietnam.com/media/lib/novica-2.jpg" />
                                    </Box>
                                </Box>
                                <Box component={'div'} mt={1}  >
                                    <CListItem title={t('news_label')} titleUrl='/news.html' />
                                    <Box component={'div'}>
                                        <CAdFrame title={t('new_video_chanel_lable')} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        {
                            hightLightCategories?.map((item, index) => {
                                return renderColByHightLightCategories(item, index)
                            })
                        }

                        <Box mt={5} mb={5}>
                            <CBrandSlide />
                        </Box>
                    </Box>
                    <Footer />
                </Box>
            </Box>
            <Box display={modeView?.mobile} sx={{ maxWidth: `${Layout_Config.mobile_maxWidth}`, margin: '0 auto' }}>
                <MListProduct listProducts={listProduct} />
                <CListCategory isMobile={true} />
                <Footer />
                {/* <div class="fb-comments" data-href="https://developers.facebook.com/docs/plugins/comments#configurator" data-width="" data-numposts="5"></div> */}
            </Box>
        </>

    )
}

export default Home