import { Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './styles'
import CListItem from 'components/CListItem'
import i18n from 'services/language/i18n'
import commonNewsServices from 'redux/common/news'
import { Layout_Config } from 'config'
import { useTranslation } from 'react-i18next'
import TopNewsItem from 'layouts/pages/news/top-news-item'
import { rewriteUrlPath } from "utils/convertPathUrl"

const CNewsList = (
    {
        title = '',
        newsItem = [],
        category = null
    }
) => {
    const classes = styles()
    const [listNews, setListNews] = useState([])
    const { t } = useTranslation()
    const [renderModeOne, setRenderModeOne] = useState(false)
    const [leftNewsCol, setLeftNewsCol] = useState([])
    const [rightNewsCol, setRightNewsCol] = useState([])

    useEffect(() => {
        if (listNews.length === 0 && category) {
            getListNews()
        }
    }, [listNews, category])

    const getListNews = async () => {
        if (category) {
            let param = {
                page: 1,
                maxItems: 10,
                categoryId: category.newsCategoryId
            }
            let res = await commonNewsServices.getNewsByFilter(param)
            if (res && res.data && res.data.length > 0) {
                let data = [...res.data]

                setListNews(data)

                if (data.length === 1) {
                    setRenderModeOne(true)
                } else {
                    let avg = ~~(data.length / 2)

                    let dataLeft = data.slice(0, avg)

                    setLeftNewsCol(dataLeft)

                    let dataRight = data.slice(avg, data.length)
                    setRightNewsCol(dataRight)
                }


            }
        }
    }

    const renderSimpleLink = (news) => {
        return (
            <ListItem >
                <Box component={'div'} className={classes.conSimpleLink}>
                    <Typography className={classes.textSimpleLink} component={'a'} href='#'>{i18n.language === 'vi' ? news.titleVi : news.titleEn}</Typography>
                </Box>
            </ListItem>
        )
    }

    const renderCellNews = (news) => {
        return (
            <>
                <ListItem sx={{ height: '130px' }}>
                    <Box component={'a'} href={`/news/${rewriteUrlPath(news.titleVi)}.html&query=${news.newsId}`} className={classes.conListItem}>
                        <ListItemAvatar>
                            <Box component={'img'} src={news?.preview} sx={{ width: '148px' }}>

                            </Box>
                        </ListItemAvatar>
                        <ListItemText className={classes.conTextTitle}>
                            <Typography className={classes.textTitle} component={'a'} href='#'>{i18n.language === 'vi' ? news.titleVi : news.titleEn}</Typography>
                            <Box component={'div'} className={classes.conInfo}>
                                {news.views} {t('views')} - {news.lastEdit}
                            </Box>
                        </ListItemText>
                    </Box>
                </ListItem>
                <Divider />
            </>
        )
    }

    return (
        <Box>
            <Typography component={'h2'} className={classes.boxTitle}>
                <Typography component={'a'} href='#' className={classes.textTitleHeader}>
                    {i18n.language === 'en' ? category?.nameEn : category?.nameVi}
                </Typography>
            </Typography>
            {
                renderModeOne ? (
                    <Box item>
                        <TopNewsItem
                            title={i18n.language === 'en' ? listNews[0].titleEn : listNews[0].titleVi}
                            image={listNews[0]?.preview}
                            sx={{ height: '12rem' }}
                            id={listNews[0].newsId}
                            lastEdit={listNews[0].lastEdit}
                            views={listNews[0].views}
                        />
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <List>
                                {
                                    leftNewsCol.map((news, index) => {
                                        return index === 0 ? renderCellNews(news) : renderSimpleLink(news)
                                    })
                                }
                            </List>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <List>
                                {
                                    rightNewsCol.map((news, index) => {
                                        return index === 0 ? renderCellNews(news) : renderSimpleLink(news)
                                    })
                                }
                            </List>
                        </Grid>
                    </Grid>
                )
            }

        </Box>
    )
}

export default CNewsList