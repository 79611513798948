import { useState } from "react";
import { useHistory } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
// Authentication layout components
import SignInLayout from "layouts/pages/sign-in/SignInLayout";

// Image
import chat from "assets/images/illustrations/chat.png";
import { useTranslation } from "react-i18next";
import userServices from "redux/user";
import SVGIcon from "components/Icon";


const SignIn = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hiddenPassword, setHiddenPassword] = useState(true);
    const [message, setMessage] = useState(null)

    const isValidPassword = () => {
        if (password.trim().length > 0 && password.length < 1) {
            return false
        }
        return true
    }

    const isValidEmail = () => {
        if (email.length === 0) {
            return false
        }
        return true
    }

    const validationForm = async () => {
        if (!isValidPassword()) {
            return { status: false, msg: t('error_password') }
        }
        if (!isValidEmail()) {
            return { status: false, msg: t('invalid_email') }
        }
        return { status: true, msg: '' }
    }

    const onSubmit = () => {
        validationForm().then(async (guard) => {
            if (guard.status) {
                loginUser()
            } else {
                setMessage(guard.msg)
            }
        })
    }

    const loginUser = async () => {
        let res = await userServices.loginUser(email, password)
        if (res) {
            history.push('/admin/company')
        } else {
            setMessage(t('error_password_email'))
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    return (
        <SignInLayout
            title="Sign In"
            description="Enter your email and password to sign in"
            image={chat}
        >
            <SuiBox>
                <SuiBox mb={2}>
                    <SuiInput type="email" placeholder="Email" size="large" value={email} onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress} />
                </SuiBox>
                <SuiBox mb={2}>
                    <SuiInput type={hiddenPassword ? "password" : "text"} placeholder={t('password')} size="large" value={password} onChange={e => setPassword(e.target.value)} icon={{ direction: "right", component: <SVGIcon name={hiddenPassword ? "password-hide" : "password-show"} size={20} />, action: () => setHiddenPassword(!hiddenPassword) }} onKeyPress={handleKeyPress} />
                </SuiBox>
                <SuiBox mb={1}>
                    {message &&
                        <SuiTypography variant="caption" color="error" fontWeight="light">
                            {message}
                        </SuiTypography>
                    }
                </SuiBox>
                <SuiBox mt={4} mb={1}>
                    <SuiButton variant="gradient" color="info" size="large" fullWidth onClick={onSubmit}>
                        sign in
                    </SuiButton>
                </SuiBox>
            </SuiBox>
        </SignInLayout>
    );
}

export default SignIn;
