import { ADMIN_API } from "redux/api";
import instance from "services/axios";

const newsServices = {

    createCategory: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.NEWS.CREATE_CATEGORY, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    editCategory: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.NEWS.EDIT_CATEGORY, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    deleteCategory: async (newsCategoryId) => {
        try {
            let res = await instance.delete(ADMIN_API.NEWS.DELETE_CATEGORY + newsCategoryId);
            return res.status === 200 && res.data ? res.data.message : null
        } catch (error) {
            return null
        }
    },

    deleteSubNewsCategory: async (subNewCategoryId) => {
        try {
            let res = await instance.delete(ADMIN_API.NEWS.DELETE_SUB_CATEGORY+subNewCategoryId);
            return res.status === 200 && res.data ? res.data.message : null
        } catch (error) {
            return null
        }
    },

    create: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.NEWS.CREATE, params);
            return res.status === 200 && res.data ? res.data.message : null
        } catch (error) {
            return null
        }
    },

    edit: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.NEWS.EDIT, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    delete: async (id) => {
        try {
            let res = await instance.delete(ADMIN_API.NEWS.DELETE + id);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },
}

export default newsServices