import { Modal, Grid } from '@mui/material'
import modal from 'assets/theme/base/modal'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiEditor from 'components/SuiEditor'
import FormField from 'components/FormField'
import { useState } from 'react'
import SuiButton from 'components/SuiButton'
import { useTranslation } from 'react-i18next'
import colors from 'assets/theme/base/colors'

const ModalAddBanner = ({ open = false, onClose = () => { } }) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null)

    const onUploadFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", () => setImage(reader.result), false);
        reader.readAsDataURL(file);
    };

    return (
        <Modal open={open} onClose={onClose} onBackdropClick={onClose}>
            <SuiBox sx={{ ...modal }} bgColor="white" borderRadius="md" shadow="md" width={'80%'} >
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} sm={4}>
                        <SuiBox my={1.5} ml={0.5} lineHeight={0}>
                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                {t('image')}
                            </SuiTypography>
                        </SuiBox>
                        {image ?
                            <SuiBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                component="img"
                                src={image}
                                alt="banner-image"
                                width={300}
                                sx={{ margin: 'auto' }}
                            />
                            :
                            <label htmlFor="upload-file">
                                <input accept="image/*" id="upload-file" type="file" onChange={onUploadFile} style={{ display: 'none' }} />
                                <SuiBox sx={{ border: `1px dashed ${colors['secondary'].main}` }} height={200} display="flex" justifyContent="center" alignItems="center">
                                    <SuiTypography variant="subtitle1">{t('upload_image')}</SuiTypography>
                                </SuiBox>
                            </label>
                        }
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Grid item xs={12}>
                            <FormField type="text" label={t('title')} value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                    {t('description')}
                                </SuiTypography>
                            </SuiBox>
                            <SuiEditor value={description} onChange={setDescription} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent={'flex-end'} >
                    <Grid item>
                        <SuiButton variant="outlined" color="info" onClick={onClose}>
                            {t('cancel')}
                        </SuiButton>
                    </Grid>
                    <Grid item>
                        <SuiButton
                            variant="gradient"
                            color="info"
                        >
                            {t('save')}
                        </SuiButton>
                    </Grid>
                </Grid>
            </SuiBox>
        </Modal>
    )
}

export default ModalAddBanner