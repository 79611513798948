/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill, { Quill } from "react-quill";


// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the SuiEditor
import SuiEditorRoot from "components/SuiEditor/SuiEditorRoot";
import ImageResize from 'quill-image-resize-module-react';



// Quill.register('modules/ImageResize', ImageResize);
const fontSizeArr = ['8px', '9px', '10px', '12px'];

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize);
// Quill.register(ImageResize, true)


function SuiEditor(props) {
  return (
    <SuiEditorRoot>
      <ReactQuill
        theme="snow"
        modules={SuiEditor.modules}

        formats={SuiEditor.formats}
        imageResize={SuiEditor.imageResize}

        {...props} />
      {/* <div className="themeSwitcher">
         <label>Theme </label>
         <select>
            <option value={'14px'}>Test</option>
         </select>
      </div> */}
    </SuiEditorRoot>
  );
}
SuiEditor.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'size': fontSizeArr }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      {
        color: ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
          "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
          "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
          "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
          "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"],
      },
    ],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },
    { 'indent': '-1' }, { 'indent': '+1' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['link', 'image'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }


}
SuiEditor.imageResize = {
  parchment: Quill.import('parchment'),
  modules: ['Resize', 'DisplaySize']

}


SuiEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'color', 'align',
]

export default SuiEditor;
