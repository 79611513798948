import { Box, Collapse, Container, Grid, Paper, Slide } from '@mui/material'
import CListCategory from 'components/CListCategory'
import CSlide from 'components/CSlide'
import React from 'react'
import { Fade } from 'react-slideshow-image';

const CHeaderBanner = () => {

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={1}>
                <Grid xs={6} md={6} item>
                    <CListCategory />
                </Grid>
                <Grid xs={6} md={6} item>
                    <CSlide />
                </Grid>
            </Grid>

        </Box >
    )
}

export default CHeaderBanner