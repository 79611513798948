import Header from "components/Header"
import SuiBox from "components/SuiBox"
import SuiTypography from "components/SuiTypography"
import { Box, Card, Container, Grid, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import ProductCategory from "../product-category"
import Footer from "components/Footer"
import { useHistory } from "react-router-dom"
import commonProductServices from "redux/common/product"
import i18n from 'services/language/i18n'
import { useTranslation } from "react-i18next"
import ProductInfo from "../product-item/product-info"
import { COLOR } from "constant"
import { useDispatch, useSelector } from "react-redux"
import ImgsViewer from "react-images-viewer";
import Slider from "react-slick";
import ReactImageMagnify from 'react-image-magnify';
import { rewriteUrlPath } from "utils/convertPathUrl"
import CBreadCrumbs from "components/CBreadCrumbs"
import styles from "./styles"
import CTabs from "components/CTabs"
import CListCardItem from "components/CListCardItem"
import { getModeView } from "utils/modeView"
import commonStyles from 'common/styles'
import Carousel from "react-material-ui-carousel"


const ProductItem = (props) => {
    const productId = Number(props.match.params.productId)
    const classes = styles()
    const commonClasses = commonStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const listProduct = useSelector(state => state.common.product_list)
    const [productList, setProductList] = useState([])
    const history = useHistory()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1)
    const [modeView, setModeView] = useState(null)
    const itemPerPage = 15;
    const lastItem = currentPage * itemPerPage;
    const firstItem = lastItem - itemPerPage;
    const data = productList.slice(firstItem, lastItem);
    const [category, setCategory] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [imgsViewer, setImgsViewer] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
    const settings = {
        dots: true,
        dotsClass: "slick-dots",
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        nextArrow: <SuiBox sx={{ right: 25, zIndex: 1 }}></SuiBox>,
        prevArrow: <SuiBox sx={{ left: 25, zIndex: 1 }}></SuiBox>,
        appendDots: dots => <ul style={{ bottom: 0 }}> {dots} </ul>,
        beforeChange: (current, next) => setImgsViewerCurrent(next)
    };

    const [productInfo, setProductInfo] = useState({
        images: [],
        titleEn: '',
        titleVi: '',
        model: '',
        manufactureEn: '',
        manufactureVi: '',
        price: t('contact_to_order'),
        quantity: 0,
        descriptionEn: '',
        descriptionVi: '',
        subProductType: null
    })


    const handleSetCurrentImage = (item, index) => {
        setCurrentImage(item.src);
        setImgsViewerCurrent(index);
    };


    // useEffect(() => {
    //     if(category === null && categoryId)
    //     {
    //         getCurrentCategory()
    //     }
    // },[currentCategory])

    const getCurrentCategory = async (categoryId) => {
        let res = await commonProductServices.getProductTypeDetail(categoryId)
        if (res) {
            setCategory(res)
        }
    }
    useEffect(() => {
        setModeView(getModeView())
    }, [])
    useEffect(() => {
        if (productId) {
            getProductDetails(productId);
        }
        if (listProduct) {
            setProductList(listProduct)
        }
    }, [productId, listProduct, i18n.language])


    const getProductDetails = async (productId) => {
        let res = await commonProductServices.getProductById(productId);
        if (res) {
            setCurrentImage(res.images && res.images.length > 0 && res.images[0])
            setProductInfo({
                images: res.images && res.images.length > 0 ? res.images.map(item => ({ src: item })) : [],
                titleEn: res.nameEn,
                titleVi: res.nameVi,
                model: res.model,
                manufactureEn: res.manufacturer.nameEn,
                manufactureVi: res.manufacturer.nameVi,
                price: res.price < 0 ? t('contact_to_order') : res.price,
                quantity: res.qty,
                descriptionEn: res.descriptionEn,
                descriptionVi: res.descriptionVi,
                subProductType: res.subProductType
            })
            if (res && res?.subProductType) {
                setSubCategory(res?.subProductType)
                getCurrentCategory(res?.subProductType?.productTypeId)
            }
            // setProductList(listProduct.filter(item => item.subProductType.productTypeId === res.subProductType.productTypeId))
        }
    }




    return (

        <>
            <Header />
            {/* <Container>
                <SuiBox my={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={1}>
                            <ProductCategory />
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <Card sx={{ overflow: "visible" }}>
                                <SuiBox p={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={2} xl={2}>

                                            {productInfo.images &&
                                                <ImgsViewer
                                                    imgs={productInfo.images}
                                                    isOpen={imgsViewer}
                                                    onClose={closeImgsViewer}
                                                    currImg={imgsViewerCurrent}
                                                    onClickPrev={imgsViewerPrev}
                                                    onClickNext={imgsViewerNext}
                                                    backdropCloseable
                                                />
                                            }

                                            <ReactImageMagnify {...{
                                                smallImage: {
                                                    alt: '',
                                                    isFluidWidth: true,
                                                    src: currentImage
                                                },
                                                largeImage: {
                                                    src: currentImage,
                                                    width: 1200,
                                                    height: 1800
                                                },
                                                style: { zIndex: 2 },
                                            }}
                                            />

                                            <SuiBox mt={3}>
                                                <Slider {...settings}>
                                                    {productInfo.images && productInfo.images.length > 0 && productInfo.images.map((item, index) => (
                                                        <SuiBox
                                                            key={index}
                                                            component="img"
                                                            src={item.src}
                                                            alt="small image"
                                                            borderRadius="lg"
                                                            shadow="md"
                                                            width="100%"
                                                            sx={{ cursor: "pointer", height: 100, objectFit: "cover" }}
                                                            onClick={() => handleSetCurrentImage(item, index)}
                                                        />
                                                    ))}
                                                </Slider>
                                            </SuiBox>

                                        </Grid>
                                        <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                                            <ProductInfo
                                                title={i18n.language === 'en' ? productInfo.titleEn : productInfo.titleVi}
                                                manufacture={productInfo.manufactureVi}
                                                price={productInfo.price}
                                                quantity={productInfo.quantity}
                                                id={productId}
                                            />
                                        </Grid>
                                    </Grid>
                                    <SuiBox mt={5} mb={2}>
                                        <SuiBox>
                                            <SuiTypography variant="button" textTransform="uppercase" sx={{ color: '#FFF', backgroundColor: COLOR.TEXT }} px={1.5} py={1.2}>
                                                {t('description')}
                                            </SuiTypography>
                                        </SuiBox>
                                    </SuiBox>
                                    <SuiBox pl={2} mb={2} ml={2}>
                                        <SuiTypography variant="body2" color="text" dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? productInfo.descriptionEn : productInfo.descriptionVi }} />
                                    </SuiBox>
                                </SuiBox>
                            </Card>
                            <SuiBox my={3}>
                                <Card>
                                    <SuiBox p={3} display="flex" justifyContent="space-between">
                                        <SuiTypography variant="button" textTransform="uppercase" sx={{ color: '#FFF', backgroundColor: COLOR.TEXT }} px={1.5} py={1.2}>
                                            {t('related_product')}
                                        </SuiTypography>
                                        <SuiTypography variant="button" sx={{ color: COLOR.TEXT, cursor: 'pointer' }} px={1.5} py={1.2} onClick={() => history.push(`/product.html&search=${productInfo.subProductType.productTypeId}&sub=null`)}>
                                            {t('view_more')}
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox p={3}>
                                        <Grid container spacing={3}>
                                            {data.length > 0 && data.filter(item => item.productId !== productId).slice(0, 4).map(item => (
                                                <Grid item xs={6} md={3}>
                                                    <SuiBox p={2} onClick={() => history.push(`/product/${rewriteUrlPath(item.nameEn)}.html&query=${item.productId}`)}>
                                                        <SuiBox position="relative" sx={{ overflow: 'hidden', cursor: 'pointer' }} borderRadius="lg">
                                                            <SuiBox
                                                                component="img"
                                                                src={item.images && item.images.length > 0 && item.images[0]}
                                                                alt="product-image"
                                                                width="100%"
                                                                height={{ xs: 100, md: 200 }}
                                                                display="inherit"
                                                                borderRadius="md"
                                                                sx={{ transition: '0.3s ease-in', '&:hover': { transform: 'scale(1.1)' } }}
                                                            />
                                                        </SuiBox>
                                                        <SuiBox mt={2}>
                                                            <SuiTypography
                                                                sx={{
                                                                    display: '-webkit-box',
                                                                    maxWidth: '100%',
                                                                    height: 43,
                                                                    margin: '0 auto',
                                                                    fontSize: 14,
                                                                    lineHeight: 1.5,
                                                                    '-webkit-line-clamp': '3',
                                                                    '-webkit-box-orient': 'vertical',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                                variant="h6"
                                                                fontWeight="bold"
                                                            >
                                                                {i18n.language === 'en' ? item.nameEn : item.nameVi}
                                                            </SuiTypography>
                                                        </SuiBox>
                                                    </SuiBox>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </SuiBox>
                                </Card>
                            </SuiBox>
                        </Grid>
                    </Grid>
                </SuiBox>
            </Container> */}
            <Box component={'div'} className={classes.conWrapper} display={modeView?.desktop}>
                <Box component={'div'} width={'100%'}>
                    <Box component={'div'} mb={2}>
                        <CBreadCrumbs category={category} subCategory={subCategory} />
                    </Box>
                    <Box component={'div'} className={classes.conCategorySideNav}>
                        <ProductCategory />
                    </Box>
                    <Box component={'div'} width={'1170px'} display={'block'} sx={{ float: 'right' }}>
                        <Box sx={{ overflow: "visible" }}>
                            <SuiBox >
                                <Box component={'div'} width={'1070px'} display={'block'}>
                                    <Box component={'div'} className={classes.conImgBox}>

                                        {productInfo.images &&
                                            <ImgsViewer
                                                imgs={productInfo.images}
                                                isOpen={imgsViewer}
                                                onClose={closeImgsViewer}
                                                currImg={imgsViewerCurrent}
                                                onClickPrev={imgsViewerPrev}
                                                onClickNext={imgsViewerNext}
                                                backdropCloseable
                                            />
                                        }

                                        <ReactImageMagnify {...{
                                            smallImage: {
                                                alt: '',
                                                isFluidWidth: true,
                                                src: currentImage
                                            },
                                            largeImage: {
                                                src: currentImage,
                                                width: 1200,
                                                height: 1800
                                            },
                                            style: { zIndex: 2 },
                                        }}

                                            className={classes.conImgShow}
                                        />

                                        <SuiBox mt={3}>
                                            <Slider {...settings}>
                                                {productInfo.images && productInfo.images.length > 0 && productInfo.images.map((item, index) => (
                                                    <SuiBox
                                                        key={index}
                                                        component="img"
                                                        src={item.src}
                                                        alt="small image"
                                                        shadow="md"
                                                        width="100%"
                                                        className={classes.conImageListItem}
                                                        onClick={() => handleSetCurrentImage(item, index)}
                                                    />
                                                ))}
                                            </Slider>
                                        </SuiBox>
                                    </Box>
                                    <Box component={'div'} className={classes.conBoxInfo}>
                                        <ProductInfo
                                            title={i18n.language === 'en' ? productInfo.titleEn : productInfo.titleVi}
                                            manufacture={productInfo.manufactureVi}
                                            price={productInfo.price}
                                            quantity={productInfo.quantity}
                                            id={productId}
                                            model={productInfo?.model}
                                            description={i18n.language === 'en' ? productInfo.descriptionEn : productInfo.descriptionVi}
                                        />
                                    </Box>

                                </Box>

                            </SuiBox>
                        </Box>
                        <Box component={'div'} width={'1070px'} display={'block'} sx={{ float: 'left' }}>
                            <CTabs description={i18n.language === 'en' ? productInfo.descriptionEn : productInfo.descriptionVi} />
                        </Box>
                        <Box p={2} component={'div'} width={'1070px'} display={'block'} sx={{ float: 'left' }}>
                            <Box component={'div'} className={classes.conWrapperOtherProduct}>
                                <Typography component={'h3'} className={classes.textSameProduct}>
                                    Sản phẩm cùng danh mục
                                </Typography>
                            </Box>
                            <Grid container spacing={3}>
                                {/* {data.length > 0 && data.filter(item => item.productId !== productId).slice(0, 4).map(item => (
                                                <Grid item xs={6} md={3}>
                                                    <SuiBox p={2} onClick={() => history.push(`/product/${rewriteUrlPath(item.nameEn)}.html&query=${item.productId}`)}>
                                                        <SuiBox position="relative" sx={{ overflow: 'hidden', cursor: 'pointer' }} borderRadius="lg">
                                                            <SuiBox
                                                                component="img"
                                                                src={item.images && item.images.length > 0 && item.images[0]}
                                                                alt="product-image"
                                                                width="100%"
                                                                height={{ xs: 100, md: 200 }}
                                                                display="inherit"
                                                                borderRadius="md"
                                                                sx={{ transition: '0.3s ease-in', '&:hover': { transform: 'scale(1.1)' } }}
                                                            />
                                                        </SuiBox>
                                                        <SuiBox mt={2}>
                                                            <SuiTypography
                                                                sx={{
                                                                    display: '-webkit-box',
                                                                    maxWidth: '100%',
                                                                    height: 43,
                                                                    margin: '0 auto',
                                                                    fontSize: 14,
                                                                    lineHeight: 1.5,
                                                                    '-webkit-line-clamp': '3',
                                                                    '-webkit-box-orient': 'vertical',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                                variant="h6"
                                                                fontWeight="bold"
                                                            >
                                                                {i18n.language === 'en' ? item.nameEn : item.nameVi}
                                                            </SuiTypography>
                                                        </SuiBox>
                                                    </SuiBox>
                                                </Grid>
                                            ))} */}
                                <CListCardItem title={'Sản Phẩm Khuyến Mãi'} listItems={data.filter(item => item.productId !== productId).slice(0, 4)} checkBoxCompare={false} />
                            </Grid>
                        </Box>
                    </Box>

                </Box>
                <div style={{ clear: 'both' }}></div>
                <Footer />

            </Box>
            <Box component={'div'} display={modeView?.mobile} className={commonClasses.conMobileWrapper}>
                <Box component={'div'} width={'100%'}>
                    <Box component={'div'} mb={2}>
                        <CBreadCrumbs category={category} subCategory={subCategory} />
                    </Box>
                </Box>
                <Box component={'div'} className={classes.boxSlide}>
                    <Carousel animation='slide' indicators={false} sx={{ height: '100%' }} autoPlay={true}>
                        {productInfo?.images.map((image, index) => (
                            <Box key={index} m={0.5} component={'div'} className={classes.conMobileSlide}>
                                <img style={{ objectFit: "contain", width: "130px !important", maxHeight: '130px' }} alt="Slide Image" src={image.src} />
                            </Box>

                        ))}
                    </Carousel>
                </Box>
                <Box component={'div'} className={classes.conMobileInfo}>
                    <Box component={'div'} sx={{ textTransform: 'uppercase', fontWeight: 'bold !important' }}>
                        <Typography sx={{ fontWeight: 'bold !important' }}>{i18n.language === 'en' ? productInfo.titleEn : productInfo.titleVi}</Typography>
                    </Box>
                    <Typography sx={{ fontWeight: 'bold !important' }}>{t('rate_product_label')} : </Typography>
                    <Typography sx={{ fontWeight: 'bold !important', color: 'red', fontSize: '14px !important' }}>{t('product_contact')}</Typography>
                    <Typography sx={{ fontWeight: 'bold !important', }}>
                        {t('product_insurance')} : <Typography component={'span'}>{t('product_insurance_none')}</Typography>
                    </Typography>
                    <Box component={'div'} sx={{ lineHeight: '18px !important' }} mt={2}>
                        <Typography component={'span'} className={classes.textFeature}>{t('outstanding_features')}</Typography>
                        <Typography sx={{ fontFamily: 'Arial,Helvetica,sans-serif !important', fontSize: '15px !important' }} variant="body2" color="text" dangerouslySetInnerHTML={{ __html: i18n.language === 'en' ? productInfo.descriptionEn : productInfo.descriptionVi }} />
                    </Box>
                    <Box component={'div'} sx={{ lineHeight: '18px !important' }} mt={2}>
                        <Typography component={'span'} className={classes.textFeature}>{t('product_specifications')}</Typography>

                    </Box>
                    <Box component={'div'} mt={2}>
                        <div class="fb-comments" data-href={`${window.location.href}??show_language=${i18n.language}`} data-width="" data-numposts="5"></div>
                    </Box>
                </Box>

                <Footer />
            </Box>

        </>
    )
}

export default ProductItem