import { Grid, Menu, MenuItem, Icon } from "@mui/material";
import AdminLayout from "components/Layout/AdminLayout";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SuiBadge from "components/SuiBadge";
import ModalDelete from "components/Modal/Delete";
import EditProductCategory from '../edit-category';
import productServices from 'redux/admin/product'
import i18n from "services/language/i18n";
import ModalSuccess from "components/Modal/Success";
import SuiSnackbar from "components/SuiSnackbar";
import ModalError from "components/Modal/Error";
import commonProductServices from "redux/common/product";
import { useDispatch } from "react-redux";

const CategoryList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openDelete, setOpenDelete] = useState({ visible: false, data: null })
    const [openMenu, setOpenMenu] = useState(null);
    const [editCategory, setEditCategory] = useState({ visible: false, data: null })
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [newCategory, setNewCategory] = useState({ en: '', vi: '' })
    const [actionStatus, setActionStatus] = useState({ status: '' })
    const [alert, setAlert] = useState({ status: false, msg: '' })
    const [listCategory, setListCategory] = useState([])

    useEffect(() => {
        getListCategory()
    }, [])

    const handleOpenMenu = (e, item) => {
        setSelectedCategory(item)
        setOpenMenu(e.currentTarget)
    };
    const handleCloseMenu = () => {
        setOpenMenu(null)
    };

    const getListCategory = async () => {
        let res = await commonProductServices.getProductType(dispatch)
        if (res) {
            setListCategory(res)
        }
    }

    const openEdit = () => {
        setEditCategory({ ...editCategory, visible: true, data: selectedCategory })
        handleCloseMenu()
    }
    const openRemove = () => {
        setOpenDelete({ ...openDelete, visible: true, data: selectedCategory.productTypeId })
        handleCloseMenu()
    }

    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
        >
            <MenuItem onClick={openEdit}>{t('edit')}</MenuItem>
            <SuiBox component="div" bgColor="secondary" opacity={0.3} width="100%" height="1px" my={1} />
            <MenuItem onClick={openRemove}>
                <SuiTypography variant="inherit" color="error">
                    {t('remove')}
                </SuiTypography>
            </MenuItem>
        </Menu>
    );

    const validationCategory = async () => {
        if (newCategory.en.trim().length > 0 && newCategory.vi.trim().length) {
            return { success: true }
        } else {
            return { success: false, msg: "error_empty_category_field" }
        }
    }

    const createCategory = () => {
        validationCategory().then(async (validation) => {
            if (validation.success) {
                const params = {
                    nameEn: newCategory.en,
                    nameVi: newCategory.vi
                }
                let res = await productServices.createProductCategory(params)
                setNewCategory({ en: '', vi: '' })
                setActionStatus({ status: res ? "success" : "error" })
                getListCategory()
            } else {
                setAlert({ status: true, msg: validation.msg })
                setNewCategory({ en: '', vi: '' })
            }
        })
    }

    const onCloseEdit = () => {
        setEditCategory({ visible: false, data: null })
        getListCategory()
    }

    const onDelete = async () => {
        let res = await productServices.deleteProductType(openDelete.data);
        setActionStatus({ status: res ? "success" : "error" })
        setOpenDelete({ visible: false, data: null })
        setActionStatus({ status: "success" })
        setListCategory(listCategory.filter(item => item.productTypeId !== selectedCategory.productTypeId))
    }

    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Grid container justifyContent={'flex-end'} mb={3}>
                    <Grid item>
                        <SuiBox display="flex" alignItems="center">
                            <SuiInput sx={{ borderRadius: 0, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} type="text" placeholder={t('enter_new_category') + '(EN)'} value={newCategory.en} onChange={(e) => setNewCategory({ ...newCategory, en: e.target.value })} />
                            <SuiInput sx={{ borderRadius: 0 }} type="text" placeholder={t('enter_new_category') + '(VN)'} value={newCategory.vi} onChange={(e) => setNewCategory({ ...newCategory, vi: e.target.value })} />
                            <SuiButton sx={{ borderRadius: 0, borderTopRightRadius: 20, borderBottomRightRadius: 20 }} variant="gradient" color="info" onClick={createCategory}>{t('add')}</SuiButton>
                        </SuiBox>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    {listCategory.length > 0 && listCategory.map(list => (
                        <Grid item xs={12} sm={4} lg={3}>
                            <SuiBox bgColor="white" borderRadius="md" shadow="md" p={2}>
                                <SuiBox display="flex" justifyContent="space-between" height={40}>
                                    <SuiTypography variant="button">{i18n.language === 'en' ? list.nameEn : list.nameVi}</SuiTypography>
                                    <SuiBox display="flex" >
                                        <SuiBadge
                                            variant="contained"
                                            color={'info'}
                                            size="sm"
                                            badgeContent={list.subProductType.length === 0 ? '0' : list.subProductType.length}
                                            circular
                                            container
                                            sx={{ height: 25, width: 25 }}
                                        />
                                        {renderMenu()}
                                        <SuiTypography
                                            color="text"
                                            onClick={(e) => handleOpenMenu(e, list)}
                                            sx={{ lineHeight: 0, cursor: "pointer" }}
                                        >
                                            <Icon fontSize="small">more_vert</Icon>
                                        </SuiTypography>
                                    </SuiBox>
                                </SuiBox>

                                <SuiBox my={1} height={120} sx={{ overflowX: 'auto' }}>
                                    {list.subProductType.length > 0 && list.subProductType.map(item => (
                                        <SuiBox>
                                            <SuiTypography variant="caption">{i18n.language === 'en' ? item.nameEn : item.nameVi}</SuiTypography>
                                        </SuiBox>
                                    ))}
                                </SuiBox>

                            </SuiBox>
                        </Grid>
                    ))}
                </Grid>
            </SuiBox>
            <SuiSnackbar
                color="error"
                icon="warning"
                title={t('form_validation')}
                content={t(alert.msg)}
                dateTime={t('now')}
                open={alert.status}
                onClose={() => setAlert({ status: false, msg: '' })}
                close={() => setAlert({ status: false, msg: '' })}
                bgWhite
            />
            <EditProductCategory
                open={editCategory.visible}
                data={editCategory.data}
                onClose={onCloseEdit}
            />
            <ModalDelete
                title={t('delete_category_title')}
                open={openDelete.visible}
                onClose={() => setOpenDelete({ visible: false, data: null })}
                onDelete={onDelete}
            />
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default CategoryList