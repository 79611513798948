import axios from "axios";
import { Base64 } from "js-base64";
import { PUBLIC_API } from "redux/api";
import instance from "services/axios";
import { BASE_URL } from "services/axios";

const userServices = {
    signOutUser: async () => {
        await localStorage.clear();
        window.location = window.origin;
    },
    loginUser: async (username, password) => {
        const body = new FormData();
        body.append("username", username);
        body.append("password", password);
        body.append("grant_type", "password");

        const authorizationString = `Basic ${Base64.encode(`${username}:${password}`)}`;

        try {
            let res = await axios.post(BASE_URL + PUBLIC_API.OAUTH, body, {
                headers: {
                    Authorization: authorizationString,
                    "Content-Type": "multipart/form-data",
                },
            })
            if (res.data) {
                localStorage.setItem("token", res.data.access_token);
                localStorage.setItem('expired_in', res.data.expires_in)
                localStorage.setItem('refresh_token', res.data.refresh_token)
                localStorage.setItem('fullClient', JSON.stringify(res.data))
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    },

    registerInformation: async (params) => {
        try {
            let res = await instance.post(PUBLIC_API.REGISTER, params);
            return res.status === 200 ? true : null
        } catch (error) {
            return null
        }
    },

    sendForm: async (params) => {
        try {
            let res = await instance.post(PUBLIC_API.FORM, params);
            return res.status === 200 ? true : null
        } catch (error) {
            return null
        }
    }
}

export default userServices