import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conProductItem: {
            width: '100%',
            overflow: 'hidden',
            transform: 'matrix(1, 0, 0, 1, 1550, 0)'
        },
        conProduct: {
            height: '160px',
            width: '100%',
            margin: '0 auto',
            display: 'flex'
            // paddingTop: '10px !important'
        },
        boxImg: {
            position: 'relative',
            float: 'left',
            width: '120px',
            height: '120px',
            verticalAlign: 'middle',
            textAlign: 'center',
            marginRight: '10px'
        },
        image: {
            maxWidth: '120px',
            maxHeight: '100%'
        },
        boxProductInfo: {
            // width: '170px',
            // height: '140px',
            verticalAlign: 'middle',

        },
        textProductName: {
            color: '#000',
            fontWeight: 'bold !important',
            display: 'block',
            textTransform: 'uppercase',
            width: '97%',
            marginBottom: '5px'
        },

        textProductInfo: {
            // marginRight: '120px !important'
            // float: "right"
        }
    }
})