import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './styles'
import commonProductServices from 'redux/common/product'
import { item } from 'examples/Sidenav/styles/sidenavItem'
import { useTranslation } from 'react-i18next'
import i18n from 'services/language/i18n'

const CBreadCrumbs = (
    {
        category = null,
        subCategory = null,
        isNewsPage = false
    }
) => {

    const { t } = useTranslation()
    const classes = styles()




    return (
        <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.conBreadCrumbs}>
                <Link underline="hover" key="1" color="inherit" href="/" className={classes.unactiveItem}>
                    {t('home_page_label')}
                </Link>
                {isNewsPage && <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/news.html"
                    className={classes.activeItem}
                >
                    {t('news')}
                </Link>}
                {category && <Link
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                    className={subCategory ? classes.unactiveItem : classes.activeItem}
                >
                    {i18n.language === 'vi' ? category?.nameVi : category?.nameEn}
                </Link>
                }
                {subCategory && <Typography key="3" color="red">
                    {i18n.language === 'vi' ? subCategory.nameVi : subCategory.nameEn}
                </Typography>}
            </Breadcrumbs>
        </Box>
    )
}

export default CBreadCrumbs