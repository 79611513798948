import { useSoftUIController, setMiniSidenav } from "context";
import Sidenav from "examples/Sidenav";
import brand from "assets/images/vietkhang/logo.png";
import listRoutes from "routes";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState } from "react";
import AdminNavbar from "../AdminNavbar";

const AdminLayout = ({ children }) => {
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    return (
        <>
            <Sidenav
                color={sidenavColor}
                brand={brand}
                routes={listRoutes.admin}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
            <DashboardLayout>
                <AdminNavbar />
                {children}
            </DashboardLayout>
        </>
    )
}

export default AdminLayout