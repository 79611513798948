export const convertCategoryToSelect = (array, lang) => {
    return Array.isArray(array) && array.length > 0 ? array.map(item => ({ value: item.productTypeId, label: lang === 'vi' ? item.nameVi : item.nameEn })) : []
}
export const convertSubCategoryToSelect = (array, lang) => {
    return Array.isArray(array) && array.length > 0 ? array.map(item => ({ value: item.subProductTypeId, label: lang === 'vi' ? item.nameVi : item.nameEn })) : []
}

export const convertManufactureToSelect = (array, lang) => {
    return Array.isArray(array) && array.length > 0 ? array.map(item => ({ value: item.manufacturerId, label: lang === 'vi' ? item.nameVi : item.nameEn })) : []
}
export const converNewsCategoryToSelect = (array, lang) => {
    return Array.isArray(array) && array.length > 0 ? array.map(item => ({ value: item.newsCategoryId, label: lang === 'vi' ? item.nameVi : item.nameEn })) : []
}
export const converNewsSubCategoryToSelect = (array, lang) => {
    return Array.isArray(array) && array.length > 0 ? array.map(item => ({ value: item.subNewCategoryId, label: lang === 'vi' ? item.nameVi : item.nameEn })) : []
}