import { Box, Button, Grid, Typography } from '@mui/material'
import CategoriesList from 'examples/Lists/CategoriesList'
import { item } from 'examples/Sidenav/styles/sidenavItem'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import commonProductServices from 'redux/common/product'
import styles from './styles'

const CListCategory = ({
    isMobile = false
}) => {
    const clasess = styles()
    const [listCategory, setListCategory] = useState(null)
    const dispatch = useDispatch()


    const getListCategory = async () => {
        let res = await commonProductServices.getProductType(dispatch);

        // hanlde
        let newData = res?.map((item, index) => {
            let newItem = {
                ...item,
                iconFisrt: Boolean(index % 2),
                bgColor: getColorBackgroup(index)
            }

            return newItem
        })
        setListCategory(newData)
    }

    // temp funtion to get color
    const getColorBackgroup = (index) => {
        switch (index) {
            case 0:
                return '#F23A6B'
                break;
            case 1:
                return '#F94A16'
                break;
            case 2:
                return '#992A9E'
                break;
            case 3:
                return '#09B392'
                break;
            case 4:
                return '#09B392'
                break;
            case 5:
                return '#10D0C2'
                break;
            case 6:
                return '#70b534'
                break;
            case 7:
                return '#09b392'
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (listCategory === null) {
            getListCategory()
        }
    }, [listCategory])

    const iconByImage = (urlImage) => {
        return (
            <Box component={'div'}
                sx={{
                    background: 'url(http://redstarvietnam.com/template/default/images/col_1_1.png) no-repeat 0 0 transparent;',
                    width: `${isMobile ? '30px' : '45px'} !important`,
                    height: `${isMobile ? '30px' : '45px'} !important`,
                    display: 'inline - block',
                    transition: 'all 300ms',
                    backgroundSize: 'contain'
                }} />
        )
    }

    const renderCol3Elment = (colNum, total) => {
        let data = []
        if (listCategory) {
            let start = colNum * total;
            if (total == 2) {
                start += 2
            }
            let end = start + total
            if (listCategory.length >= end) {
                data = listCategory.slice(start, end);
            }
        }
        return data.length > 0 ? (
            <Grid item xs={total === 3 ? (isMobile ? 4.5 : 4) : (isMobile ? 3 : 2)} md={total === 3 ? (isMobile ? 4.5 : 4) : (isMobile ? 3 : 2)} container direction={'column'} display={'flex'} justifyContent={'space-between'} flexWrap={'nowrap'}>
                {data.map((item, index) => {
                    return (
                        <Grid item sx={{ background: `${item.bgColor}`, height: '100%' }} m={'1px'} key={`col_product_type_${item?.productTypeId}`}>
                            <Box p={1} component={'a'} href={`/product.html&search=${item?.productTypeId}&sub=${null}`} className={total === 3 ? clasess.boxCol3Element : clasess.boxCol2Element} width={'100%'}>
                                {item.iconFisrt && iconByImage('')}
                                <Typography variant='custWhite' sx={{ maxWidth: '50%', fontSize: `${isMobile ? '10px' : '12px'} !important` }}>{item.nameEn}</Typography>
                                {!item.iconFisrt && iconByImage('')}
                            </Box>
                        </Grid>
                    )
                })}

            </Grid>
        ) : <></>
    }

    const renderCol2Element = (colNum) => {
        let data = []

        if (listCategory) {
            data = listCategory.slice(colNum * 3, (colNum * 3) + 3);
        }
    }

    return (
        <>
            <Grid container display={'flex'} whiteSpace={1}>
                {renderCol3Elment(0, 3)}
                {renderCol3Elment(1, 3)}
                {renderCol3Elment(2, 2)}
                {!isMobile && <Grid item xs={2} md={2} container direction={'column'} display={'flex'} justifyContent={'space-between'} flexWrap={'nowrap'}>
                    <Grid item sx={{ background: '#09b392', height: '100%' }} m={'1px'}>
                        <Box p={1} component={'a'} href='/' >
                            {iconByImage('')}
                            <Typography variant='custWhite'>Thiết Bị Phân Tích</Typography>
                        </Box>
                    </Grid>
                    <Grid item sx={{ background: '#F0039C', height: '100%' }} m={'1px'}>
                        <Box p={1} component={'a'} href='/' className={clasess.boxCol3Element} width={'100%'}>
                            {iconByImage('')}
                            <Typography variant='custWhite'>Thiết Bị Phân Tích</Typography>
                        </Box>
                    </Grid>
                </Grid>}
            </Grid>
            <div style={{ clear: 'both' }} />
            {
                isMobile &&
                <Grid container>
                    <Grid item xs={5.9} md={5.9} sx={{ background: '#ED1B24', height: '100%' }} m={'1px'}>
                        <Box p={1} component={'a'} href='/' display={'flex'} alignItems={'center'}>
                            {iconByImage('')}
                            <Typography variant='custWhite' sx={{ fontSize: '10px !important' }}>Gian Hàng Khuyến Mãi</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5.92} md={5.92} sx={{ background: '#034DA2', height: '100%' }} m={'1px'}>
                        <Box p={1} component={'a'} href='/' display={'flex'} alignItems={'center'}>
                            <Typography variant='custWhite' sx={{ fontSize: '10px !important' }}>Tin Tức Ứng Dụng</Typography>
                            {iconByImage('')}
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default CListCategory