import { Container, Grid } from "@mui/material"
import Header from "components/Header"
import Footer from "components/Footer"
import SuiBox from "components/SuiBox"
import ContactForm from "./component/ContactForm"
import ContactInfo from "./component/ContactInfo"

const Contact = () => {
    return (
        <>
            <Header />
            <SuiBox bgColor="white" py={10}>
                <Container>
                    <SuiBox>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1959.5210599033853!2d106.7836652711266!3d10.80808605505758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317526ed232edb7d%3A0x78656155f41ba998!2zxJDGsOG7nW5nIEQxNSwgUGjGsOG7m2MgTG9uZyBCLCBRdeG6rW4gOSwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1640613128736!5m2!1svi!2s" width={'100%'} height="450" allowfullscreen loading="lazy"></iframe>
                    </SuiBox>
                    <Grid container spacing={5} mt={3} justifyContent={'center'}>
                        <Grid item xs={12} md={4}>
                            <ContactForm />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ContactInfo />
                        </Grid>
                    </Grid>
                </Container>
            </SuiBox>
            <Footer />
        </>
    )
}

export default Contact