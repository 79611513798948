import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBadge from "components/SuiBadge";
import { useTranslation } from "react-i18next";
import ModalOrder from "../../modal-order";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./styles";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PhoneIcon from '@mui/icons-material/Phone';
import { useSelector } from "react-redux";

const ProductInfo = (
    { 
        title = '', 
        manufacture = '', 
        price = '', 
        quantity = 0, 
        id = null, 
        model = '' ,
        description = ''
    }
) => {
    const {t} = useTranslation();
    const [openOrder, setOpenOrder] = useState(false)
    const classes = styles()
    const companyDetail = useSelector(state => state.common.company_details)
    
    return (
        // <SuiBox>
        //     <SuiBox mb={1}>
        //         <SuiTypography variant="h3" fontWeight="bold">{title}</SuiTypography>
        //     </SuiBox>
        //     <SuiBadge variant="contained" color="success" badgeContent={quantity === 0 ? t('out_of_stock') : t('in_stock')} container />
        //     <SuiBox mt={3}>
        //         <SuiBox display="flex" fontSize="1.25rem" lineHeight={1}>
        //             <SuiTypography variant="body2" verticalAlign="middle" fontWeight="bold">
        //                 {t('manufacturer')}:
        //             </SuiTypography>
        //             <SuiTypography variant="body2" color="text" verticalAlign="middle" >
        //                 &nbsp;&nbsp; {manufacture}
        //             </SuiTypography>
        //         </SuiBox>
        //     </SuiBox>
        //     <SuiBox mt={3}>
        //         <Grid item xs={12} container>
        //             <SuiButton variant="gradient" color="success" size="large" fullWidth onClick={() => setOpenOrder(true)}>
        //                {t('contact_to_order')}
        //             </SuiButton>
        //         </Grid>
        //     </SuiBox>
        //     <ModalOrder open={openOrder} productName={title} id={id} onClose={() => setOpenOrder(false)}/>
        // </SuiBox>
        <Box>
            <Typography component={'h1'} 
                        sx={{color: '#333', fontSize: '19px', lineHeight: '24px'}}
            >
                {title}
            </Typography>
            <Box component={'div'} className={classes.conProductStatus}>
                <Typography component={'span'} sx={{fontWeight: 'bold'}}>
                    Model : 
                    <Typography component={'strong'} sx={{fontWeight: 'normal'}}> {model}</Typography>
                </Typography>
                <br/>
                <Typography component={'span'} sx={{fontWeight: 'bold'}}>
                    Status: 
                    <Typography component={'strong'} sx={{fontWeight: 'normal', color: '#090'}}> {quantity === 0 ? t('out_of_stock') : t('in_stock')}</Typography>
                </Typography>
                <br/>
                <Box component={'div'} className={classes.conProductDescription}>
                    {/* <Typography dangerouslySetInnerHTML={{ __html: description }}/> */}

                
                Giảm "hiệu ứng sản phẩm" và lãng phí do lỗi thải loại với Máy dò kim loại hiệu suất cao Thermo Science ™ APEX 500. Với độ nhạy vượt trội, lớp bảo vệ tiên tiến và thiết kế đa cuộn độc đáo với từ thông nhất quán, APEX 500 đang đáp ứng các yêu cầu khó khăn nhất của ngành công nghiệp thực phẩm.
                </Box>
                <Box component={'div'} className={classes.conProductWarranty}>
                    <br/>
                    <Typography component={'span'} sx={{fontWeight: 'bold'}}>
                        Bảo hành : 
                        <Typography component={'strong'} sx={{fontWeight: 'bold'}}> Chưa có thông tin bảo hành </Typography>
                    </Typography>
                </Box>
                <Box component={'div'} className={classes.conProductPrice}>
                    <br/>
                    <br/>
                        Giá bán : {price === '' || price === 0 ? 'Liên Hệ' : price}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </Box>
                <Box component={'div'} className={classes.conOrder}>
                    <Box component={'a'} href="/order.html" className={classes.buttonOrder}>
                        {t('order_label')}
                    </Box>
                    <Box component={'div'} className={classes.conShip}>
                        <Typography>
                            <CheckBoxIcon sx={{color: 'wheat !important'}}/>
                            <Typography component={'span'} sx={{color: 'white !important'}}>Khung giờ giao hàng từ 8h00 - 18h00 hàng ngày.</Typography>
                        </Typography>
                        <Typography>
                            <CheckBoxIcon sx={{color: 'wheat !important'}}/>
                            <Typography component={'span'} sx={{color: 'white !important'}}>Sản phẩm chính hãng, cung cấp CO & CQ.</Typography>
                        </Typography>
                        <Typography>
                            <CheckBoxIcon sx={{color: 'wheat !important'}}/>
                            <Typography component={'span'} sx={{color: 'white !important'}}>Bảo hành miễn phí 12 tháng với máy chính.</Typography>
                        </Typography>
                        <Typography>
                            <CheckBoxIcon sx={{color: 'wheat !important'}}/>
                            <Typography component={'span'} sx={{color: 'white !important'}}>Giá trên chỉ áp dụng đối với mặt hàng có sẵn.</Typography>
                        </Typography>
                        <Typography>
                            <Typography component={'span'} sx={{color: 'white !important'}}>Đối với mặt hàng không có sẵn sẽ tính thêm phí vận chuyển.</Typography>
                        </Typography>
                    </Box>
                </Box>
                <div style={{clear: 'both'}}/>
                <Box component={'div'} className={classes.boxContact}>
                    <Box component={'span'} className={classes.textContact}>
                        <PhoneIcon className={classes.iconPhoneContact}/>
                        Gửi email yêu cầu báo giá: 
                        <Typography component={'a'} href="#" sx={{fontSize: '15px', lineHeight: '22px', fontWeight: 'bold'}}> {companyDetail?.email} </Typography>
                    </Box>
                    <Typography component={'span'} sx={{color: '#747474', marginLeft: '35px', lineHeight: '22px'}}>
                                   Tất cả các ngày trong tuần
                    </Typography>
                </Box>
                
            </Box>
        </Box>
    );
}

export default ProductInfo