import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AppBar, Box, Typography, Button, InputBase, Grid } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import { useTranslation } from "react-i18next";
import i18n from "services/language/i18n";
import rgba from "assets/theme/functions/rgba";
import commonProductServices from "redux/common/product";
import { useDispatch, useSelector } from "react-redux";
import { COLOR } from "constant";
import commonCompanyServices from "redux/common/company";
import { rewriteUrlPath } from "utils/convertPathUrl";
import CNavTop from "components/CNavTop";
import styles from "./custStyle"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getModeView } from "utils/modeView";


const Header = ({ hasBanner = false, }) => {
    const { t } = useTranslation()
    const classes = styles();
    const history = useHistory()
    const location = useLocation();
    const dispatch = useDispatch()
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [lang, setLang] = useState(i18n.language);
    const listProduct = useSelector(state => state.common.product_list)
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([])
    const companyDetail = useSelector(state => state.common.company_details)
    const [modeView, setModeView] = useState(null)

    useEffect(() => {
        // getListProduct()
        if (!companyDetail) {
            getCompanyInfo()
        }
        setModeView(getModeView())
        // getListCategory()
    }, [])


    useEffect(() => {
        if (listProduct && search !== '') {
            let resultEn = listProduct.filter(item => item.nameEn.toLowerCase().includes(search.toLowerCase()))
            let resultVi = listProduct.filter(item => item.nameVi.toLowerCase().includes(search.toLowerCase()))
            if (resultEn && resultEn.length > 0) {
                setResult(resultEn)
            } else if (resultVi && resultVi.length > 0) {
                setResult(resultVi)
            } else {
                setResult([])
            }
        }
    }, [listProduct, search])


    const getCompanyInfo = async () => {
        await commonCompanyServices.getCompanyInfoWithCache(dispatch);
    }

    const handleOpenNavMenu = (e) => {
        setAnchorElNav(e.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const onChangeLanguage = (value) => {
        setLang(value)
        i18n.changeLanguage(value);
        localStorage.setItem('lang', value)
    }

    const getListProduct = async () => {
        let res = await commonProductServices.getListProduct(dispatch);
    }

    const getListCategory = async () => {
        let res = await commonProductServices.getProductType(dispatch);
    }

    const onSelectProduct = (item) => {
        history.push(`/product/${rewriteUrlPath(item.nameEn)}.html&query=${item.productId}`)
    }

    return (
        <>
            <AppBar
                position="sticky"
                color="inherit"
                sx={{
                    backgroundColor: rgba(colors['white'].main, 0.8),
                    paddingBottom: pxToRem(8),
                    top: 0,
                    backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
                    // maxWidth: '310px !important'
                    // minWidth: {xs: `${Layout_Config.minWidth} !important`, }
                }}

            >
                <CNavTop companyDetail={companyDetail} modeView={modeView} />

            </AppBar>
            {/* <Box>
                <SuiBox display={{ xs: 'none', md: 'block' }} sx={{ backgroundImage: `url(${Assets.bgHeader})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top' }}>
                    <Container maxWidth="xl">
                        <Toolbar>
                            <NavLink to="/">
                                <SuiBox
                                    customClass="cursor-pointer"
                                    mx="auto"
                                    mt={0.5}
                                    component="img"
                                    src={companyDetail?.logoUrl}
                                    alt="Logo"
                                    sx={{ width: pxToRem(100), flexGrow: 1, display: 'flex' }}
                                />
                            </NavLink>
                            <SuiBox sx={{ flexGrow: 1 }}>
                                <SuiBox py={1} pr={1} display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
                                    <SuiBox position="relative" width={300}>
                                        <SuiInput
                                            size="small"
                                            placeholder={t('search')}
                                            icon={{ component: "search", direction: "left" }}
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        {search !== '' &&
                                            <SuiBox position="absolute" bgColor="white" shadow="md" borderRadius="lg">
                                                {result.length > 0 && result.slice(0, 4).map(item => (
                                                    <SuiBox
                                                        p={2}
                                                        mb={1}
                                                        display="flex"
                                                        alignItems="center"
                                                        gap={1}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&:hover': { backgroundColor: COLOR.MAIN },
                                                            '&:hover > .product-name': { color: '#FFF !important' }
                                                        }}
                                                        onClick={() => onSelectProduct(item)}
                                                    >
                                                        <SuiBox
                                                            component="img"
                                                            src={item.images && item.images.length > 0 && item.images[0]}
                                                            alt="product-image"
                                                            width={40}
                                                            height={40}
                                                            display="inherit"
                                                            borderRadius="md"
                                                            className="product-image"
                                                        />
                                                        <SuiTypography variant="caption" className="product-name">{i18n.language === "en" ? item.nameEn : item.nameVi}</SuiTypography>
                                                    </SuiBox>
                                                ))}
                                                {result.length === 0 &&
                                                    <SuiBox width={300} p={2}>
                                                        <SuiTypography variant="button">{t('no_result_found')}</SuiTypography>
                                                    </SuiBox>

                                                }
                                            </SuiBox>
                                        }
                                    </SuiBox>
                                    <SuiBox component="img" src={Assets.FLAG_VN} onClick={() => onChangeLanguage('vi')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'vi' ? 1 : 0.3 }} />
                                    <SuiBox component="img" src={Assets.FLAG_US} onClick={() => onChangeLanguage('en')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'en' ? 1 : 0.3 }} />
                                </SuiBox>
                                <SuiBox sx={{ flexGrow: 1 }} display="flex" alignItems="center" justifyContent="center" ml={3}>
                                    {listRoutes.guest.map(item => {
                                        if (item.type === 'hidden') {
                                            return null
                                        }
                                        return (
                                            <SuiBox onClick={() => history.push(item.route)} sx={{ m: 2, color: 'white', display: 'block', cursor: "pointer" }}>
                                                <SuiTypography color={location.pathname === item.route ? 'success' : 'dark'} variant="h5" fontWeight="bold" textTransform="uppercase">{t(item.name)}</SuiTypography>
                                            </SuiBox>
                                        )
                                    })}
                                </SuiBox>
                            </SuiBox>
                        </Toolbar>
                    </Container>
                </SuiBox>
                <SuiBox display={{ xs: 'block', md: 'none' }}>
                    <Container maxWidth="xl">
                        <Toolbar>
                            <SuiBox sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' }
                                    }}
                                >
                                    {listRoutes.guest.map(item => {
                                        if (item.type === 'hidden') {
                                            return null
                                        }

                                        return (
                                            <MenuItem onClick={() => history.push(item.route)}>
                                                <SuiTypography textAlign="center" >{t(item.name)}</SuiTypography>
                                            </MenuItem>
                                        )
                                    })}
                                </Menu>
                            </SuiBox>
                            <NavLink to="/">
                                <SuiBox
                                    customClass="cursor-pointer"
                                    component="img"
                                    src={Assets.LOGO}
                                    alt="Logo"
                                    sx={{ width: pxToRem(80), flexGrow: 1, display: 'flex' }}
                                />
                            </NavLink>
                            <SuiBox display="flex" justifyContent="flex-end" sx={{ flexGrow: 1 }}>
                                <SuiBox component="img" src={Assets.FLAG_VN} onClick={() => onChangeLanguage('vi')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'vi' ? 1 : 0.3 }} />
                                <SuiBox component="img" src={Assets.FLAG_US} onClick={() => onChangeLanguage('en')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'en' ? 1 : 0.3 }} />
                            </SuiBox>
                        </Toolbar>
                    </Container>

                </SuiBox>
            </Box> */}
            <Box className={classes.conHeader} display={modeView?.desktop}>
                {/* <Toolbar> */}

                <Grid spacing={1} container sx={{ display: 'flex !important', alignItems: 'flex-end', width: '100%' }}>
                    <Grid item xs={2} md={2}>
                        <NavLink to="/">
                            <SuiBox
                                customclass="cursor-pointer"
                                mx="auto"
                                mt={0.5}
                                component="img"
                                src={companyDetail?.logoUrl}
                                alt="Logo"
                                sx={{ maxWidth: '130px', flexGrow: 1, display: 'flex' }}
                            />
                        </NavLink>
                    </Grid>
                    <Grid item xs={3} md={3} textAlign={'center'} pr={4}>
                        <Typography component={'h1'} variant="h1" py={1} className={classes.companyName} sx={{ color: 'black', textTransform: 'upcase' }} >{companyDetail?.name}</Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <SuiBox display="flex" alignItems="flex-end" gap={1} sx={{ paddingBottom: 0, width: '100%' }}>
                            <SuiBox width='100%'>
                                {/* <SuiInput
                                    sx={{ borderRadius: '0px !important' }}
                                    size="small"
                                    placeholder={t('search')}
                                    icon={{ component: "search", direction: "right" }}
                                    // endAdornment={
                                    //     <InputAdornment position='start'>
                                    //         <IconButton type='button'>
                                    //             <SearchIcon />
                                    //         </IconButton>
                                    //     </InputAdornment>
                                    // }
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                /> */}
                                <Box display={'flex'}>
                                    <InputBase
                                        sx={{
                                            borderRadius: '0px',
                                            padding: '3px',
                                            width: '100%',
                                            border: '1px solid #f36362'
                                        }}

                                        placeholder={t('search')}
                                        size="md"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}

                                    />

                                    <Button sx={{
                                        borderRadius: 'unset',
                                        background: '#f36362',
                                        fontSize: '12px',
                                        color: '#fff !important',
                                        width: '30%',
                                        textAlign: 'center',
                                        padding: '8px 6px',
                                        textTransform: 'unset'
                                    }}>
                                        {t('search_label')}
                                    </Button>
                                </Box>

                                {/* <Button>2</Button> */}
                                {search !== '' &&
                                    <SuiBox position="absolute" bgColor="white" shadow="md" borderRadius="lg" sx={{ zIndex: 1000 }}>
                                        {result.length > 0 && result.slice(0, 4).map(item => (
                                            <SuiBox
                                                p={2}
                                                mb={1}
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': { backgroundColor: COLOR.MAIN },
                                                    '&:hover > .product-name': { color: '#FFF !important' }
                                                }}
                                                onClick={() => onSelectProduct(item)}
                                            >
                                                <SuiBox
                                                    component="img"
                                                    src={item.images && item.images.length > 0 && item.images[0]}
                                                    alt="product-image"
                                                    width={40}
                                                    height={40}
                                                    display="inherit"
                                                    borderRadius="md"
                                                    className="product-image"
                                                />
                                                <SuiTypography variant="caption" className="product-name">{i18n.language === "en" ? item.nameEn : item.nameVi}</SuiTypography>
                                            </SuiBox>
                                        ))}
                                        {result.length === 0 &&
                                            <SuiBox width={300} p={2}>
                                                <SuiTypography variant="button">{t('no_result_found')}</SuiTypography>
                                            </SuiBox>

                                        }
                                    </SuiBox>
                                }
                            </SuiBox>
                            {/* <SuiBox component="img" src={Assets.FLAG_VN} onClick={() => onChangeLanguage('vi')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'vi' ? 1 : 0.3 }} />
                                <SuiBox component="img" src={Assets.FLAG_US} onClick={() => onChangeLanguage('en')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'en' ? 1 : 0.3 }} /> */}
                        </SuiBox>
                    </Grid>
                    <Grid item p={1} xs={3} md={3} display={'flex'} alignItems={'center'} textAlign={'center'} justifyContent={'space-evenly'}>
                        <Box component={'div'} textAlign={'center'} display={'flex'} alignItems={'flex-start'}>
                            <ShoppingCartIcon sx={{ fontSize: '32px !important' }} />
                            <Typography component={'a'} href="#" sx={{ color: '#333', fontSize: '13px', fontWeight: '400' }}>
                                &nbsp;&nbsp;{t('cart_label')}&nbsp;&nbsp;
                                <Typography component={'span'} color={'red'} fontSize={'16px'} fontWeight={1000}>0</Typography>
                            </Typography>

                        </Box>
                        <Box component={'div'} textAlign={'center'} display={'flex'} alignItems={'flex-start'} mb={'4px'}>
                            <Typography>
                                {t('total_label')}:&nbsp;&nbsp;
                                <Typography component={'span'} color={'red'} fontWeight={500}>0 đ</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {/* </Toolbar> */}
            </Box>

            <Box display={modeView?.mobile} className={classes.mWrapper}>
                <Box component={'div'} className={classes.mContent}>
                    <InputBase
                        sx={{
                            borderRadius: '0px',
                            padding: '3px',
                            width: '100%',
                            border: '1px solid #034EA2'
                        }}

                        placeholder={t('search')}
                        size="md"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}

                    />
                </Box>
                {search !== '' &&
                    <SuiBox position="absolute" bgColor="white" shadow="md" borderRadius="lg" sx={{ zIndex: 1000 }}>
                        {result.length > 0 && result.slice(0, 4).map(item => (
                            <SuiBox
                                p={2}
                                mb={1}
                                display="flex"
                                alignItems="center"
                                gap={1}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': { backgroundColor: COLOR.MAIN },
                                    '&:hover > .product-name': { color: '#FFF !important' }
                                }}
                                onClick={() => onSelectProduct(item)}
                            >
                                <SuiBox
                                    component="img"
                                    src={item.images && item.images.length > 0 && item.images[0]}
                                    alt="product-image"
                                    width={40}
                                    height={40}
                                    display="inherit"
                                    borderRadius="md"
                                    className="product-image"
                                />
                                <SuiTypography variant="caption" className="product-name">{i18n.language === "en" ? item.nameEn : item.nameVi}</SuiTypography>
                            </SuiBox>
                        ))}
                        {result.length === 0 &&
                            <SuiBox width={300} p={2}>
                                <SuiTypography variant="button">{t('no_result_found')}</SuiTypography>
                            </SuiBox>

                        }
                    </SuiBox>
                }
            </Box>
            {/* {hasBanner && <Box component={'div'} sx={{ background: 'white', width: `${Layout_Config.minWidth}` }}><CHeaderBanner /></Box>} */}
        </>
    )
}

export default Header