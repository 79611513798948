import { ADMIN_API } from "redux/api";
import instance from "services/axios";

const manufactuterServices = {
    list: async () => {
        try {
            let res = await instance.get(ADMIN_API.MANUFACTURE.LIST);
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    create: async (params) => {
        try {
            let res = await instance.post(ADMIN_API.MANUFACTURE.CREATE, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    edit: async (params, manufacturerId) => {
        try {
            let res = await instance.post(ADMIN_API.MANUFACTURE.EDIT + manufacturerId, params);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },

    delete: async (id) => {
        try {
            let res = await instance.delete(ADMIN_API.MANUFACTURE.DELETE + id);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    },
}

export default manufactuterServices