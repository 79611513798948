import { makeStyles } from "@mui/styles";
import { Layout_Config } from "config";

export default makeStyles(() => {
    return {
        conWrapper: {
            width: `${Layout_Config.minWidth}`,
            margin: '0px auto',
            position: 'relative'
        },
        boxSideNavLeft: {
            width: '240px',
            float: 'left'
        },
        conContent: {
            // width: '990px',
            padding: '10px 10px 30px 10px',
            marginLeft: '14px',
            background: '#fff',
            border: '1px solid #E7DFDF',
            float: 'right',
            overflow: 'hidden'
        },
        textNewsTitle: {
            fontWeight: 'bold !important',
            color: '#333 !important',
            fontSize: '20px !important',
            lineHeight: '30px !important'
        },
        textSubInfo: {
            color: '#858585 !important',
            padding: '5px 0',
            fontSize: '11px'
        },
        iconSameNews: {
            background: 'url(http://redstarvietnam.com/template/default/images/icon_total.png) no-repeat 0 0 transparent',
            width: '26px',
            height: '24px',
            display: 'block',
            left: 0,
            marginRight: '10px'
            // top: '7px',
            // position: 'absolute'
        },

        textSameNewsTitle: {
            fontSize: '18px !important',
            lineHeight: '14px !important',
            color: '#ff013d !important',
            display: 'flex',
            alignItems: 'center'
        },
        textCompanyRefTag: {
            color: 'white',
            textTransform: 'uppercase',
            lineHeight: '25px !important',
            background: '#555',
            width: '210px !important',
            padding: '0 10px',
            height: '25px',
            fontSize: '13px !important',
            textAlign: 'center'
        },
        mTitleText: {
            fontSize: '16px !important',
            lineHeight: '18px !important',
            marginBottom: '5px !important',
            fontWeight: '600 !important'
        }
    }
})