import { Box, FormControl, FormControlLabel, FormLabel, InputBase, List, ListItem, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles'
import StarIcon from '@mui/icons-material/Star';
import { Star } from '@mui/icons-material';
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge } from 'react-simple-captcha';

const FormCommentProduct = () => {
    
    const {t} = useTranslation()
    const classes = styles()
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userComment, setUserComment] = useState('')
    const [productRate, setProductRate] = useState('')

    useEffect(()=>{
        reloadCaptcha()
    },[])

    const reloadCaptcha = () =>{
        loadCaptchaEnginge(8, 'white', 'red')
    }

    const ActiveStartIcon = () =>{
        return (
            <StarIcon sx={{color: '#FFAE0B !important', fontSize: '24px !important'}}/>
        )
    }
    const UnActiveStartIcon = () =>{
        return (
            <StarIcon sx={{fontSize: '24px !important'}}/>
        )
    }

    const onSubmit = () =>{
        console.log(productRate)
    }

    return (
        <Box>
            <Box component={'div'}>
                <input 
                    placeholder={t('input_name_label')}
                    // className={classes.textFieldWrapper}
                    // inputProps={{
                    //     className: classes.textField
                    // }}
                    sx={{borderRadius: '0px', marginRight: 2}}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
                <input 
                    placeholder={t('input_email_label')}
                    // className={classes.textFieldWrapper}
                    // inputProps={{
                    //     className: classes.textField
                    // }}
                    sx={{borderRadius: '0px', marginRight: 2}}
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
                
            </Box>
            <Box component={'div'} mt={2}>
                <textarea 
                    className={classes.textUserComment} 
                    cols={60} 
                    rows={6}
                    value={userComment}
                    onChange={(e) => setUserComment(e.target.value)}
                />
            </Box>
            <Box component={'div'} display={'flex'}>
                <FormControl sx={{display: 'flex'}}>
                    <FormLabel>{t('rate_product_label')}</FormLabel>
                    <RadioGroup row value={productRate} onChange={(e) => setProductRate(e.target.value)}>
                        <FormControlLabel sx={{display: 'flex', alignItems: 'center'}} value="5" control={
                            <Radio size="small" />} label={
                            <Box>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                            </Box>
                        }/>
                        <FormControlLabel sx={{display: 'flex', alignItems: 'center'}} value="4" control={<Radio size="small" />} label={
                            <Box>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <UnActiveStartIcon/>
                            </Box>
                        }/>
                        <FormControlLabel sx={{display: 'flex', alignItems: 'center'}} value="3" control={<Radio size="small" />} label={
                            <Box>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <UnActiveStartIcon/>
                                <UnActiveStartIcon/>
                            </Box>
                        }/>
                        <FormControlLabel sx={{display: 'flex', alignItems: 'center'}} value="2" control={<Radio size="small" />} label={
                            <Box>
                                <ActiveStartIcon/>
                                <ActiveStartIcon/>
                                <UnActiveStartIcon/>
                                <UnActiveStartIcon/>
                                <UnActiveStartIcon/>
                            </Box>
                        }/>
                         <FormControlLabel sx={{display: 'flex', alignItems: 'center'}} value="1" control={<Radio size="small" />} label={
                            <Box>
                                <ActiveStartIcon/>
                                <UnActiveStartIcon/>
                                <UnActiveStartIcon/>
                                <UnActiveStartIcon/>
                                <UnActiveStartIcon/>
                            </Box>
                        }/>
                     
                    </RadioGroup>
                </FormControl>
            </Box>
            <div style={{clear: 'both'}}/>
            <Box mt={2}>
                <LoadCanvasTemplateNoReload/>
                <Box display={'flex'}>
                    <input type='text' placeholder={t('input_captcha_label')}/>
                    <Typography ml={1}>
                        {'('} 
                            <Typography component={'span'} 
                                        onClick={() => reloadCaptcha()}
                                        sx={{color: '#0000ED !important', cursor: 'pointer'}}>
                                            {t('reload_captcha_label')}
                            </Typography> 
                        {')'}
                    </Typography>
                </Box>
            </Box>
            <Box mt={2}>
                <button onClick={onSubmit()}>{t('send_comment_label')}</button>
            </Box>
        </Box>
    )
}

export default FormCommentProduct