import { Box, FormControl, InputAdornment, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import styles from './styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import i18n from 'services/language/i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const MenuProps = {
    PaperProps: {
        style: {
            borderRadius: '0px',
            padding: 0,
            backgroundColor: '#fff',
            border: '1px solid #ddd'
        },
    },
};
const CSelector = ({
    listItems = [],
    placeholder = {
        vi: '',
        en: ''
    },
    defaultIndexItem = null,
    fieldNameRenders = {
        vi: 'nameVi',
        en: 'nameEn'
    },
    onChangeSelector = () => { },
    ...props
}) => {
    const classes = styles()
    const [openSelector, setOpenSelector] = useState(false)
    const { t } = useTranslation()
    const [lang, setLang] = useState(i18n.language)
    const [currentItem, setCurrentItem] = useState(null)


    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language])

    const onChange = (event) => {
        setCurrentItem(event.target.value)
        onChangeSelector(event.target.value)
    }

    const renderItem = (item, index) => {
        return (
            <MenuItem value={item}
                key={`selector_item_${index}`}
                className={classes.boxItemSelector}
                sx={{
                    borderBottom: '1px dashed #ddd',
                    padding: 0,
                    color: '#333',
                    paddingLeft: '5px'
                }}
            >
                {lang === 'vi' ? item[fieldNameRenders.vi] : item[fieldNameRenders.en]}
            </MenuItem>
        )
    }

    return (
        <Box component={'div'} mt={props.mt}>
            <FormControl sx={{ width: '100%' }}>
                <Select
                    displayEmpty
                    MenuProps={MenuProps}
                    value={currentItem}
                    renderValue={(selected) => {
                        let dataRender = lang === 'vi' ? placeholder.vi : placeholder.en
                        if (selected) {


                            dataRender = lang === 'vi' ? selected[fieldNameRenders.vi] : selected[fieldNameRenders.en]
                        }
                        return (
                            <Box sx={{ width: '90%' }}>
                                <Typography >
                                    {dataRender}
                                </Typography>
                            </Box>
                        );
                    }}
                    sx={{
                        borderRadius: '0px !important',
                        cursor: 'pointer !important',
                        borderRadius: '0px !important',
                        background: '#eae8e9 !important',
                        backgroundColor: '#eae8e9 !important',
                        marginTop: '5px !important',
                        marginBottom: '10px !important',
                        color: '#333333 !important',
                        padding: '3px !important'
                    }}
                    onChange={onChange}
                    className={classes.conSelector}
                    inputProps={{
                        'aria-label': 'Without label',

                        className: classes.conSelectorItem
                    }}
                    open={openSelector}
                    endAdornment={
                        <InputAdornment position="end">
                            <ArrowDropDownIcon />
                        </InputAdornment>
                    }
                    onClick={() => {
                        setOpenSelector(!openSelector)
                    }}
                >
                    {
                        listItems.map((item, index) => {
                            return renderItem(item, index)
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    )
}

export default CSelector