import { makeStyles } from "@mui/styles";
import { Layout_Config } from "config";

export default makeStyles(() => {
    return {
        conHeader:
        {
            width: `${Layout_Config.minWidth}`,
            background: "#ffff",
            padding: '15px 0px',
            margin: '0 auto'
        },
        boxSearch:
        {
            borderRadius: '0px'
        },
        companyName: {
            fontSize: '15px !important',
            fontWeight: '700 !important',
            lineHeight: '14.4px',
            padding: '5px !important'
        },

        // === MOBILE ===

        mWrapper: {
            maxWidth: `${Layout_Config.mobile_maxWidth}`,
            margin: '0px auto',
            position: 'relative'
        },
        mContent: {
            width: '100%'
        }
    }
})