import { Card, Grid } from "@mui/material"
import colors from "assets/theme/base/colors"
import FormField from "components/FormField"
import AdminLayout from "components/Layout/AdminLayout"
import ModalError from "components/Modal/Error"
import ModalSuccess from "components/Modal/Success"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiEditor from "components/SuiEditor"
import SuiSelect from "components/SuiSelect"
import SuiTypography from "components/SuiTypography"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import newsServices from "redux/admin/news"
import commonNewsServices from "redux/common/news"
import i18n from "services/language/i18n"
import { styleImage } from "utils/CKEditorStyle"
import { converNewsCategoryToSelect, converNewsSubCategoryToSelect } from 'utils/convertData';
import { useDispatch } from "react-redux";

const AddPost = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null)
    const [nameEn, setNameEn] = useState('');
    const [nameVi, setNameVi] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionVi, setDescriptionVi] = useState('');
    const [listCategory, setListCategory] = useState([])
    const [tmp, setTmp] = useState([])
    const [listSubCategory, setListSubCategory] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [actionStatus, setActionStatus] = useState({ status: '' })

    useEffect(() => {
        getListCategory()
    }, [])

    useEffect(() => {
        if (selectedCategory) {
            setListSubCategory(converNewsSubCategoryToSelect(tmp.filter(item => item.newsCategoryId === selectedCategory.value).map(item => item.subNewCategory)[0], i18n.language))
        }
    }, [selectedCategory])

    const getListCategory = async () => {
        let res = await commonNewsServices.listCategory(dispatch)
        if (res) {
            setListCategory(converNewsCategoryToSelect(res.filter(item => item.subNewCategory && item.subNewCategory.length > 0)))
            setTmp(res.filter(item => item.subNewCategory && item.subNewCategory.length > 0))
        }
    }

    const onUploadFile = (e) => {
        const file = e.target.files[0];
        setFile(e.target.files[0])
        const reader = new FileReader();
        reader.addEventListener("load", () => setImage(reader.result), false);
        reader.readAsDataURL(file);
    };

    const onBack = () => {
        history.push('/admin/news/news-list')
    }

    const onSave = async () => {
        const body = new FormData();
        body.append('preview', file);
        body.append('titleEn', nameEn);
        body.append('titleVi', nameVi);
        body.append('descriptionEn', styleImage(descriptionEn));
        body.append('descriptionVi', styleImage(descriptionVi));
        body.append('subNewsCategoryId', selectedSubCategory.value);
        let res = await newsServices.create(body);
        setActionStatus({ status: res ? "success" : "error" })
        if (res) {
            onBack()
        }
    }


    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                        <Card sx={{ height: "100%", overflow: 'unset !important' }}>
                            <SuiBox m={3}>
                                <SuiTypography variant="h5" fontWeight="bold" mb={1}>
                                    {t('thumbnail')}
                                </SuiTypography>
                                {image ?
                                    <>
                                        <SuiBox
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            component="img"
                                            src={image}
                                            alt="post-image"
                                            width={'100%'}
                                            sx={{ margin: 'auto' }}
                                        />
                                        <SuiBox display="flex" justifyContent="center" mt={2}>
                                            <SuiBox mr={1}>
                                                <label htmlFor="upload-file">
                                                    <input accept="image/*" id="upload-file" type="file" onChange={onUploadFile} style={{ display: 'none' }} />
                                                    <SuiButton variant="gradient" color="info" size="small" >
                                                        edit
                                                    </SuiButton>
                                                </label>
                                            </SuiBox>
                                            <SuiButton variant="outlined" color="dark" size="small" onClick={() => setImage(null)}>
                                                remove
                                            </SuiButton>
                                        </SuiBox>
                                    </>
                                    :
                                    <label htmlFor="upload-file">
                                        <input accept="image/*" id="upload-file" type="file" onChange={onUploadFile} style={{ display: 'none' }} />
                                        <SuiBox sx={{ border: `1px dashed ${colors['secondary'].main}` }} height={200} display="flex" justifyContent="center" alignItems="center" mt={3}>
                                            <SuiTypography variant="subtitle1">{t('upload_image')}</SuiTypography>
                                        </SuiBox>
                                    </label>
                                }
                            </SuiBox>
                            <SuiBox mx={3}>
                                <SuiTypography variant="h5" fontWeight="bold" mb={1}>
                                    {t('category')}
                                </SuiTypography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <SuiBox>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('category')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiSelect
                                                options={listCategory}
                                                onChange={setSelectedCategory}
                                            />
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SuiBox>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('sub_category')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiSelect
                                                options={listSubCategory}
                                                onChange={setSelectedSubCategory}
                                            />
                                        </SuiBox>
                                    </Grid>

                                </Grid>
                            </SuiBox>
                            <SuiBox m={3}>
                                <Grid container spacing={1} alignItems="center">
                                    {/* <Grid item xs={6}>
                                        <SuiBox display="flex" justifyContent="center">
                                            <SuiButton variant="gradient" color="secondary" size="small" fullWidth>
                                                draff
                                            </SuiButton>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SuiBox display="flex" justifyContent="center">
                                            <SuiButton variant="gradient" color="dark" size="small" fullWidth>
                                                preview
                                            </SuiButton>
                                        </SuiBox>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <SuiBox display="flex" justifyContent="center">
                                            <SuiButton variant="outlined" color="secondary" size="small" onClick={onBack} fullWidth>
                                                {t('cancel')}
                                            </SuiButton>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SuiBox display="flex" justifyContent="center">
                                            <SuiButton variant="gradient" size="small" color="info" fullWidth onClick={onSave}>
                                                {t('save')}
                                            </SuiButton>
                                        </SuiBox>
                                    </Grid>
                                </Grid>
                            </SuiBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Card sx={{ overflow: "visible" }}>
                            <SuiBox p={3}>
                                <SuiTypography variant="h5">{t('post_information')}</SuiTypography>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('title') + ' (EN)'} value={nameEn} onChange={(e) => setNameEn(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('title') + ' (VN'} value={nameVi} onChange={(e) => setNameVi(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('description')}&nbsp;&nbsp;
                                                    <SuiTypography variant="caption" fontWeight="regular" color="text">
                                                        (EN)
                                                    </SuiTypography>
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiEditor value={descriptionEn} onChange={setDescriptionEn} />
                                            <SuiBox mt={1}>
                                                <SuiTypography variant="caption">{t('word_count')}: {descriptionEn.length === 0 ? 0 : descriptionEn.length - 7}</SuiTypography>
                                            </SuiBox>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('description')}&nbsp;&nbsp;
                                                    <SuiTypography variant="caption" fontWeight="regular" color="text">
                                                        (VN)
                                                    </SuiTypography>
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiEditor value={descriptionVi} onChange={setDescriptionVi} />
                                            <SuiBox mt={1}>
                                                <SuiTypography variant="caption">{t('word_count')}: {descriptionVi.length === 0 ? 0 : descriptionVi.length - 7}</SuiTypography>
                                            </SuiBox>
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox>
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default AddPost