import { makeStyles } from "@mui/styles";
import { Layout_Config } from "config";
import { position } from "stylis";

export default makeStyles(() => {
    return {
        conNav: {
            background: '#333',
            minWidth: `${Layout_Config.minWidth}`,
            // position: 'fixed'
        },
        conLayout: {
            display: 'flex !important',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '0 auto !important',
            position: 'relative',
            width: `${Layout_Config.minWidth}`
        },
        conMailAlert: {
            display: 'flex',
            alignItems: 'center'
        },
        mailAlertIcon: {
            position: 'relative',
            top: '10px'
        },
        replayIcon: {
            position: 'relative'
        },

        // === Mobile ===
        conMenu: {
            // height: '65px',
            // width: '100%',
            display: 'block',
            float: 'left',
            maxWidth: `${Layout_Config.mobile_maxWidth}`,
            margin: '0 auto !important',
            background: '#034EA2',
            color: 'white !important'
        },
        mConNav: {
            height: '65px',
            // width: '100%',
            display: 'block',
            float: 'left',
            maxWidth: `${Layout_Config.mobile_maxWidth}`,
            margin: '0 auto !important',
        },
        mLayout: {
            display: 'flex !important',
            margin: '0 auto !important',
            position: 'relative',
            width: `${Layout_Config.mobile_maxWidth}`
        },
        mHomeButton: {
            float: 'left',
            width: '40px',
            height: '23px',
            color: '#034EA2',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        boxItemMenu: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '120px',
            padding: '10px'
        },
        imgMenuItem: {
            height: '30px',
            marginBottom: '5px',
            maxWidth: '100%'
        },
        textMenuItem: {
            verticalAlign: 'top',
            textAlign: 'center',
            fontSize: '11px !important',
            color: 'white !important'
        }

    }

})