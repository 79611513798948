import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

import DataTable from "examples/Tables/DataTable";

// Data
import AdminLayout from "components/Layout/AdminLayout";
import orderServices from "redux/admin/order";
import i18n from "services/language/i18n";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import moment from "moment";


const OrderList = () => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    columns: [
      { Header: "id", accessor: "productOrderId", isSort: true },
      { Header: "status", accessor: "orderStatus", isSort: true, Cell: ({ row }) => StatusCell(row.original) },
      { Header: "customer", accessor: "customer.fullName", isSort: true },
      { Header: "product_name", accessor: i18n.language === 'en' ? "product.nameEn": 'product.nameVi', isSort: true },
    ],
    rows: []
  })
  const [dataExport, setDataExport] = useState([])

  useEffect(() => {
    getListOrder()
  }, [])

  const getListOrder = async () => {
    let res = await orderServices.getList();
    setData({ ...data, rows: res })
    if (res) {
      setDataExport(res.map(item => ({
        productOrderId: item.productOrderId,
        productName: item.product?.nameEn,
        productModel: item.product?.model,
        productOrderQuantity: item.product?.orderQuantity,
        orderStatus: item.orderStatus?.nameEn,
        orderContent: item.orderContent,
        customerName: item.customer?.fullName,
        customerEmail: item.customer?.email,
        customerPhone: item.customer?.phone,
        customerAddress: item.customer?.address,
        customerId: item.customer?.customerId,
      })))
    }
  }

  const renderStatusType = (type) => {
    switch (type) {
      case 'ORDER':
        return { icon: 'phone', color: 'info' };
      case 'CONTACT':
        return { icon: 'contacts', color: 'warning' };
      case 'PAID':
        return { icon: 'done', color: 'info' };
      case 'CANCEL':
        return { icon: 'done', color: 'info' };
      default:
        return { icon: 'replay', color: 'dark' };;
    }
  }

  const StatusCell = (data) => {

    return (
      <SuiBox display="flex" alignItems="center">
        <SuiBox mr={1}>
          <SuiButton variant="outlined" color={renderStatusType(data.orderStatus.nameEn).color} size="small" iconOnly circular>
            <Icon sx={{ fontWeight: "bold" }}>{renderStatusType(data.orderStatus.nameEn).icon}</Icon>
          </SuiButton>
        </SuiBox>
        <SuiTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
          {data.orderStatus.nameEn}
        </SuiTypography>
      </SuiBox>
    )
  }
  const renderCellCustomer = (data) => {
    return (
      <SuiTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>{data.customer.fullName}</SuiTypography>
    )
  }
  const renderCellProductName = (data) => {
    return (
      <SuiTypography variant="caption" fontWeight="medium" color="secondary">{i18n.language === 'en' ? data?.product?.nameEn : data?.product?.nameVi}</SuiTypography>
    )
  }
  const exportFile = () => {
    const fileExtension = ".xlsx";
    const fileName = "Order-File " + moment().format('L');
    const ws = XLSX.utils.json_to_sheet(dataExport)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws)
    XLSX.writeFile(wb, fileName + fileExtension)
  }

  return (
    <AdminLayout>
      <SuiBox my={3}>
        <Card>
          <DataTable
            table={data}
            entriesPerPage={false}
            canExport={{ title: t('export_orders'), action: exportFile }}
            canSearch
          />
        </Card>
      </SuiBox>
    </AdminLayout>
  );
}

export default OrderList;
