import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conFooter: {
            background: '#0a62ac',
            display: 'flex !important',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        textTitleInfoHeader: {
            color: 'white',
            textTransform: 'uppercase',
            fontSize: '13px !important',
            fontWeight: 'bold !important'
        },
        boxTextInfoItem: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        textLink: {
            // fontWeight: '500',
            color: '#fff',
            lineHeight: '15px',

            '&:hover': {
                textDecoration: 'underline'
            }
        },
        conPaymentFooter: {
            display: 'flex !important',
            alignItems: 'flex-start',
            color: '#656565'
        },
        registerText: {
            padding: '3px 5px',
            background: '#0a3f96',
            color: '#fff'
        },
        salesText: {
            textTransform: 'uppercase',
            fontWeight: 'bold !important'
        },
        textFieldRegisterWrapper: {
            paddingLeft: '10px !important',
            padding: '0px !important',
            borderRadius: '0px !important',

        },
        textFieldRegister: {
            height: '21px !important'
        },
        registerButton: {
            color: '#fff !important',
            background: 'red !important',
            borderRadius: '0px !important',
        },
        conMobileInfo: {
            lineHeight: '30px',
            fontSize: '15px',
            position: 'relative',
            borderBottom: '1px solid #d3d3d3',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500 !important'
        },
        boxMode: {
            padding: '10px',
            marginTop: '10px',
            lineHeight: '27px',
            height: '27px',
            backgroundColor: '#034EA2',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            cursor: 'pointer'
        }

    }
})