import { Box, Divider, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { rewriteUrlPath } from "utils/convertPathUrl"
import styles from './styles'
import commonNewsServices from 'redux/common/news'
import i18n from 'services/language/i18n'
import NewspaperIcon from '@mui/icons-material/Newspaper';

const CListItem = (
    {
        title = '',
        titleUrl = ''
    }
) => {

    const classes = styles()
    const [listNews, setListNews] = useState(null)

    useEffect(() => {
        if (!listNews) {
            getListNews()
        }
    }, [listNews])

    const getListNews = async () => {

        let param = {
            page: 2,
            maxItems: 5
        }
        let res = await commonNewsServices.getNewsByFilter(param)
        if (res && res.data && res.data.length > 0) {
            let data = [...res.data]
            setListNews(data)
        }

    }

    return (
        <Box>
            <Box component={'div'} className={classes.conHeader} display={'flex'} alignItems={'center'}>
                <NewspaperIcon color='white' sx={{ fontSize: '18px !important' }} />
                <Typography ml={1} variant='custWhite' component={'a'} href={titleUrl} sx={{ fontWeight: 'bold' }}>{title.toString().toUpperCase()}</Typography>
            </Box>
            <List>
                {listNews && listNews.map((news, index) => {
                    return (
                        <>
                            <ListItem>
                                <Box className={classes.conListItem} component={'a'} href={`/news/${rewriteUrlPath(news.titleVi)}.html&query=${news.newsId}`}>
                                    <ListItemAvatar>
                                        <Box component={'img'} sx={{ width: '148px' }} src={news.preview}>
                                        </Box>
                                    </ListItemAvatar>
                                    <ListItemText className={classes.conTextTitle}>
                                        <Typography className={classes.textTitle} component={'a'} href='#'>{i18n.language === 'vi' ? news.titleVi : news.titleEn}</Typography>
                                    </ListItemText>
                                </Box>
                            </ListItem>
                            <Divider />
                        </>
                    )
                })}

            </List>
        </Box >

    )
}

export default CListItem