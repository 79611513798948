import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conMobileInfo: {
            lineHeight: '30px',
            fontSize: '15px',
            position: 'relative',
            borderBottom: '1px solid #d3d3d3',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500 !important'
        },
        boxMode: {
            padding: '10px',
            marginTop: '10px',
            lineHeight: '27px',
            height: '27px',
            backgroundColor: '#034EA2',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            cursor: 'pointer'
        }
    }
})