import { makeStyles } from "@mui/styles";
import { Layout_Config } from "config";

export default makeStyles(() =>{
    return{
        conWrapper: {
            width: Layout_Config.minWidth,
            margin: '0px auto',
            position: 'relative'
        },
        conCategorySideNav: {
            width: '100px',
            float: 'left',

        },
    }
})