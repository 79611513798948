import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conWrapper: {
            width: '1160px',
            margin: '0px auto',
            position: 'relative'
        },
        boxSideNavLeft: {
            width: '240px',
            float: 'left'
        },
        conContent: {
            width: '883px',
            padding: '10px 10px 30px 10px',
            marginLeft: '14px',
            background: '#fff',
            border: '1px solid #E7DFDF',
            float: 'right'
        },
        conBoxProductItem: {
            // float: 'left',
            width: '100%',
            borderBottom: '1px dotted #d3d3d3',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'space-between'
        },
        conBoxProductImg: {
            maxWidth: '120px',
            maxHeight: '120px'
        },
        conBoxProductInfo: {
            color: '#000',
        },
        textProductName: {
            fontWeight: 'bold !important',
            textTransform: 'uppercase'
        }
    }
})