import { Card, Grid, Icon } from "@mui/material"
import colors from "assets/theme/base/colors"
import FormField from "components/FormField"
import AdminLayout from "components/Layout/AdminLayout"
import SuiBox from "components/SuiBox"
import SuiButton from "components/SuiButton"
import SuiEditor from "components/SuiEditor"
import SuiSelect from "components/SuiSelect"
import SuiTypography from "components/SuiTypography"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import productServices from "redux/admin/product";
import manufactuterServices from 'redux/admin/manufacturer'
import { convertCategoryToSelect, convertSubCategoryToSelect, convertManufactureToSelect } from "utils/convertData"
import i18n from 'services/language/i18n'
import ModalSuccess from "components/Modal/Success"
import ModalError from "components/Modal/Error"
import commonProductServices from "redux/common/product"
import { BASE_URL } from 'services/axios'
import { styleImage } from "utils/CKEditorStyle"
import { useSelector } from "react-redux"

const EditProduct = (props) => {
    const { t } = useTranslation();
    const history = useHistory()
    const productId = Number(props.match.params.productId)
    const [image, setImage] = useState([]);
    const [files, setFiles] = useState(null);
    const [name, setName] = useState({ en: '', vi: '' });
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionVi, setDescriptionVi] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(null);
    const [model, setModel] = useState('');
    const [tmp, setTmp] = useState({
        listCategory: [],
        listManufacture: []
    })
    // const [listCategory, setListCategory] = useState([])
    const [listSubCategory, setListSubCategory] = useState([])
    const [listManufacture, setListManufacture] = useState([])

    const [categorySelected, setCategorySelected] = useState(null)
    const [subCategorySelected, setSubCategorySelected] = useState(null);
    const [manufactureSelected, setManufactureSelected] = useState(null)
    const [actionStatus, setActionStatus] = useState({ status: '' })

    const listCategory = useSelector(state => state.common.product_category);

    useEffect(() => {
        if (listCategory) {
            getListManufacture()
            getProductDetails()
        }
    }, [listCategory])

    useEffect(() => {
        if (categorySelected) {
            setListSubCategory(convertSubCategoryToSelect(listCategory.filter(item => item.productTypeId === categorySelected.value).map(item => item.subProductType)[0], i18n.language))
        }
    }, [categorySelected])

    const getProductDetails = async () => {
        let res = await commonProductServices.getProductById(productId);
        if (res) {
            setImage(res.images);
            setName({ en: res.nameEn, vi: res.nameVi })
            setModel(res.model)
            setPrice(res.price)
            setQuantity(res.qty)
            setDescriptionEn(res.descriptionEn)
            setDescriptionVi(res.descriptionVi)
            setManufactureSelected(convertManufactureToSelect([res.manufacturer])[0])
            let productCategory = listCategory.filter(item => item.productTypeId === res.subProductType.productTypeId)
            let subCategory = productCategory[0].subProductType.filter(item => item.subProductTypeId === res.subProductType.subProductTypeId)
            setCategorySelected(convertCategoryToSelect(productCategory)[0])
            setSubCategorySelected(convertSubCategoryToSelect(subCategory)[0])
        }
    }

    const onUploadFile = (e) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            setFiles(files)
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    setImage(images)
                }, error => {
                    console.error(error);
                });
        }
    };

    const onRemoveImage = (index) => {
        image.splice(index, 1);
        setImage([...image])
    }

    const onBack = () => {
        history.push('/admin/product/product-list')
    }

    const onSave = async () => {
        const params = new FormData();
        params.append('productId', productId)
        if(files){
            for(let key of Object.keys(files)) {
                if (key !== 'length') {
                    params.append('images', files[key]);
                }
            }
        }    
        // params.append('images', image)
        params.append('nameEn', name.en)
        params.append('nameVi', name.vi)
        params.append('descriptionEn', styleImage(descriptionEn));
        params.append('descriptionVi', styleImage(descriptionVi));
        params.append('qty', quantity)
        params.append('price', price)
        params.append('model', model)
        params.append('subProductTypeId', subCategorySelected.value)
        params.append('manufacturerId', manufactureSelected.value)
        let res = await productServices.editProduct(params)
        setActionStatus({ status: res ? 'success' : 'error' })
        if (res) {
            onBack()
        }
    }

    const getListManufacture = async () => {
        let res = await manufactuterServices.list();
        setListManufacture(convertManufactureToSelect(res, i18n.language))
    }

    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                        <Card sx={{ height: "100%" }}>
                            <SuiBox p={3}>
                                <SuiTypography variant="h5" fontWeight="bold">
                                    {t('product_image')}
                                </SuiTypography>
                                {image.length > 0 ?
                                    <>
                                        <SuiBox display="flex" flexDirection="column" gap={1}>
                                            {image.map((item, index) => (
                                                <SuiBox position="relative">
                                                    <SuiBox
                                                        component="img"
                                                        src={item}
                                                        alt="product-image"
                                                        width={150}
                                                        height={150}
                                                        borderRadius="md"
                                                        shadow="md"
                                                    />
                                                    <SuiBox position='absolute' top={'50%'} left={'50%'} sx={{ transform: "translate(-50%, -50%)" }}>
                                                        <SuiButton variant="contained" color={'error'} sx={{ opacity: 0.6 }} size="small" iconOnly circular onClick={() => onRemoveImage(index)}>
                                                            <Icon sx={{ fontWeight: "bold" }}>{'close'}</Icon>
                                                        </SuiButton>
                                                    </SuiBox>
                                                </SuiBox>

                                            ))}
                                        </SuiBox>
                                    </>
                                    :
                                    <label htmlFor="upload-file">
                                        <input accept="image/*" id="upload-file" type="file" onChange={onUploadFile} multiple style={{ display: 'none' }} />
                                        <SuiBox sx={{ border: `1px dashed ${colors['secondary'].main}` }} height={200} display="flex" justifyContent="center" alignItems="center" mt={3}>
                                            <SuiTypography variant="subtitle1">{t('upload_image')}</SuiTypography>
                                        </SuiBox>
                                    </label>
                                }
                            </SuiBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Card sx={{ overflow: "visible" }}>
                            <SuiBox p={3}>
                                <SuiTypography variant="h5">{t('product_information')}</SuiTypography>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('product_name') + ' (VN)'} value={name.vi} onChange={(e) => setName({ ...name, vi: e.target.value })} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('product_name') + ' (EN)'} value={name.en} onChange={(e) => setName({ ...name, en: e.target.value })} />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <FormField type="text" label={t('model')} value={model} onChange={(e) => setModel(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('manufacturer')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiSelect
                                                value={manufactureSelected}
                                                options={listManufacture}
                                                onChange={setManufactureSelected}
                                            />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={3}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('category')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiSelect
                                                value={categorySelected}
                                                options={convertCategoryToSelect(listCategory)}
                                                onChange={setCategorySelected}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography
                                                    component="label"
                                                    variant="caption"
                                                    fontWeight="bold"
                                                    textTransform="capitalize"
                                                >
                                                    {t('sub_category')}
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiSelect
                                                value={subCategorySelected}
                                                options={listSubCategory}
                                                onChange={setSubCategorySelected}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormField type="number" label={t('price')} value={price} onChange={(e) => setPrice(e.target.value)} />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <FormField type="number" label={t('quantity')} value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                                <SuiBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('description')}&nbsp;&nbsp;
                                                    <SuiTypography variant="caption" fontWeight="regular" color="text">
                                                        (VN)
                                                    </SuiTypography>
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiEditor value={descriptionVi} onChange={setDescriptionVi} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                                <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                    {t('description')}&nbsp;&nbsp;
                                                    <SuiTypography variant="caption" fontWeight="regular" color="text">
                                                        (EN)
                                                    </SuiTypography>
                                                </SuiTypography>
                                            </SuiBox>
                                            <SuiEditor value={descriptionEn} onChange={setDescriptionEn} />
                                        </Grid>
                                    </Grid>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox>
            <SuiBox mt={3}>
                <Grid container spacing={3} alignItems="center" justifyContent={'flex-end'}>
                    <Grid item>
                        <SuiBox display="flex" justifyContent="flex-end">
                            <SuiButton variant="outlined" color="info" size="small" onClick={onBack}>
                                back
                            </SuiButton>
                        </SuiBox>
                    </Grid>
                    <Grid item>
                        <SuiBox display="flex" justifyContent="flex-end">
                            <SuiButton variant="gradient" size="small" color="info" onClick={onSave}>
                                save
                            </SuiButton>
                        </SuiBox>
                    </Grid>
                </Grid>
            </SuiBox>

            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default EditProduct