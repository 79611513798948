import { Card, Tooltip } from "@mui/material";
import AdminLayout from "components/Layout/AdminLayout";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SuiBadge from "components/SuiBadge";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDelete from "components/Modal/Delete";
import moment from "moment";
import { useHistory } from "react-router-dom";
import commonNewsServices from "redux/common/news";
import ModalSuccess from "components/Modal/Success";
import ModalError from "components/Modal/Error";
import newsServices from "redux/admin/news";
import i18n from 'services/language/i18n'
import { BASE_URL } from 'services/axios'

// Badges
const notPublished = (
    <SuiBadge variant="contained" color="secondary" size="xs" badgeContent="draff" container />
);
const published = (
    <SuiBadge variant="contained" color="success" size="xs" badgeContent="published" container />
);

const NewsList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [openDelete, setOpenDelete] = useState({ visible: false, data: null })
    const [actionStatus, setActionStatus] = useState({ status: '' })
    const [data, setData] = useState({
        columns: [
            { Header: t("title"), accessor: "", width: "30%",isSort: true, Cell: ({ row }) => renderCellTitle(row.original) },
            { Header: t("category"), accessor: "", align: 'center', isSort: true, Cell: ({ row }) => renderCellCategory(row.original) },
            { Header: t('views'), accessor: "views", align: 'center', isSort: true },
            { Header: t("last_edit"), accessor: "lastEdit", Cell: ({ value }) => renderCellTime(value), align: 'center', isSort: true },
            { Header: "", accessor: "action", width: '10%', Cell: ({ row }) => renderCellAction(row.original), align: 'center' },
        ],
        rows: []
    })

    useEffect(() => {
        getNewsList()
    }, [])

    const getNewsList = async () => {
        let res = await commonNewsServices.listNews();
        setData({ ...data, rows: res })
    }

    const renderCellTitle = (data) => {
        return (
            <SuiBox display="flex" alignItems="center">
                <SuiBox mx={2} width="3.75rem">
                    <SuiBox component="img" src={data?.preview ? data?.preview : ''} alt={''} width="100%" />
                </SuiBox>
                <SuiTypography variant="button" fontWeight="medium">
                    {i18n.language === 'en' ? data.titleEn : data.titleVi}
                </SuiTypography>
            </SuiBox>
        )
    }

    const renderCellCategory = (data) => {
        return (
            <SuiTypography variant="button" fontWeight="medium">
                {i18n.language === 'en' ? data.subNewsCategory.nameEn : data.subNewsCategory.nameVi}
            </SuiTypography>
        )
    }

    const renderCellTime = (time) => {
        return (
            <SuiBox>
                <SuiTypography variant="button" fontWeight="medium">
                    {moment(time).format('DD-MM-YYYY')}
                </SuiTypography>
            </SuiBox>
        )
    }

    const renderCellAction = (data) => {
        return (
            <SuiBox display="flex" gap={1} sx={{ cursor: 'pointer' }}>
                <Tooltip title={t('view')} placement="top">
                    <SuiBox bgColor="info" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <VisibilityIcon />
                    </SuiBox>
                </Tooltip>
                <Tooltip title={t('edit')} placement="top">
                    <SuiBox bgColor="warning" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <EditIcon onClick={() => history.push(`/admin/news/edit/${data.newsId}`)} />
                    </SuiBox>
                </Tooltip>
                <Tooltip title={t('delete')} placement="top">
                    <SuiBox bgColor="error" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <DeleteIcon onClick={() => setOpenDelete({ visible: true, data: data.newsId })} />
                    </SuiBox>
                </Tooltip>
            </SuiBox>
        )
    }

    const onDeletePost = async () => {
        let res = await newsServices.delete(openDelete.data)
        if (res) {
            let tmp = data.rows.filter(item => item.newsId !== openDelete.data)
            setData({ ...data, rows: tmp })
        }
        setActionStatus({ status: res ? 'success' : 'error' })
        setOpenDelete({ visible: false, data: null })
    }

    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Card>
                    <DataTable
                        table={data}
                        entriesPerPage={{
                            defaultValue: 7,
                            entries: [5, 7, 10, 15, 20, 25],
                        }}
                        canAdd={{
                            title: '+ new post',
                            href: '/admin/news/new-post'
                        }}
                        canSearch
                    />
                </Card>
            </SuiBox>
            <ModalDelete
                title={t('delete_post_title')}
                open={openDelete.visible}
                onClose={() => setOpenDelete({ visible: false, data: null })}
                onDelete={onDeletePost}
            />
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default NewsList