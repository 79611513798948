import React from "react";

const SVG = ({
    size = "100%",
    viewBox = "0 0 18 24",
    fill = "#3A416F",
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M16.3652 6.69824H1.63477C1.18901 6.69824 0.827637 7.05958 0.827637 7.5053V20.1135C0.827637 22.2566 2.5712 24 4.71411 24H13.2856C15.4286 24 17.1722 22.2566 17.1722 20.1135V7.5053C17.1722 7.05961 16.811 6.69824 16.3652 6.69824ZM7.05975 19.139C7.05975 19.6866 6.61583 20.1305 6.06814 20.1305C5.52065 20.1305 5.07654 19.6866 5.07654 19.139V11.5593C5.07654 11.0116 5.52058 10.5677 6.06814 10.5677C6.61579 10.5677 7.05975 11.0117 7.05975 11.5593V19.139ZM12.9233 19.139C12.9233 19.6866 12.4794 20.1305 11.9317 20.1305C11.3842 20.1305 10.9401 19.6866 10.9401 19.139V11.5593C10.9401 11.0116 11.3841 10.5677 11.9317 10.5677C12.4794 10.5677 12.9233 11.0117 12.9233 11.5593V19.139Z" fill={fill} />
            <path d="M15.5559 1.25576H12.0401V0.950863C12.0401 0.426549 11.6136 0 11.0893 0H6.90929C6.38498 0 5.95843 0.426549 5.95843 0.950863V1.25576H2.44258C1.09631 1.25576 0.000976562 2.35102 0.000976562 3.69733V5.26298C0.000976562 5.54298 0.227957 5.77 0.508075 5.77H17.4906C17.7705 5.77 17.9975 5.54298 17.9975 5.26298V3.69733C17.9975 2.35106 16.9023 1.25576 15.5559 1.25576Z" fill={fill} opacity="0.595377604" />
        </svg>
    );
};

export default SVG;
