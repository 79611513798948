import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conBoxTitle: {
            backgroundColor: '#EEE',
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center'
        },
        boxTitle: {
            background: '#D30F43',
            padding: '5px 2rem'
        },
        boxSubCategory:{
            color: '#444',
            fontSize: '12px',
            padding: '0px 10px'
        }
    }
})