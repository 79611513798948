import { Box, Pagination, PaginationItem, Typography } from "@mui/material"
import Footer from "components/Footer"
import Header from "components/Header"
import SuiBox from "components/SuiBox"
import { useEffect, useState } from "react"
import commonNewsServices from "redux/common/news"
import NewsCategory from "./news-category"
import TopNewsItem from "./top-news-item"
import i18n from 'services/language/i18n'
import { useDispatch } from "react-redux"
import styles from "./styles"
import CBreadCrumbs from "components/CBreadCrumbs"
import commonStyles from 'common/styles'
import CNewsList from "components/CNewsList"
import CSelector from "components/CSeletor"
import { getModeView } from "utils/modeView"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { rewriteUrlPath } from "utils/convertPathUrl"

const News = () => {
    const [data, setData] = useState([])
    const [tmp, setTmp] = useState([])
    const dispatch = useDispatch()
    const [category, setCategory] = useState([])
    const [categorySelected, setCategorySelected] = useState(null)
    const [subCategorySelected, setSubCategorySelected] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [modeView, setModeView] = useState(null)
    const [totalPage, setTotalPage] = useState(1)
    const itemPerPage = 5;
    const lastItem = currentPage * itemPerPage;
    const firstItem = lastItem - itemPerPage;
    const newsList = data.slice(firstItem, lastItem);
    const classes = styles()
    const commonClasses = commonStyles()
    const history = useHistory();
    const [lang, setLang] = useState(i18n.language)

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        setModeView(getModeView())
    }, [])

    useEffect(() => {
        getListCategory()
        getNewsList()
    }, [])
    useEffect(() => {
        setLang(i18n.language)
    }, [i18n.language])

    const onChangeSubCategory = (item) => {
        history.push(`/list-news/${rewriteUrlPath(item?.nameVi)}.html&query=${item?.newsCategoryId}`)
    }

    const getNewsList = async () => {
        let param = {
            page: 1,
            maxItems: 20
        }
        let res = await commonNewsServices.getNewsByFilter(param);
        setTmp(res.data)
        setData(res.data)
        setTotalPage(res?.totalPage)
    }
    const getListCategory = async () => {
        let res = await commonNewsServices.listCategory(dispatch);
        console.log(res)
        setCategory(res)
    }

    const onSelectCategory = (index, parentCategoryId) => {
        let newsList = tmp.filter(item => item.subNewsCategory.newsCategoryId === parentCategoryId)
        setCategorySelected(index)
        setSubCategorySelected(null)
        setData(newsList)
        setTotalPage(Math.ceil(newsList.length / itemPerPage))
    }

    const onSelectSubCategory = (parentIndex, subIndex, subCategoryId) => {
        let newsList = tmp.filter(item => item.subNewsCategory.subNewCategoryId === subCategoryId)
        setCategorySelected(parentIndex)
        setSubCategorySelected(subIndex)
        setData(newsList)
        setTotalPage(Math.ceil(newsList.length / itemPerPage))
    }

    const returnNewsItem = (news, index) => {
        return (
            <Box component={'a'} href={`/news/${rewriteUrlPath(news?.titleEn)}.html&query=${news?.newsId}`} className={classes.conBoxProductItem} key={`news_item_${index}`}>
                <Box component={'div'} sx={{ minWidth: '125px' }}>
                    <Box className={classes.conBoxProductImg}
                        component={'img'}
                        src={news?.preview ? news?.preview : 'http://vietkhanggroup.vn/content/product/product_50_0.jpg'}
                    />
                </Box>
                <Box component={'div'} className={classes.conBoxProductInfo} ml={1}>
                    <Typography className={classes.textProductName}>{lang === 'vi' ? news?.titleVi : news?.titleEn}</Typography>
                </Box>
            </Box>
        )
    }


    return (
        <>
            <Header />
            <Box component={'div'} className={classes.conWrapper} display={modeView?.desktop}>

                <Box component={'div'}>
                    <CBreadCrumbs isNewsPage={true} />
                </Box>
                <Box component={'div'} className={classes.boxSideNavLeft} mt={2} mb={10}>
                    <NewsCategory
                        listCategory={category}
                        categorySelected={categorySelected}
                        subCategorySelected={subCategorySelected}
                        onSelectSubCategory={onSelectSubCategory}
                        onSelectCategory={onSelectCategory}
                    />
                </Box>
                <Box component={'div'} className={classes.conContent}>
                    {data.length > 0 &&
                        <Box item>
                            <TopNewsItem
                                title={i18n.language === 'en' ? data[0].titleEn : data[0].titleVi}
                                image={data[0]?.preview}
                                sx={{ height: '12rem' }}
                                id={data[0].newsId}
                                lastEdit={data[0].lastEdit}
                                views={data[0].views}
                            />
                        </Box>
                    }

                    <SuiBox bgColor="white" mt={2}>
                        {newsList.length > 0 &&
                            category.map((item, index) => {
                                return (
                                    <Box component={'div'} mt={2}>
                                        <CNewsList title='Tin Tức' category={item} />
                                    </Box>
                                )
                            })
                        }
                    </SuiBox>
                </Box>
                <div style={{ clear: 'both' }} />
                <Box mt={2}></Box>
                <Footer />
            </Box>
            <Box display={modeView?.mobile} className={commonClasses.conMobileWrapper}>
                <Box component={'div'}>
                    <CBreadCrumbs isNewsPage={true} />
                </Box>
                <Box component={'div'} sx={{ borderBottom: '1px solid #d3d3d3' }}>
                    {
                        category &&
                        <CSelector
                            listItems={category}
                            placeholder={{
                                vi: 'Danh Mục Con',
                                en: 'Sub Categories'
                            }}
                            fieldNameRenders={
                                {
                                    vi: 'nameVi',
                                    en: 'nameEn'
                                }
                            }
                            onChangeSelector={onChangeSubCategory}
                        />

                    }

                </Box>
                <Box component={'div'}>
                    {
                        data?.map((product, index) => {
                            return returnNewsItem(product, index)
                        })
                    }
                    {totalPage > 0 &&
                        <Box component={'div'} sx={{ width: '100%', background: '#eee', alignContent: 'center' }} mt={2}>
                            <Pagination
                                sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                count={totalPage}
                                page={currentPage}
                                onChange={handleChangePage}
                                shape="rounded"
                                siblingCount={0}
                                renderItem={(item) => <PaginationItem {...item} sx={{ '&.Mui-selected': { color: '#000', background: 'white', border: '#000 1px solid' } }} />}
                            />
                        </Box>
                    }
                </Box>
                <Footer />
            </Box>

        </>
    )
}

export default News