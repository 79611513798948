

import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useTranslation } from "react-i18next"
import ContactDetail from '../ContactDetail';

const ContactInfo = () => {
    const { t } = useTranslation();

    return (
        <SuiBox>
            <SuiBox>
                <SuiTypography variant="h2" sx={{ textAlign: 'center' }}>{t('contact_us')}</SuiTypography>
            </SuiBox>
            <SuiBox mt={1} mb={2}>
                <SuiTypography variant="h3" textAlign="center" sx={{ color: '#058548'}}>{t('headBanner')}</SuiTypography>
                <SuiTypography variant="h2" textAlign="center" sx={{ color: '#058548'}}>{t('companyName')}</SuiTypography>
                <SuiTypography variant="body2">{t('contentBanner')}</SuiTypography>
            </SuiBox>
            <ContactDetail variant="body" />
        </SuiBox>

    )
}

export default ContactInfo