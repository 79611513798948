import { makeStyles } from "@mui/styles"

export default makeStyles(() => {
    return {
        conHeader: {
            background: '#024AA0 !important',
            padding: '3px',
            lineHeight: '28px',
            paddingLeft: '1rem'
        },
        conListItem: {
            // padding: '1rem',
            margin: '1rem',
            display: 'flex',
            alignItems: 'flex-start !important'
        },
        conTextTitle: {
            marginLeft: '1rem',
        },
        textTitle: {
            fontWeight: 'bold',
            padding: '0px 2px',
            color: '#000',
            overflow: 'hidden',

            "&:hover": {
                textDecoration: 'underline !important'
            }
        }
    }
})