import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

import DataTable from "examples/Tables/DataTable";
import AdminLayout from "components/Layout/AdminLayout";
import customerServices from "redux/admin/user";

import { useTranslation } from "react-i18next"
import * as XLSX from 'xlsx';
import moment from "moment";

const UserList = () => {
    const { t } = useTranslation()
    const [data, setData] = useState({
        columns: [
            { Header: "user_name", accessor: "fullName", isSort: true },
            { Header: "email", accessor: "email", isSort: true },
            { Header: "phone", accessor: "phone", isSort: true },
            { Header: "address", accessor: "address", isSort: true },
        ],
        rows: []
    })
    const [dataExport, setDataExport] = useState([])

    useEffect(() => {
        getListUser()
    }, [])

    const getListUser = async () => {
        let res = await customerServices.getList();
        setData({ ...data, rows: res})
        if (res) {
            setDataExport(res)
        }
    }

    const exportFile = () => {
        const fileExtension = ".xlsx";
        const fileName = "Customer-File " + moment().format('L');
        const ws = XLSX.utils.json_to_sheet(dataExport)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws)
        XLSX.writeFile(wb, fileName + fileExtension)
    }

    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Card>
                    <DataTable
                        table={data}
                        entriesPerPage={false}
                        canSearch
                        canExport={{ title: t('export_customer'), action: exportFile }}
                    />
                </Card>
            </SuiBox>
        </AdminLayout>
    );
}

export default UserList;
