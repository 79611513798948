import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './styles'
import i18n from 'services/language/i18n'

const CTitleHeader = (
    {
        title = '',
        subTitles = [],
        category = null
    }
) => {
    const classes = styles()
    const [subCategories, setSubCategories] = useState([])
    const MAX_SUB_HIGHT_LIGHT = 5

    useEffect(() => {
        if (subCategories?.length === 0 && category != null) {
            let subCategories = [...category?.subProductType]
            if (subCategories.length >= MAX_SUB_HIGHT_LIGHT) {
                setSubCategories(subCategories.slice(0, MAX_SUB_HIGHT_LIGHT))
            } else {
                setSubCategories(subCategories)
            }
        }
    }, [category])

    return (

        <Box component={'div'} className={classes.conBoxTitle}>
            <Typography component={'a'} href={`/product.html&search=${category?.productTypeId}&sub=${null}`} variant='custWhite' className={classes.boxTitle}>{title}</Typography>
            {
                subCategories.length > 0 && (
                    <Breadcrumbs separator={<Typography sx={{ color: 'red' }}>|</Typography>}>
                        {
                            subCategories.map((item, index) => {
                                return (
                                    <Link
                                        key={`sub_category_product_${category?.productTypeId}`}
                                        href={`/product.html&search=${category?.productTypeId}&sub=${item.subProductTypeId}`}
                                        className={classes.boxSubCategory}>
                                        {i18n.language === 'vi' ? item.nameVi : item.nameEn}
                                    </Link>
                                )
                            })
                        }
                    </Breadcrumbs>

                )
            }
        </Box>

    )
}

export default CTitleHeader