import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import { COLOR } from 'constant'
import { t } from 'i18next'
import ContactDetail from 'layouts/pages/customer/contact/component/ContactDetail'
import i18n from 'services/language/i18n'
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors'
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { useSoftUIController } from 'context'
import { Box, Grid, Icon, List, ListItem, Typography } from '@mui/material'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import MobileFilter from './components/filter'
import { useDispatch } from "react-redux";
import commonProductServices from "redux/common/product";
import { rewriteUrlPath } from "utils/convertPathUrl";
import styles from './styles'

const useStyles = makeStyles({
    productCategory: {
        "&:hover > .product-sub-category": {
            display: 'block'
        },
    },

});


const ProductCategory = (
    {
        currentCategoryId = null
    }
) => {
    const listCategory = useSelector(state => state.common.product_category)
    const history = useHistory();
    // const classes = useStyles();
    const [showSub, setShowSub] = useState(false)
    const [openMobileFilter, setOpenMobileFilter] = useState(false);
    const [search, setSearch] = useState('');
    const [result, setResult] = useState([])
    const listProduct = useSelector(state => state.common.product_list)
    const dispatch = useDispatch()
    const classes = styles()

    const onClickCategory = (id) => {
        if (window.innerWidth < 1200) {
            setShowSub(!showSub)
        }
        history.push(`/product.html&search=${id}&sub=${null}`)
    }

    const onClickSubCategory = (parentId, subId) => {
        history.push(`/product.html&search=${parentId}&sub=${subId}`)
    }

    useEffect(() => {
        getListProduct()

    }, [])

    useEffect(() => {
        if (!listCategory) {
            getListCategory()
        }
    }, [])

    const getListCategory = async () => {
        await commonProductServices.getProductType(dispatch)

    }

    useEffect(() => {
        if (listProduct && search !== '') {
            let resultEn = listProduct.filter(item => item.nameEn.toLowerCase().includes(search.toLowerCase()))
            let resultVi = listProduct.filter(item => item.nameVi.toLowerCase().includes(search.toLowerCase()))
            if (resultEn && resultEn.length > 0) {
                setResult(resultEn)
            } else if (resultVi && resultVi.length > 0) {
                setResult(resultVi)
            } else {
                setResult([])
            }
        }
    }, [listProduct, search])

    const getListProduct = async () => {
        let res = await commonProductServices.getListProduct(dispatch);
    }
    const onSelectProduct = (item) => {
        history.push(`/product/${rewriteUrlPath(item.nameEn)}.html&query=${item.productId}`)
    }

    const renderCategoryItem = (category) => {
        return (
            <ListItem key={category?.productTypeId} className={classes.listItem} >
                <Box component={'a'} href={`/product.html&search=${category.productTypeId}&sub=${null}`} className={`${classes.conItemCategory} ${category?.productTypeId === currentCategoryId ? classes.activeListItem : ''}`}>
                    <Box className={classes.conIcon} component={'img'} src='http://redstarvietnam.com/media/category/cat_d3306ab36c10519e174cd70561740e69.png' />
                    <Typography className={classes.textItemCategoryName} component={'p'} >
                        {i18n.language === 'vi' ? category?.nameVi : category?.nameEn}
                    </Typography>
                </Box>
            </ListItem>
        )
    }

    return (
        <SuiBox>
            <Box>
                <List>
                    {
                        listCategory?.length > 0 && listCategory.map((item, index) => {
                            return renderCategoryItem(item)
                        })
                    }
                </List>
            </Box>
            {/* <SuiBox display={{ xs: 'block', sm: 'none' }}>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <SuiInput
                            placeholder={t('search')}
                            icon={{ component: "search", direction: "left" }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            fullWidth
                        />
                        {search !== '' &&
                                            <SuiBox position="absolute" bgColor="white" shadow="md" borderRadius="lg" sx={{zIndex: 3}}>
                                                {result.length > 0 && result.slice(0, 4).map(item => (
                                                    <SuiBox
                                                        p={2}
                                                        mb={1}
                                                        display="flex"
                                                        alignItems="center"
                                                        gap={1}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&:hover': { backgroundColor: COLOR.MAIN },
                                                            '&:hover > .product-name': { color: '#FFF !important' }
                                                        }}
                                                        onClick={() => onSelectProduct(item)}
                                                    >
                                                        <SuiBox
                                                            component="img"
                                                            src={item.images && item.images.length > 0 && item.images[0]}
                                                            alt="product-image"
                                                            width={40}
                                                            height={40}
                                                            display="inherit"
                                                            borderRadius="md"
                                                            className="product-image"
                                                        />
                                                        <SuiTypography variant="caption" className="product-name">{i18n.language === "en" ? item.nameEn : item.nameVi}</SuiTypography>
                                                    </SuiBox>
                                                ))}
                                                {result.length === 0 &&
                                                    <SuiBox width={300} p={2}>
                                                        <SuiTypography variant="button">{t('no_result_found')}</SuiTypography>
                                                    </SuiBox>

                                                }
                                            </SuiBox>
                                        }
                    </Grid>
                    <Grid item>
                        <SuiButton variant="gradient" color="success" iconOnly onClick={() => setOpenMobileFilter(true)}>
                            <Icon sx={{ fontWeight: "bold" }}>filter_list</Icon>
                        </SuiButton>
                    </Grid>
                </Grid>
            </SuiBox>

            <SuiBox display={{ xs: 'none', sm: 'block' }} bgColor="white" sx={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                <SuiBox>
                    <SuiBox sx={{ borderTopLeftRadius: 20, borderTopRightRadius: 20, backgroundColor: `${COLOR.TEXT}` }} variant="gradient" px={2} py={1} display="flex" alignItems="center">
                        <SuiTypography variant="h6" color="white" m={0}>{t('category')}</SuiTypography>
                    </SuiBox>
                    <SuiBox>
                        {listCategory && listCategory.length > 0 && listCategory.map((category, index) => (
                            <SuiBox
                                sx={{ cursor: "pointer", zIndex: 3, borderBottom: index === listCategory.length - 1 ? 'none' : `1px solid ${COLOR.TEXT}` }}
                                p={2}
                                position="relative"
                                className={classes.productCategory}
                            >
                                <SuiBox onClick={() => onClickCategory(category.productTypeId)}>
                                    <SuiTypography
                                        variant="button"
                                        fontWeight="regular"
                                    >
                                        {i18n.language === 'en' ? category.nameEn : category.nameVi}
                                    </SuiTypography>
                                </SuiBox>
                                <SuiBox className="product-sub-category" display="none" top={0} sx={{ position: { xs: 'relative', md: 'absolute' }, left: { xs: 0, md: '100%' }, width: { md: 300, xs: '100%' }, backgroundColor: `${colors['light'].main}`, zIndex: 1 }}>
                                    {(window.innerWidth > 1200 || showSub) && category.subProductType.length > 0 && category.subProductType.map((sub, subIndex) => (
                                        <SuiBox p={2} sx={{ cursor: "pointer", borderBottom: subIndex === category.subProductType.length ? 'none' : `1px solid ${COLOR.MAIN}`, '&:hover': { backgroundColor: COLOR.MAIN, '& > span': { color: '#FFF' } } }}
                                            onClick={() => onClickSubCategory(category.productTypeId, sub.subProductTypeId)}
                                        >
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="regular"
                                            >
                                                {i18n.language === 'en' ? sub.nameEn : sub.nameVi}
                                            </SuiTypography>
                                        </SuiBox>
                                    ))}
                                </SuiBox>
                            </SuiBox>

                        ))}
                    </SuiBox>
                </SuiBox>
                <SuiBox>
                    <SuiBox sx={{ borderTopLeftRadius: 20, borderTopRightRadius: 20, backgroundColor: `${COLOR.TEXT}` }} variant="gradient" px={2} py={1} mb={2} display="flex" alignItems="center">
                        <SuiTypography variant="h6" mb={2} color="white" m={0}>{t('contact')}</SuiTypography>
                    </SuiBox>
                    <SuiBox px={2}>
                        <ContactDetail variant="caption" />
                    </SuiBox>
                </SuiBox>
            </SuiBox>

            <MobileFilter open={openMobileFilter} onClose={() => setOpenMobileFilter(false)} /> */}
        </SuiBox>
    )
}

export default ProductCategory