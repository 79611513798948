export const PUBLIC_API = {
    OAUTH: "/oauth/token ",
    PRODUCT: {
        GET_LIST_PRODUCT: '/web/api/v1/product/findAll',
        GET_LIST_SUB_PRODUCT_TYPE: '/web/api/v1/subProductType/findAll',
        GET_PRODUCT: '/web/api/v1/product/findById/',
        GET_LIST_CATEGORY: '/web/api/v1/productType/findAll',
        GET_CATEGORY_DETAIL: '/web/api/v1/productType/findById?productTypeId='
    },
    NEWS: {
        GET_LIST_CATEGORY: '/web/api/v1/newsCategory/findAll',
        GET_LIST_NEWS: '/web/api/v1/news/findAll',
        GET_NEWS_DETAIL: '/web/api/v1/news/findById/',
        GET_NEWS_BY_FILTER: '/web/api/v1/news/findByFilter',
        GET_CATEGORY_BY_ID: '/web/api/v1/newsCategory/findById'
    },
    COMPANY: '/web/api/v1/company/info',
    REGISTER: '/web/api/v1/customer/register/information',
    FORM: '/web/api/v1/customer/send/form'
}
export const ADMIN_API = {
    COMPANY: '/web/api/v1/company/info',
    UPDATE_COMPANY: '/admin/api/v1/company/update',
    PRODUCT: {
        // CATEGORY
        GET_LIST_CATEGORY: '/web/api/v1/productType/findAll',
        CREATE_CATEGORY: '/admin/api/v1/productType/create',
        EDIT_CATEGORY: '/admin/api/v1/productType/edit',
        GET_SUB_CATEGORY: '/web/api/v1/subProductType/findByProductTypeId?productTypeId=',
        DELETE_SUB_CATEGORY: '/admin/api/v1/subProductType/delete?subProductTypeId=',
        DELETE_TYPE: '/admin/api/v1/productType/delete?productTypeId=',
        // PRODUCT
        GET_LIST_SUB_PRODUCT: '/admin/api/v1/product/findBySubProductType?subProductTypeId=',
        CREATE_PRODUCT: '/admin/api/v1/product/create',
        EDIT_PRODUCT: '/admin/api/v1/product/update',
        DELETE_PRODUCT: '/admin/api/v1/product/delete/',
    },
    MANUFACTURE: {
        LIST: '/web/api/v1/manufacturer/findAll',
        CREATE: '/admin/api/v1/manufacture/create',
        EDIT: '/admin/api/v1/manufacture/update/',
        DELETE: '/admin/api/v1/manufacture/delete/',
    },
    NEWS: {
        //CATEGORY
        CREATE_CATEGORY: '/admin/api/v1/newsCategory/create',
        EDIT_CATEGORY: '/admin/api/v1/newsCategory/update',
        DELETE_CATEGORY: '/admin/api/v1/newsCategory/delete/',
        DELETE_SUB_CATEGORY: '/admin/api/v1/subNewsCategory/delete/',

        CREATE: '/admin/api/v1/news/create',
        EDIT: '/admin/api/v1/news/update',
        DELETE: '/admin/api/v1/news/delete/',
    },
    CUSTOMER: {
        GET_LIST: '/admin/api/v1/customer/findAll',
        USER_INFO: '/admin/api/v1/customer/findById/'
    },
    ORDER: {
        GET_LIST: '/admin/api/v1/productOrder/findAll',
        DETAILS: '/admin/api/v1/productOrder/findById/'
    },
}