import { Box, Grid, Popover, Typography } from '@mui/material'
import SuiBox from 'components/SuiBox'
import React from 'react'
import { useState } from 'react'
import mailAlert from 'assets/images/icons/locationnews.png'
import styles from './styles'
import CPopover from 'components/CPopover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import replyIcon from 'assets/images/icons/emailReplyIcon.png'
import userIcon from 'assets/images/icons/user-icon.png'
import { Assets } from "constant";
import i18n from 'services/language/i18n'
import pxToRem from 'assets/theme/functions/pxToRem'
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import commonProductServices from 'redux/common/product'
import { useEffect } from 'react'


const useStyles = makeStyles((theme) => ({
    customPopoverPaper: {
        padding: "0 !important",
        color: "white",
        marginTop: '1rem !important',
        // border: 'red 1px solid ',
        margin: 0,
        borderRadius: '0px !important'
    }

}));
const CNavTop = (
    {
        companyDetail = null,
        modeView = {}
    }
) => {

    const [anchorMenuMobile, setAnchorMenuMobile] = useState(null)
    const { t } = useTranslation()
    const classes = styles()
    const [lang, setLang] = useState(i18n.language);
    const popOverClass = useStyles()
    const [newAlert, setUserAlert] = useState(5);
    const dispatch = useDispatch()
    const listCategories = useSelector(state => state.common.product_category);
    const openMenuMobile = Boolean(anchorMenuMobile)
    const idMoblieMenu = openMenuMobile ? 'menu-mobile' : undefined


    useEffect(() => {

        getProductCategories()

    }, [])

    const onChangeLanguage = (value) => {
        setLang(value)
        i18n.changeLanguage(value);
        localStorage.setItem('lang', value)
    }


    const handleClickMobileMenu = (event) => {
        setAnchorMenuMobile(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorMenuMobile(null)
    }

    const getProductCategories = async () => {

        let res = await commonProductServices.getProductType(dispatch)
    }

    const popOverContentMail = () => {
        return (
            <Box sx={{ background: 'white' }}>
                <Typography sx={{ p: 1, fontWeight: 'bold' }}>
                    <Typography sx={{ fontWeight: '700 !important', textTransform: 'uppercase' }} component={'span'} color={'gray'}>{t('saleOnline')}</Typography>
                    <Typography sx={{ fontWeight: '700 !important' }} component={'span'} color={'red'}>&nbsp; -{t('email_sales_contact')} : {companyDetail?.email}</Typography>
                </Typography>
            </Box>
        )
    }
    const titleEmail = () => {
        return (
            <Typography variant='custWhite' sx={{ fontWeight: 'bold' }} component={'a'} href={`mailto:${companyDetail?.email}`}>
                Email:
                <Typography sx={{ fontWeight: 'bold' }} component={'span'} color={'#1CF070'}> {companyDetail?.email}</Typography>
            </Typography>
        )
    }

    const titleSupportTech = () => {
        return (
            <Typography variant='custWhite' sx={{ fontWeight: 'bold' }} component={'a'} href={`mailto:${companyDetail?.email}`}>
                {t('tech_support')}
                <ArrowDropDownIcon />
            </Typography>
        )
    }
    const popOverSupportTech = () => {
        return (
            <Box sx={{ background: '#ffe', width: '410px' }}>
                <Typography sx={{ p: 1, fontWeight: 'bold !important' }}>
                    <Typography sx={{ fontWeight: 'bold !important' }} component={'span'} color={'#0086f1'}>&nbsp;Email : {companyDetail?.email}</Typography>
                </Typography>
            </Box>
        )
    }

    const renderMenuItemMobile = (menu) => {
        return (
            <Box component={'a'} className={classes.boxItemMenu} href={`/product.html&search=${menu?.productTypeId}&sub=${null}`}>
                <Box className={classes.imgMenuItem} component={'img'} src='https://redstarvietnam.com//media/category/cat_d3306ab36c10519e174cd70561740e69.png' />
                <Typography className={classes.textMenuItem}>{i18n.language === 'vi' ? menu.nameVi : menu.nameEn}</Typography>
            </Box>
        )
    }
    const renderMenuMobile = () => {
        return (
            <Popover
                id={idMoblieMenu}
                sx={{
                    pointerEvents: 'auto',
                    zIndex: 10000
                }}
                open={openMenuMobile}
                anchorEl={anchorMenuMobile}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: popOverClass.customPopoverPaper,
                    root: popOverClass.customPopoverPaper
                }}
            >
                <Box component={'div'} className={classes.conMenu}>
                    <Box component={'div'} className={classes.mLayout} >
                        <Grid container>
                            {listCategories && listCategories.map((category, index) => {
                                return (
                                    <Grid item xs={4} md={4} key={'category_' + index}>
                                        {renderMenuItemMobile(category)}
                                    </Grid>
                                )
                            })}


                        </Grid>
                    </Box>
                </Box>
            </Popover>
        )
    }
    return (
        <>
            <Box className={classes.conNav} display={modeView?.desktop}>
                <Box className={classes.conLayout}>
                    <Grid container display={'flex'} alignItems={'center'}>
                        <Grid item xs={3}>
                            <Box component={'div'} className={classes.conMailAlert}>
                                <img src={mailAlert} className={classes.mailAlertIcon} />
                                <Typography
                                    href='/cc' component={'a'} sx={{ fontWeight: 'bold', color: '#FDD501' }} >
                                    New <Typography component={'span'} color={'#1CF070'}>(5)</Typography>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={9}>
                            <Box component={'div'} display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
                                <CPopover
                                    titleCell={titleEmail}
                                    contentCell={popOverContentMail}
                                    transformOriginProp={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    anchorOriginProp={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                />
                                {/* <Typography variant='custWhite'>Tu Van Ky Thuat</Typography> */}
                                <CPopover
                                    titleCell={titleSupportTech}
                                    contentCell={popOverSupportTech}
                                    transformOriginProp={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    anchorOriginProp={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                />
                                <Box display={'flex'} alignItems={'center'}>
                                    <img src={replyIcon} className={classes.replayIcon} />
                                    <Typography variant='custWhite' href='/cc' component={'a'} ml={1}> {t('feedback_label')}</Typography>
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <img src={userIcon} className={classes.replayIcon} />
                                    <Typography variant='custWhite'>{t('register')} | {t('login')}</Typography>
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Typography component={'a'} variant='custWhite' href='/' fontWeight={500}>Sitemap</Typography>
                                </Box>
                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                    {
                                        lang === 'en' ?
                                            <SuiBox component="img" src={Assets.FLAG_VN} onClick={() => onChangeLanguage('vi')} sx={{ width: pxToRem(30), cursor: 'pointer' }} />
                                            :
                                            <SuiBox component="img" src={Assets.FLAG_US} onClick={() => onChangeLanguage('en')} sx={{ width: pxToRem(30), cursor: 'pointer' }} />
                                    }
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>
                </Box>
            </Box >
            <Box className={classes.mConNav} display={modeView?.mobile}>
                <Box component={'div'} className={classes.mLayout}>
                    <Box component={'a'} href='/' className={classes.mHomeButton} >
                        <HomeIcon sx={{ fontSize: '36px !important' }} />
                        Home
                    </Box>
                    <Box
                        customclass="cursor-pointer"
                        mx="auto"
                        mt={0.5}
                        component="img"
                        src={companyDetail?.logoUrl}
                        alt="Logo"
                        sx={{ maxWidth: '100px', flexGrow: 1, display: 'flex' }}
                    />
                    <Box component={'a'} className={classes.mHomeButton} onClick={handleClickMobileMenu}>
                        <MenuIcon sx={{ fontSize: '36px !important' }} />
                        Menu
                    </Box>
                </Box>
            </Box>
            {renderMenuMobile()}
        </>
    )
}

export default CNavTop