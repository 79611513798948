import instance from "services/axios"
import { ADMIN_API } from 'redux/api'

const customerServices = {
    getList: async () => {
        try {
            let res = await instance.get(ADMIN_API.CUSTOMER.GET_LIST);
            return res.status === 200 && res.data ? res.data.data : []
        } catch (error) {
            return []
        }
    },

    getUserDetails: async (id) => {
        try {
            let res = await instance.get(ADMIN_API.CUSTOMER.USER_INFO + id);
            return res.status === 200 && res.data ? res.data.data : null
        } catch (error) {
            return null
        }
    }
}

export default customerServices