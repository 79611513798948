import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Libraries
import { Box, Button, Fab, InputBase, List, ListItem, Typography } from "@mui/material";
// CONSTANTS
import { Assets } from "constant";
// THEMES
import pxToRem from "assets/theme/functions/pxToRem";
// Components
import SuiBox from "components/SuiBox";
// @mui icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import userServices from "redux/user";
import { COLOR } from 'constant'
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getModeView } from "utils/modeView";
import LaptopIcon from '@mui/icons-material/Laptop';
import { setItemWithExpiry } from "utils/localStorage"
import i18n from "services/language/i18n";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import commonCompanyServices from "redux/common/company";
import MailIcon from '@mui/icons-material/Mail';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import commonNewsServices from "redux/common/news";
import { getItemWithExpiry } from "utils/localStorage";
import { rewriteUrlPath } from "utils/convertPathUrl";



const Footer = () => {
    const { t } = useTranslation()
    const theme = useTheme();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [actionStatus, setActionStatus] = useState({ status: '' })
    const companyDetail = useSelector(state => state.common.company_details)
    const classes = styles()
    const dispatch = useDispatch()

    const [modeView, setModeView] = useState(null)
    const [lang, setLang] = useState(i18n.language);
    const company = useSelector(state => state.common.company_details)

    const [companyRefNews, setCompanyRefNews] = useState(null)

    useEffect(() => {
        setModeView(getModeView())
        if (!company) {
            getCompanyInfo()

        }

    }, [])

    // useEffect(() => {
    //     if (!companyRefNews) {
    //         getCompanyInfoRef()
    //     }
    // }, [companyRefNews])

    const getCompanyInfo = async () => {
        let companyInfo = await commonCompanyServices.getCompanyInfoWithCache(dispatch);
        if (companyInfo && companyInfo.footerNewsInfo) {
            setCompanyRefNews(companyInfo.footerNewsInfo)
        }
    }

    // const getCompanyInfoRef = async () => {

    //     //get in cache
    //     let companyRefNewsData = getItemWithExpiry('companyNewsFooter')
    //     if (companyRefNewsData) {
    //         setCompanyRefNews(companyRefNewsData)
    //     } else {
    //         let param = {
    //             page: 1,
    //             maxItems: 1000,
    //             categoryId: 8
    //         }
    //         let res = await commonNewsServices.getNewsByFilter(param)
    //         if (res && res.data) {
    //             let listTitles = await commonNewsServices.getNewsCategoryById(8)
    //             if (listTitles.subNewCategory) {
    //                 console.log(res.data, 'c', listTitles.subNewCategory)
    //                 let companyInfoRef = listTitles.subNewCategory.map((category, index) => {
    //                     let refItem = {
    //                         ...category,
    //                         listInfo: []
    //                     }
    //                     refItem.listInfo = res.data.filter((item) => item.subNewsCategory.subNewCategoryId === category.subNewCategoryId)

    //                     return refItem

    //                 })

    //                 setCompanyRefNews(companyInfoRef)
    //                 setItemWithExpiry('companyNewsFooter', companyInfoRef, 86400)
    //             }
    //         }

    //     }

    // }

    const changeModeView = (mode) => {
        setItemWithExpiry('modeView', mode, 600)
        window.location.reload();

    }

    const onChangeLanguage = () => {
        let value = 'vi'
        if (lang === 'vi') {
            value = 'en'
        }
        setLang(value)
        i18n.changeLanguage(value);
        localStorage.setItem('lang', value)
    }




    const companyInfo = [

        {
            nameVi: 'Tin Tức',
            nameEn: 'News',
            news: [
                {
                    titleVi: 'Tin tức',
                    titleEn: 'News',
                    link: '/news.html'
                },
                {
                    titleVi: 'Tuyển dụng',
                    titleEn: 'Tuyển dụng',
                    link: '#'
                },
                {

                    titleVi: 'Phiên bản mobile',
                    titleEn: 'Mobile version',
                    link: '#',
                    isChangeVersion: true
                }
            ]
        },
    ]


    const social = [
        {
            link: "https://www.facebook.com/",
            icon: <FacebookIcon />,
            color: "facebook",
        },
        {
            link: "https://twitter.com/",
            icon: <TwitterIcon />,
            color: "twitter",
        },
        {
            link: "https://www.instagram.com/",
            icon: <InstagramIcon />,
            color: "instagram",
        },
    ]
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const clearForm = () => {
        setName('');
        setEmail('')
    }



    const onSubmit = async () => {
        const params = {
            fullName: name,
            email: email
        }
        let res = await userServices.registerInformation(params);
        setActionStatus({ status: res ? 'success' : 'error' })
        clearForm()
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderBoxListInfo = (col) => {
        return (
            <Box component={'div'} key={`company_info_box_${col?.title}`}>
                <Typography mt={1} component={'h3'} className={classes.textTitleInfoHeader}>{i18n.language === 'vi' ? col?.nameVi : col?.nameEn}</Typography>
                <Box mt={1.5}>
                    <List>
                        {
                            col?.news?.length > 0 && col?.news.map((item, index) => {
                                return (
                                    <ListItem sx={{ mb: 1 }} key={`company_info_${index}`}>
                                        {
                                            item.isChangeVersion ?
                                                <Box
                                                    component={'a'}
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => changeModeView('mobile')}
                                                    className={classes.boxTextInfoItem} >
                                                    <ArrowRightIcon fontSize='15px' sx={{ color: '#7F8285', fontSize: '13px !important', mr: 1 }} />
                                                    <Typography variant="custWhite" className={classes.textLink}>{i18n.language === 'vi' ? item?.titleVi : item?.titleEn}</Typography>
                                                </Box>
                                                :
                                                <Box
                                                    component={'a'}
                                                    href={item?.link ? item?.link : `/news/${rewriteUrlPath(item.titleVi)}.html&query=${item.newsId}?companyRef=true`}
                                                    className={classes.boxTextInfoItem} >
                                                    <ArrowRightIcon fontSize='15px' sx={{ color: '#7F8285', fontSize: '13px !important', mr: 1 }} />
                                                    <Typography variant="custWhite" className={classes.textLink}>{i18n.language === 'vi' ? item?.titleVi : item?.titleEn}</Typography>
                                                </Box>

                                        }

                                    </ListItem>
                                )
                            })
                        }

                        {/* <ListItem>
                            <Box component={'a'} href="#" className={classes.boxTextInfoItem}>
                                <ArrowRightIcon fontSize='15px' sx={{ color: '#7F8285', fontSize: '13px !important', mr: 1 }} />
                                <Typography variant="custWhite" className={classes.textLink}>Giới thiệu công ty</Typography>
                            </Box>
                        </ListItem> */}

                    </List>
                </Box>
            </Box>
        )
    }
    return (
        // <>
        //     <SuiBox bgColor="grey-200" mt={3}>
        //         <Container p={8}>
        //             <Grid container spacing={3}>
        //                 <Grid item xs={12} md={5}>
        //                     <Grid container spacing={1}>
        //                         <Grid item xs={12}>
        //                             <SuiBox display="flex" sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }}>
        //                                 <SuiBox
        //                                     customClass="cursor-pointer"
        //                                     component="img"
        //                                     src={companyDetail?.logoUrl}
        //                                     alt="Logo"
        //                                     sx={{ width: pxToRem(100) }}

        //                                 />
        //                             </SuiBox>
        //                         </Grid>
        //                         <Grid item xs={12}>
        //                             <SuiTypography variant="h6" fontWeight="bold" sx={{ color: COLOR.TEXT }}>{companyDetail?.name}</SuiTypography>
        //                             <Divider />
        //                         </Grid>
        //                         <Grid item xs={12}>
        //                             <SuiBox display="flex" alignItems="flex-start">
        //                                 <LocationOnIcon />
        //                                 <SuiTypography variant="caption" fontWeight="regular" ml={1}>{companyDetail?.address}</SuiTypography>
        //                             </SuiBox>
        //                         </Grid>
        //                         <Grid item xs={12}>
        //                             <SuiBox display="flex" alignItems="flex-end">
        //                                 <LocalPhoneIcon />
        //                                 <SuiTypography variant="caption" fontWeight="regular" ml={1}>{companyDetail?.hotline}</SuiTypography>
        //                             </SuiBox>
        //                         </Grid>
        //                         <Grid item xs={12}>
        //                             <SuiBox display="flex" alignItems="flex-end">
        //                                 <EmailIcon />
        //                                 <SuiTypography variant="caption" fontWeight="regular" ml={1}>{companyDetail?.email}</SuiTypography>
        //                             </SuiBox>
        //                         </Grid>
        //                         <Grid item xs={12}>
        //                             {social.map(({ link, icon, color }) => (
        //                                 <SuiBox
        //                                     key={color}
        //                                     component="a"
        //                                     href={link}
        //                                     target="_blank"
        //                                     rel="noreferrer"
        //                                     fontSize={size.lg}
        //                                     color={socialMediaColors[color].main}
        //                                     mr={2}
        //                                 >
        //                                     {icon}
        //                                 </SuiBox>
        //                             ))}
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //                 <Grid item xs={12} md={2}>

        //                 </Grid>
        //                 <Grid item xs={12} md={5}>
        //                     <SuiBox display="flex" alignItems="center" sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }} mb={1}>
        //                         <SuiTypography variant="h3">{t('register_information')}</SuiTypography>
        //                     </SuiBox>
        //                     <Grid container spacing={1} sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }}>
        //                         <Grid item xs={9}> <SuiInput type="text" placeholder={t('enter_name')} value={name} onChange={(e) => setName(e.target.value)} /></Grid>
        //                         <Grid item xs={9}> <SuiInput type="text" placeholder={t('enter_email')} value={email} onChange={(e) => setEmail(e.target.value)} /></Grid>
        //                         <Grid item xs={9}>
        //                             <SuiBox display="flex" sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }} mt={1}>
        //                                 <SuiButton sx={{ backgroundColor: COLOR.MAIN, color: '#FFF' }} fullWidth onClick={onSubmit}>{t('register_now')}</SuiButton>
        //                             </SuiBox>
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //         </Container>
        //         <ModalSuccess
        //             open={actionStatus.status === 'success'}
        //             onClose={() => setActionStatus({ status: "" })}
        //         />
        //         <ModalError
        //             title={t('an_error_occured')}
        //             open={actionStatus.status === 'error'}
        //             onClose={() => setActionStatus({ status: "" })}
        //         />
        //         <SuiBox height={50} bgColor="success" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        //             <SuiTypography variant="caption" color="white">Copyright © 2021. All rights reserved.</SuiTypography>
        //         </SuiBox>

        //         <SuiBox
        //             display="flex"
        //             justifyContent="center"
        //             alignItems="center"
        //             width="3.5rem"
        //             height="3.5rem"
        //             bgColor="info"
        //             shadow="sm"
        //             borderRadius="50%"
        //             position="fixed"
        //             right="2rem"
        //             bottom="6rem"
        //             zIndex={99}
        //             sx={{
        //                 animation: 'shake 2.5s infinite',
        //                 '@keyframes shake': {
        //                     '0%': { transform: 'rotate(-15deg)' },
        //                     '2%': { transform: 'rotate(15deg)' },
        //                     '4%': { transform: 'rotate(-18deg)' },
        //                     '6%': { transform: 'rotate(18deg)' },
        //                     '8%': { transform: 'rotate(-22deg)' },
        //                     '10%': { transform: 'rotate(22deg)' },
        //                     '12%': { transform: 'rotate(-18deg)' },
        //                     '14%': { transform: 'rotate(18deg)' },
        //                     '16%': { transform: 'rotate(-12deg)' },
        //                     '18%': { transform: 'rotate(12deg)' },
        //                     '20%': { transform: 'rotate(0deg)' }
        //                 }
        //             }}
        //         >
        //             <Fab href="http://zalo.me/0938583279" aria-label={'phone'} sx={{ backgroundColor: '#337ab7', "&:hover": { backgroundColor: COLOR.MAIN } }}>
        //                 <img width='100%' src={Assets.ZALO} />
        //             </Fab>

        //         </SuiBox>
        //     </SuiBox>
        // </>

        <>
            <Box sx={{ width: '100%' }} display={modeView?.desktop}>
                <Box className={classes.conFooter} p={3}>
                    <Box component={'div'} width={'70%'}>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                            {
                                companyRefNews?.map((col, index) => {
                                    return renderBoxListInfo(col)
                                })
                            }
                            {
                                companyInfo.map((col, index) => {
                                    return renderBoxListInfo(col)
                                })
                            }
                        </Box>
                        <hr />
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} my={2}>
                            <Box>
                                <Typography component={'h3'} variant="custWhite">{companyDetail?.name}</Typography>
                                <Typography variant="custWhite"> Địa chỉ: {companyDetail?.address}</Typography>
                            </Box>
                        </Box>

                    </Box>
                    <Box>
                        <Box component={'span'} height={'70px'} width={'300px'}>
                            <Box component={'iframe'}
                                width={'300px'}
                                height={'70px'}
                                // width={'100%'}
                                src="https://www.facebook.com/plugins/page.php?adapt_container_width=false&app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df8e95edc595c6c%26domain%3Dredstarvietnam.com%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Fredstarvietnam.com%252Ff2e926996ed977%26relation%3Dparent.parent&container_width=174&height=200&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2Fsaodovietnam&locale=en_US&sdk=joey&show_facepile=true&small_header=true&width=300">

                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ padding: '1rem 7rem !important' }} >
                    <Box className={classes.conPaymentFooter}>
                        <Box component={'div'} display={'flex'} width={'50%'}>
                            <Typography>Thanh toán: </Typography>

                            <Box component={'img'} src="http://redstarvietnam.com/template/default/images/bg_credit.png" />

                        </Box>
                        <Box ml={10} width={'50%'} >
                            <Typography component={'span'}>
                                <Typography component={'strong'} className={classes.registerText}>Đăng Ký </Typography>
                                Nhận bảng tin
                                <Typography component={'b'} className={classes.salesText}> KHUYẾN MÃI</Typography>
                            </Typography>
                            <Box display={'flex'} mt={1}>
                                <InputBase className={classes.textFieldRegisterWrapper}
                                    inputProps={{
                                        className: classes.textFieldRegister
                                    }}
                                    placeholder="Vui lòng nhập địa chỉ email của bạn"
                                    fullWidth
                                    sx={{ width: '60% !important' }}
                                />
                                <Button className={classes.registerButton}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Đăng Ký Ngay</Typography>
                                </Button>

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center' }} mt={3}>
                        <Typography>© Bản quyền 2004 - 2016 thuộc về
                            <Typography component={'span'} sx={{ fontWeight: 'bold' }}> {companyDetail?.name}</Typography>
                        </Typography>
                    </Box>
                    <SuiBox
                        onClick={() => scrollToTop()}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="3.5rem"
                        height="3.5rem"
                        bgColor="success"
                        shadow="sm"
                        borderRadius="50%"
                        position="fixed"
                        right="2rem"
                        bottom="2rem"
                        zIndex={99}
                        sx={{
                            animation: 'shake 2.5s infinite',
                            '@keyframes shake': {
                                '0%': { transform: 'rotate(-15deg)' },
                                '2%': { transform: 'rotate(15deg)' },
                                '4%': { transform: 'rotate(-18deg)' },
                                '6%': { transform: 'rotate(18deg)' },
                                '8%': { transform: 'rotate(-22deg)' },
                                '10%': { transform: 'rotate(22deg)' },
                                '12%': { transform: 'rotate(-18deg)' },
                                '14%': { transform: 'rotate(18deg)' },
                                '16%': { transform: 'rotate(-12deg)' },
                                '18%': { transform: 'rotate(12deg)' },
                                '20%': { transform: 'rotate(0deg)' }
                            }
                        }}
                    >
                        <Fab sx={{ backgroundColor: COLOR.TEXT, "&:hover": { backgroundColor: COLOR.MAIN } }}>
                            <ArrowUpwardIcon fontSize="medium" color="white" />
                        </Fab>

                    </SuiBox>
                </Box>

            </Box>
            <Box display={modeView?.mobile}>
                <Box component={'div'} className={classes.conMobileInfo}>
                    <LocationOnIcon sx={{ fontSize: '20px !important', marginRight: 1 }} />
                    Địa điểm {company?.name}
                </Box>
                <Box component={'div'} className={classes.conMobileInfo}>
                    <ThumbUpIcon sx={{ fontSize: '20px !important', marginRight: 1 }} />
                    {t('product_contact')}:&nbsp; <Typography component={'span'} sx={{ fontWeight: 'bold !important', fontSize: '16px !important' }}>{company?.email}</Typography>
                </Box>
                <Box component={'div'} className={classes.conMobileInfo}>
                    <MailIcon sx={{ fontSize: '20px !important', marginRight: 1 }} />
                    {t('support_customer_label')}
                </Box>
                <Box component={'div'} className={classes.conMobileInfo}>
                    <ShoppingCartIcon sx={{ fontSize: '20px !important', marginRight: 1 }} />
                    {t('In_cart')}
                </Box>
                <Box component={'div'} className={classes.boxMode} onClick={() => changeModeView('desktop')}>
                    <LaptopIcon sx={{ fontSize: '20px !important', marginRight: 1, color: 'white !important' }} />
                    {t('desktop_mode')}
                </Box>

                <Box component={'div'} className={classes.boxMode} onClick={() => onChangeLanguage()}>
                    {
                        lang === 'en' ?
                            <Box component="img" src={Assets.FLAG_VN} sx={{ width: pxToRem(30), cursor: 'pointer' }} mr={1} />
                            :
                            <Box component="img" src={Assets.FLAG_US} sx={{ width: pxToRem(30), cursor: 'pointer' }} mr={1} />
                    }
                    {t('language_version')}
                </Box>
            </Box>
        </>
    )

}

export default Footer;