import { Box, List, ListItem, Popper, Typography } from '@mui/material'
import React, { useState } from 'react'
import styles from './styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import i18n from 'services/language/i18n';

const useStyles = makeStyles((theme) => ({
    customPopoverPaper: {
        padding: "0 !important",
        color: "white",
        marginTop: '1rem !important',
        // border: 'red 1px solid ',
        margin: 0,
        borderRadius: '0px !important'
    },
}));

const CNavProductHeader = (
    {
        category = null
    }
) => {

    const { t } = useTranslation()
    const classes = styles()
    const popoverClasses = useStyles()
    const MAX_ITEM_LIST_SUB_CATEGORIES = 5

    // === LOGIC HANDLE CATEGORY ===
    const [anchorElSubCategory, setAnchorElSubcategory] = useState(null)

    const hanldeOpenSubCategory = (event) => {
        setAnchorElSubcategory(event.currentTarget)
    }

    const handleCloseSubCategory = () => {
        setAnchorElSubcategory(null)
    }

    const openSubCategory = Boolean(anchorElSubCategory)
    //=== END ===

    const renderSubCategory = (subCategory) => {
        return (
            <ListItem className={classes.conItemSubCategory}>
                <Box component={'a'} href={`/product.html&search=${category?.productTypeId}&sub=${subCategory?.subProductTypeId}`} className={classes.itemSubCategory}>
                    <Typography>{i18n.language === 'vi' ? subCategory.nameVi : subCategory.nameEn}</Typography>
                </Box>
            </ListItem>
        )
    }

    return (
        <Box component={'div'} className={classes.conBox}>
            <Box className={classes.conList}>
                <Box component={'div'} className={classes.boxTitle}>
                    <Typography>{t('search_by_label')}</Typography>
                </Box>
                <Box
                    component={'div'}
                    ml={1}
                    className={classes.boxItem}
                    aria-owns={openSubCategory ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    display={'flex'}
                    alignItems={'center'}
                    onMouseEnter={hanldeOpenSubCategory}
                    onMouseLeave={handleCloseSubCategory}
                >
                    <Typography> {i18n.language === 'vi' ? category?.nameVi : category?.nameEn}  <KeyboardArrowDownIcon /></Typography>

                    <Popper
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'auto',
                            zIndex: 10000
                        }}
                        open={openSubCategory}
                        anchorEl={anchorElSubCategory}
                        classes={{
                            paper: popoverClasses.customPopoverPaper,
                            root: popoverClasses.customPopoverPaper
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleCloseSubCategory}
                        // PaperProps={{onMouseEnter: ()=>{console.log('cc')}}}
                        // PaperProps={{onMouseEnter: hanldeOpenSubCategory, onMouseLeave: handleCloseSubCategory}}
                        disableRestoreFocus>
                        {
                            category?.subProductType && category.subProductType.length > 0 && (
                                <Box component={'div'}
                                    className={classes.conPopover}>
                                    <List>
                                        {
                                            category.subProductType.map((subCategory, index) => {
                                                return renderSubCategory(subCategory)
                                            })
                                        }
                                    </List>
                                </Box>
                            )
                        }
                    </Popper>
                </Box>
            </Box>

            {/* <List className={classes.conList}>
                <ListItem className={classes.boxTitle}>
                    <Box>
                        <Typography>Tìm Theo</Typography>
                    </Box>
                </ListItem>
                <ListItem>
                    <Typography>Thiet bi</Typography>
                </ListItem>
            </List> */}
        </Box>
    )
}

export default CNavProductHeader