import { Modal, Grid } from '@mui/material'
import modal from 'assets/theme/base/modal'
import SuiBox from 'components/SuiBox'
import FormField from 'components/FormField'
import { useEffect, useState } from 'react'
import SuiButton from 'components/SuiButton'
import { useTranslation } from 'react-i18next'
import manufactuterServices from 'redux/admin/manufacturer'
import ModalSuccess from 'components/Modal/Success'
import ModalError from 'components/Modal/Error'

const ModalEditManufacture = ({ open = false, onClose = () => { }, data = null }) => {
    const { t } = useTranslation()
    const [nameEn, setNameEn] = useState('');
    const [nameVi, setNameVi] = useState('');
    const [actionStatus, setActionStatus] = useState({ status: '' })

    useEffect(() => {
        if (!open) {
            clearModal()
        }
        if (open && data) {
            setNameEn(data.nameEn)
            setNameVi(data.nameVi)
        }
    }, [open, data])


    const onSave = async () => {
        let params = { nameEn, nameVi }
        let res = await manufactuterServices.edit(params, data.amanufacturerId)
        setActionStatus({ status: res ? 'success' : 'error' })
    }

    const clearModal = () => {
        setNameEn('');
        setNameVi('')
    }

    return (
        <>
            <Modal open={open} onClose={onClose} onBackdropClick={onClose}>
                <SuiBox sx={{ ...modal }} bgColor="white" borderRadius="md" shadow="md" width={500} >
                    <Grid container spacing={3} mb={3}>
                        <Grid item xs={12} >
                            <FormField type="text" label={t('manufacturer_name') + ' (VN)'} value={nameVi} onChange={(e) => setNameVi(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <FormField type="text" label={t('manufacturer_name') + ' (EN)'} value={nameEn} onChange={(e) => setNameEn(e.target.value)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} justifyContent={'flex-end'} >
                        <Grid item>
                            <SuiButton variant="outlined" color="info" onClick={onClose}>
                                {t('cancel')}
                            </SuiButton>
                        </Grid>
                        <Grid item>
                            <SuiButton variant="gradient" color="info" onClick={onSave}>
                                {t('save')}
                            </SuiButton>
                        </Grid>
                    </Grid>
                </SuiBox>
            </Modal>
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </>
    )
}

export default ModalEditManufacture