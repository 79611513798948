import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import styles from './styles';
import { styled } from '@mui/styles';
import Captcha from 'components/Captcha';
import FormCommentProduct from 'components/FormCommentProduct';


const StyledTabs = styled((props) => (
  <Tabs
    sx={{padding: '0px !important', borderRadius: '0px'}}
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
    '& .MuiTabs-root':{
        padding: '0px !important'
    },
    '& .MuiTabs-flexContainer': {
        display: 'block !important'
    },
    
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#b1000e'
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} 
    sx={
        { 
            padding: '0px 20px !important', 
            fontSize: '14px',
        }
    } 
/>)(
  ({ theme }) => ({
    maxWidth: '10px !important',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff !important',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

const CTabs = (
    {
        description = ''
    }
) => {
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCaptcha = (validated) =>{
        console.log(validated)
    }

    const classes = styles()
    
    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs onChange={handleChange} aria-label="lab API tabs example" value={value}>
                <StyledTab className={classes.conTab} label="Đặc tính nổi bật" value="1" />
                <StyledTab className={classes.conTab} label="Thông số kỹ thuật" value="2" />
                <StyledTab className={classes.conTab} label="Ứng dụng" value="3" />
                <StyledTab className={classes.conTab} label="Nhận xét" value="4" />
            </StyledTabs>
            </Box>
            <TabPanel value="1">
                <Typography dangerouslySetInnerHTML={{ __html: description }}></Typography>
            </TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">
                <FormCommentProduct/>
            </TabPanel>
        </TabContext>

        </Box>
    );
}

export default CTabs