import { makeStyles } from "@mui/styles";

export default makeStyles(() =>{
    return{
        conTab:{
            float: 'left',
            margin: 0,
            padding: 0,
            height: '31px',
            lineHeight: '31px',
            borderLeft: 'none',
            marginBottom: '-1px',
            overflow: 'hidden',
            position: 'relative'
        },
        tabItem:{
            fontSize: '14px',
            padding: '0px 20px'
        }
    }
})