import { Box } from '@mui/material';
import React from 'react'
import Carousel from 'react-material-ui-carousel';

const CSlide = () => {
    const images = [
        "http://redstarvietnam.com/media/banner/banner_82aa4b0a.jpg",
        "http://redstarvietnam.com/media/banner/banner_a5e00132.jpg",
        "http://redstarvietnam.com/media/banner/banner_8d5e957f.jpg",
        "http://redstarvietnam.com/media/banner/banner_47d1e990.jpg",
        "http://redstarvietnam.com/media/banner/banner_d645920e.jpg",
        "http://redstarvietnam.com/media/banner/banner_698d51a1.jpg",
        "http://redstarvietnam.com/media/banner/banner_a5771bce.jpg",

    ];

    return (
        <Carousel indicators={false} sx={{ height: '100%' }}>
            {images.map((each, index) => (
                <Box key={index} width={'100%'} height={'100%'} m={0.5} component={'div'}>
                    <img style={{ objectFit: "contain", width: "100%" }} alt="Slide Image" src={each} />
                </Box>

            ))}
        </Carousel>
    )
}

export default CSlide