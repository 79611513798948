import { Grid, Modal } from "@mui/material"
import modal from "assets/theme/base/modal"
import SuiBox from "components/SuiBox"
import { Player } from '@lottiefiles/react-lottie-player';
import SuiTypography from "components/SuiTypography";
import DeleteIcon from '@mui/icons-material/Delete';
import SuiButton from "components/SuiButton";
import { useTranslation } from "react-i18next";
import { LottieAnimation } from "constant";

const ModalDelete = ({ open = false, onClose = () => { }, title = '', onDelete = () => { } }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} onBackdropClick={onClose} disableAutoFocus>
            <SuiBox sx={{ ...modal }} bgColor="white" borderRadius="md" shadow="md">

                <SuiBox p={3}>
                    <SuiTypography variant="h5">{title}</SuiTypography>
                    <SuiBox my={2}>
                        <Player
                            autoplay={true}
                            loop={true}
                            src={LottieAnimation.delete}
                            style={{ width: 200 }}
                        />
                    </SuiBox>
                </SuiBox>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item>
                        <SuiButton variant="outlined"
                            color={'error'}
                            size={'small'}
                            onClick={onClose}
                        >
                            <SuiTypography variant="button" color={'error'}>{t('cancel')}</SuiTypography>
                        </SuiButton>
                    </Grid>

                    <Grid item>
                        <SuiButton variant="gradient"
                            color={'error'}
                            size={'small'}
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                            <SuiTypography ml={1} variant="button" color="white">{t('delete')}</SuiTypography>
                        </SuiButton>
                    </Grid>
                </Grid>
            </SuiBox>
        </Modal>
    )
}

export default ModalDelete