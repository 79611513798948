import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en-US.json";
import vi from "./locale/vi.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: en,
    },
    vi: {
      translations: vi,
    },
  },

  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "vi",
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

export default i18n;
