import { Box, Typography } from '@mui/material'
import React from 'react'
import styles from './styles'
import ProductCategory from '../product-category'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import FormOrder from 'components/FormOrder'

const OrderPage = () => {

    const classes = styles()
    const {t} = useTranslation()


    return (
       <>
        <Header/>
         <Box component={'div'} className={classes.conWrapper} mt={2}>
            <Box component={'div'} width={'100%'}>
                <Box component={'div'} className={classes.conCategorySideNav}>
                        <ProductCategory />
                </Box>
                <Box component={'div'} width={'1170px'} display={'block'} sx={{ float: 'right' }}>
                    <Typography sx={{fontWeight: 'bold !important', padding: '15px 0 !important'}}>{t('form_order_title')}:</Typography>
                    <FormOrder/>
                </Box>
            </Box>
        </Box>
       </>
    )
}

export default OrderPage