import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conSelector: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

        },
        conSelectorItem: {
            width: '90% !important'
        },
        boxItemSelector: {
            borderBottom: '1px dashed #ddd',
            backgroundColor: 'red'
        }
    }
})