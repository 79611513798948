import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { navbar } from "./styles";
import { useSoftUIController, setMiniSidenav } from "context";
import avatar from "assets/images/team-3.jpg";
import SuiAvatar from "components/SuiAvatar";
import pxToRem from "assets/theme/functions/pxToRem";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import SVGIcon from "components/Icon";
import { Assets } from "constant";
import i18n from "services/language/i18n";
import userServices from 'redux/user'

const AdminNavbar = () => {
    const { t } = useTranslation();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const [logo, setLogo] = useState(avatar);
    const [lang, setLang] = useState(i18n.language);
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            <MenuItem>
                <SuiBox display="flex" mr={1}>
                    <SVGIcon name="user-outlined" size={24} />
                </SuiBox>
                {t("profile_information")}
            </MenuItem>
            <MenuItem onClick={onLogout}>
                <SuiBox display="flex" mr={1}>
                    <SVGIcon name="sign-out" size={24} />
                </SuiBox>
                {t("sign_out")}
            </MenuItem>
        </Menu>
    );

    const onLogout = () => {
        userServices.signOutUser();
    }
    const onChangeLanguage = (value) => {
        setLang(value)
        i18n.changeLanguage(value);
        localStorage.setItem('lang', value)
    }

    return (
        <AppBar
            position={"sticky"}
            color="inherit"
            sx={(theme) => navbar(theme)}
        >
            <Toolbar>
                <SuiBox color="inherit" mb={{ xs: 1, md: 0 }}>
                    <Icon fontSize="medium" onClick={handleMiniSidenav}>
                        {miniSidenav ? "menu_open" : "menu"}
                    </Icon>
                </SuiBox>

                <SuiBox display="flex" alignItems="center">
                    <SuiBox display="flex" alignItems="center" mx={3} gap={1}>
                        <SuiBox component="img" src={Assets.FLAG_VN} onClick={() => onChangeLanguage('vi')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'vi' ? 1 : 0.3 }} />
                        <SuiBox component="img" src={Assets.FLAG_US} onClick={() => onChangeLanguage('en')} sx={{ width: pxToRem(30), cursor: 'pointer', opacity: lang === 'en' ? 1 : 0.3 }} />
                    </SuiBox>
                    <SuiBox display="flex" alignItems="center" onClick={handleOpenMenu} sx={{ cursor: 'pointer' }}>
                        <SuiTypography
                            variant="button"
                            fontWeight="medium"
                            color={"dark"}
                            mr={1}
                        >
                            Admin
                        </SuiTypography>
                        <SuiAvatar
                            src={logo}
                            alt="logo-image"
                            variant="circle"
                            customClass="shadow-sm"
                            size="sm"
                        />
                    </SuiBox>
                </SuiBox>
                {renderMenu()}
            </Toolbar>
        </AppBar>
    );
}

export default AdminNavbar;
