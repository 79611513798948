import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conImg: {
            width: '100%',
            height: '100%',
            float: 'left',
            marginRight: '20px'
        },
        textTitle: {
            fontSize: '18px !important',
            fontWeight: '700 !important',
            lineHeight: '26px !important',
            color: '#ED1B2F'
        },
        conSubTitle: {
            lineHeight: '22px !important',
            marginTop: '8px',
            fontSize: '14px',
            color: '#000'
        },
        btnViewDetail: {
            // display: 'block',
            color: '#fff',
            background: '#ed1b2f',
            marginTop: '20px',
            width: '70px',
            fontWeight: '300 !important',
            // marginLeft: '342px',
            border: '1px solid #ed1b2f',
            padding: '8px 4px !important'
        }
    }
})