import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonNewsServices from "redux/common/news";
import commonProductServices from "redux/common/product";
import  userServices from "redux/user";

const AdminProvider = ({ children }) => {
  const dispatch = useDispatch();
  const productCategory = useSelector(state => state.common.product_category);
  const newsCategory = useSelector(state => state.common.news_category)
  const token = localStorage.getItem('token')
  
  useEffect(() => {
    baseData()
  },[token])

  const baseData = async () => {
    if(!productCategory){
      await commonProductServices.getProductType(dispatch)
    }
    if(!newsCategory){
      await commonNewsServices.listCategory(dispatch)
    }
  }

  if (localStorage.getItem('token')) {
    return children;
  }
  return userServices.signOutUser();
};

export default AdminProvider;
