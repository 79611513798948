import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conSelector: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        conSelectorItem: {
            width: '90% !important'
        },
        conBoxProductItem: {
            // float: 'left',
            width: '100%',
            borderBottom: '1px dotted #d3d3d3',
            padding: '10px 0',
            display: 'flex',
            justifyContent: 'space-between'
        },
        conBoxProductImg: {
            maxWidth: '120px',
            maxHeight: '120px'
        },
        conBoxProductInfo: {
            color: '#000',
        },
        textProductName: {
            fontWeight: 'bold !important',
            textTransform: 'uppercase'
        }
    }
})