import { SET_NEWS_CATEGORY, SET_COMPANY_DETAILS, SET_PRODUCT_CATEGORY, PRODUCT_DETAILS, SET_PRODUCT_LIST } from "redux/types";

const initialState = {
    product_category: null,
    news_category: null,
    product_detail: null,
    product_list: null,
    company_details: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT_CATEGORY:
            return { ...state, product_category: action.payload };
        case SET_NEWS_CATEGORY:
            return { ...state, news_category: action.payload };
        case PRODUCT_DETAILS:
            return { ...state, product_detail: action.payload };
        case SET_PRODUCT_LIST:
            return { ...state, product_list: action.payload };
        case SET_COMPANY_DETAILS:
            return { ...state, company_details: action.payload };
        default:
            return state;
    }
}
