import { Box, Typography } from '@mui/material'
import React from 'react'
import styles from './styles'

const CAdFrame = (
    {
        title = ''
    }
) => {

    const classes = styles()

    return (
        <Box>
            <Box component={'div'} className={classes.conBoxTitle}>
                {/* icon */}
                <Typography variant='custWhite' sx={{ fontWeight: 'bold' }}>{title.toString().toUpperCase()}</Typography>
            </Box>
            <Box mt={1}>
                <Box height={'15rem'} overflow={'auto'} width={'100%'} component={'iframe'} src='https://www.youtube.com/embed/WNiyvbxlolM?list=PLB04tRIZiriKaypUyfaTwfvN6-WRzL4As'>

                </Box>
            </Box>
        </Box>
    )
}

export default CAdFrame