import { Card, Tooltip } from "@mui/material";
import AdminLayout from "components/Layout/AdminLayout";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useHistory } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SuiBadge from "components/SuiBadge";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDelete from "components/Modal/Delete";
import productServices from "redux/admin/product";
import { BASE_URL } from "services/axios";
import i18n from "services/language/i18n";
import commonProductServices from "redux/common/product";
import ModalSuccess from "components/Modal/Success";
import ModalError from "components/Modal/Error";
import { useDispatch } from "react-redux";



const ProductList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch()
    const [openDelete, setOpenDelete] = useState({ visible: false, data: null })
    const [actionStatus, setActionStatus] = useState({ status: '' })

    const [data, setData] = useState({
        columns: [
            { Header: "product_name", accessor: i18n.language === 'en' ? "nameEn": 'nameVi', width: "30%", isSort: true, Cell: ({ row }) => renderCellProduct(row.original) },
            { Header: "manufacturer", accessor: "manufacturer.nameVi", align: 'center', isSort: true, Cell: ({ row }) => renderCellManufacturer(row.original) },
            { Header: "Model", accessor: "model", align: 'center', isSort: true },
            { Header: "price", accessor: "price", width: '10%', align: 'center', isSort: true, Cell: ({ value }) => renderPrice(value), },
            { Header: "status", accessor: "qty", width: '10%', align: 'center', isSort: true, Cell: ({ value }) => (value > 0 ? inStock : outOfStock) },
            { Header: "", accessor: "action", width: '10%', align: 'center', Cell: ({ row }) => renderCellAction(row.original) },
        ],
        rows: []
    })
    // Badges
    const outOfStock = (
        <SuiBadge variant="contained" color="error" size="xs" badgeContent={t('out_of_stock')} container />
    );
    const inStock = (
        <SuiBadge variant="contained" color="success" size="xs" badgeContent={t('in_stock')} container />
    );

    useEffect(() => {
        getListProduct()
    }, [])

    const getListProduct = async () => {
        let res = await commonProductServices.getListProduct(dispatch);
        setData({ ...data, rows: res })
    }

    const renderCellProduct = (data) => {
        return (
            <SuiBox display="flex" alignItems="center">
                <SuiBox mx={2} width="3.75rem">
                    <SuiBox component="img" src={data.images ? data.images[0] : ""} alt={''} width="100%" />
                </SuiBox>
                <SuiTypography variant="button" fontWeight="medium">
                    {i18n.language === 'en' ? data.nameEn : data.nameVi}
                </SuiTypography>
            </SuiBox>
        )
    }

    const renderCellManufacturer = (data) => {
        return (
            <SuiTypography variant="caption" fontWeight="light">
                {i18n.language === 'en' ? data.manufacturer.nameEn : data.manufacturer.nameVi}
            </SuiTypography>
        )
    }

    const renderPrice = (data) => {
        return (
            <SuiTypography variant="caption" fontWeight="light">{data}</SuiTypography>
        )
    }

    const renderCellAction = (data) => {
        return (
            <SuiBox display="flex" gap={1} sx={{ cursor: 'pointer' }}>
                <Tooltip title={t('view')} placement="top">
                    <SuiBox bgColor="info" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <VisibilityIcon onClick={() => history.push(`/admin/product/view/${data.productId}`)} />
                    </SuiBox>
                </Tooltip>
                <Tooltip title={t('edit')} placement="top">
                    <SuiBox bgColor="warning" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <EditIcon onClick={() => history.push(`/admin/product/edit/${data.productId}`)} />
                    </SuiBox>
                </Tooltip>
                <Tooltip title={t('delete')} placement="top">
                    <SuiBox bgColor="error" variant="gradient" color="white" display="flex" alignItems="center" justifyContent="center" borderRadius="md" width={25} height={25}>
                        <DeleteIcon onClick={() => setOpenDelete({ visible: true, data: data.productId })} />
                    </SuiBox>
                </Tooltip>
            </SuiBox>
        )
    }

    const onDeleteProduct = async () => {
        let res = await productServices.deleteProduct(openDelete.data)
        if (res) {
            let tmp = data.rows.filter(item => item.productId !== openDelete.data.productId)
            setData({ ...data, rows: tmp })
        }
        setActionStatus({ status: res ? 'success' : 'error' })
        setOpenDelete({ visible: false, data: null });
        getListProduct();
    }

    return (
        <AdminLayout>
            <SuiBox my={3}>
                <Card>
                    <DataTable
                        table={data}
                        entriesPerPage={{
                            defaultValue: 20,
                            entries: [5, 7, 10, 15, 20, 25],
                        }}
                        canAdd={{
                            title: '+ ' + t('add_product'),
                            href: '/admin/product/new-product'
                        }}
                        canSearch
                    />
                </Card>
            </SuiBox>
            <ModalDelete
                title={t('delete_product_title') + ` ${i18n.language === 'en' ? openDelete.data?.nameEn : openDelete.data?.nameVi}`}
                open={openDelete.visible}
                onClose={() => setOpenDelete({ visible: false, data: null })}
                onDelete={onDeleteProduct}
            />
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
             
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default ProductList