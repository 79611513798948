import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles'
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge } from 'react-simple-captcha'

const FormOrder = () => {

    const {t} = useTranslation()
    const classes = styles()

    useEffect(()=>{
        reloadCaptcha()
    },[])

    const reloadCaptcha = () =>{
        loadCaptchaEnginge(8, 'white', 'red')
    }

    return (
        <Box>
            <Box component={'div'} display={'flex'} alignItems={'center'}>
                <Box component={'div'} className={classes.label}>
                    <label >{t('form_order_name')} <span style={{color: 'red'}}>*</span></label>
                </Box>
                <input size={40}/>
            </Box>
            
            <Box component={'div'} display={'flex'} alignItems={'center'} mt={1}>
                <Box component={'div'} className={classes.label}>
                    <label >Email <span style={{color: 'red'}}>*</span></label>
                </Box>
                <input size={40}/>
            </Box>
            <Box component={'div'} display={'flex'} alignItems={'center'} mt={1}>
                <Box component={'div'} className={classes.label}>
                    <label >{t('form_order_phone')} <span style={{color: 'red'}}>*</span></label>
                </Box>
                <input size={40}/>
            </Box>
             <Box component={'div'} display={'flex'} alignItems={'center'} mt={1}>
                <Box component={'div'} className={classes.label}>
                    <label >{t('form_order_contact')}</label>
                </Box>
                <textarea cols="41" rows="8"/>
            </Box>
            <Box component={'div'} display={'flex'} alignItems={'center'} mt={1}>
                <Box component={'div'} className={classes.label}>
                    <label >{t('form_order_captcha_code')} <span style={{color: 'red'}}>*</span></label>
                </Box>
                <input size={10}/>
             
                <LoadCanvasTemplateNoReload/>
                   
                <Typography component={'span'} sx={{color: '#0D0DEE', cursor: 'pointer'}} onClick={reloadCaptcha}>
                    {t('reload_captcha_label')}
                </Typography>
            </Box>
            <Box mt={2} component={'div'} display={'flex'} alignItems={'center'}>
                <Box component={'div'} className={classes.label}>
                    
                </Box>
                <button> {t('form_order_send_contact')}</button>
            </Box>
        </Box>
    )
}

export default FormOrder