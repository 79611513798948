import { makeStyles } from "@mui/styles";
import { Layout_Config } from "config";

export default makeStyles(() => {
    return {
        conWrapper: {
            width: Layout_Config.minWidth,
            margin: '0px auto',
            position: 'relative'
        },
        conCategorySideNav: {
            width: '100px',
            float: 'left',

        },
        conContent: {
            width: '1170px',
            float: 'right'
        },
        conImgBox: {
            width: '368px',
            float: 'left',
            // overflow: 'hidden',
            marginRight: '14px !important',
            position: 'relative'
        },
        conImgShow: {
            zIndex: 2,
            border: '1px solid #d3d3d3',
            background: '#fff',
            textAlign: 'center'
        },
        conImageListItem: {
            cursor: 'pointer',
            height: 100,
            objectFit: 'cover',
            borderRadius: '0px'
        },
        conBoxInfo: {
            width: '688px',
            float: 'right',
            padding: '15px 0',
            borderTop: '1px solid #d3d3d3'
        },
        conWrapperOtherProduct: {
            height: '35px',
            borderBottom: 'solid 1px #ddd',
            marginBottom: '30px'
        },
        textSameProduct: {
            fontSize: '16px !important',
            fontWeight: 'bold !important',
            margin: '19px 0px !important',
            color: '#444',
            paddingLeft: '5px',
            float: 'left',
            background: 'white',
            paddingRight: '5px'
        },
        conMobileSlide: {
            width: '100%',
            // backfaceVisibility: 'hidden',
            // overflow: 'hidden',
            // position: 'absolute',
            // transform: 'matrix(1, 0, 0, 1, 310, 0)',
            height: '140px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center'

        },
        boxSlide: {
            borderBottom: '2px solid #dfdfdf'
        },
        conMobileInfo: {
            padding: '10px 0 10px 0px',
            lineHeight: '22px'
        },
        textFeature: {
            fontSize: '15px !important',
            textTransform: 'uppercase',
            lineHeight: '40px !important',
            color: '#034EA2',
            display: 'block',
            fontWeight: 'bold !important'
        }
    }
})