import { Box } from '@mui/material'
import React from 'react'

const CBrandSlide = () => {
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_9b861925.png' />
            </Box>
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_76dc611d.jpg' />
            </Box>
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_8f855179.jpg' />
            </Box>
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_82161242.jpg' />
            </Box>
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_bf822969.png' />
            </Box>
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_1afa34a7.png' />
            </Box>
            <Box component={'a'} href='#' height={{ xs: 50, md: 50 }}>
                <Box height={'100%'} width={'100%'} component={'img'} src='http://redstarvietnam.com/media/banner/banner_1ff8a7b5.png' />
            </Box>
        </Box>
    )
}

export default CBrandSlide