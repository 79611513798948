import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import pattern from "assets/images/shapes/pattern-lines.svg";

function SignInLayout({ title, description, image, children }) {
    return (
        <PageLayout background="white">
            <Grid container>
                <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
                    <SuiBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
                        <SuiBox pt={3} px={3}>
                            <SuiBox mb={1}>
                                <SuiTypography variant="h4" fontWeight="bold">
                                    {title}
                                </SuiTypography>
                            </SuiBox>
                            <SuiTypography variant="body2" fontWeight="regular" color="text">
                                {description}
                            </SuiTypography>
                        </SuiBox>
                        <SuiBox p={3}>{children}</SuiBox>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SuiBox
                        display={{ xs: "none", lg: "flex" }}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        width="calc(100% - 2rem)"
                        height="calc(100vh - 2rem)"
                        position="relative"
                        borderRadius="lg"
                        textAlign="center"
                        bgColor={'info'}
                        variant="gradient"
                        m={2}
                        px={13}
                        sx={{ overflow: "hidden" }}
                    >
                        <SuiBox
                            component="img"
                            src={pattern}
                            alt="pattern-lines"
                            width="120rem"
                            position="absolute"
                            topl={0}
                            left={0}
                            opacity={0.4}
                        />
                        {image && (
                            <SuiBox
                                component="img"
                                src={image}
                                alt="chat-illustration"
                                width="100%"
                                maxWidth="31.25rem"
                            />
                        )}
                    </SuiBox>
                </Grid>
            </Grid>
        </PageLayout >
    );
}

// Setting default values for the props of IllustrationLayout
SignInLayout.defaultProps = {
    title: "",
    description: "",
    image: '',
};

// Typechecking props for the IllustrationLayout
SignInLayout.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default SignInLayout;
