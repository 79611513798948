const { makeStyles } = require("@mui/styles");

export default makeStyles(() => {
    return {
        conBoxItem: {
            height: '100%',
            overflow: 'hidden',
            cursor: 'pointer',
            border: '1px solid #ddd !important',
            marginBottom: '-1px'
        },
        conImg: {
            display: 'inherit',

        },
        boxInfo: {
            padding: '10px 10px 0 10px'
        },
        boxTag: {
            background: '#FED501',
            marginTop: '5px',
            marginRight: '5px',
            padding: '0.1rem 0.5rem',
            border: '1px #FFD913 solid',
            borderRadius: '2px',
            fontWeight: 'bold',
            color: '#4D4B4C',
            float: 'right',
        },
        textName: {
            display: 'block',
            fontSize: '13px !important',
            color: '#222',
            fontWeight: 'bold !important',
            marginTop: '2px !important',
            marginBottom: '3px !important',
            padding: '0px 0px'
        },
        textContact:
        {
            display: 'table-cell',
            verticalAlign: 'bottom',
            color: '#c70101',
            fontWeight: 'bold !important',
            fontSize: '12px',
            lineHeight: '18px'
        },
        customPopoverPaper: {
            padding: "0 !important",
            color: "white",
            marginTop: '1rem !important',
            // border: 'red 1px solid',
            margin: 0,
            borderRadius: '0px !important'
        },
        conPopDetail: {
            width: '100%',
            height: '100%',
            background: 'white'
        },
        textDescription: {

        },
        conCompareCheckBox:{
            position: 'absolute',
            float: 'right',
            bottom: 0,
            right: 0,
            background: 'white',
            zIndex: 1001
        }
    }
})