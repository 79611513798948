import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Base64 } from "js-base64";
import { PUBLIC_API } from "redux/api";

const domain_config = {
  production_mode: false,
  developmentUrl: 'http://scnvn.com/medical',
  productionUrl: window.origin,
}

export const BASE_URL = domain_config.production_mode ? domain_config.productionUrl : domain_config.developmentUrl

const instance = axios.create({
  baseURL: BASE_URL,
});
instance.interceptors.request.use(
  (request) => {
    request.headers.Authorization = "Bearer " + localStorage.getItem('token');
    return request;
  },
  (error) => Promise.reject(error)
);

const refreshAuthLogic = (failedRequest) => {
  const username = Base64.decode(localStorage.getItem("username"));
  const password = Base64.decode(localStorage.getItem("password"));
  const body = new FormData();
  body.append("username", username);
  body.append("password", password);
  body.append("grant_type", "password");

  const authorizationString = `Basic ${Base64.encode(
    `${username}:${password}`
  )}`;
  return axios
    .post(BASE_URL + PUBLIC_API.OAUTH, body, {
      headers: {
        Authorization: authorizationString,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem('expired_in', response.data.expires_in)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        localStorage.setItem('fullClient', JSON.stringify(response.data))
        failedRequest.response.config.headers.Authorization =
          "Bearer " + response.data.access_token;
        return Promise.resolve();
      } else {
        // userServices.SignOutUser()
        return Promise.reject();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status == 401) {
        // userServices.SignOutUser()
      }
    });
};
createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
