import { makeStyles } from "@mui/styles";

export default makeStyles(()=>{
    return{
        listItem:{
            marginBottom:'2px',
            verticalAlign: 'center',
            cursor: 'pointer'
        },
        activeListItem:{
            background: '#eb1c24 !important'
        },
        conItemCategory:{
            background: '#6e6566',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '5px'
        },
        textItemCategoryName:{
            color: 'white'
        },
        conIcon:{
            width: '30%',
            marginTop: '8px',
            marginBottom: '4px'
        }
    }
})