const { makeStyles } = require("@mui/styles");

export default makeStyles(() => {
    return {
        conBoxTitle: {
            backgroundColor: '#EEE',
            width: '100%',
            display: 'flex'
        },
        boxTitle: {
            background: '#D30F43',
            padding: '5px 2rem'
        }
    }
})