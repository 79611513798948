import { Box, Popover, Slide, Typography } from '@mui/material'
import React from 'react'
import styles from './styles'
import { useState } from 'react'
import i18n from 'services/language/i18n'
import { rewriteUrlPath } from "utils/convertPathUrl";
import { useTranslation } from 'react-i18next'

const CCardItemProduct = (
    {
        productItem = {},
        isNewProduct = false,
        checkBoxCompare = false
    }
) => {
    const { t } = useTranslation()
    const classes = styles()
    const [containerEl, setContainerEl] = useState(null)

    const handleOpenPop = (e) => {
        setContainerEl(e.currentTarget)
    }

    const handleClosePop = () => {
        setContainerEl(null)
    }

    const openPop = Boolean(containerEl)



    return (
        <Box component={'a'} href={`/product/${rewriteUrlPath(productItem?.nameEn)}.html&query=${productItem?.productId}`}>
            <Box onMouseEnter={handleOpenPop}
                onMouseLeave={handleClosePop}
                component={'div'}
                className={classes.conBoxItem} position={'relative'} zIndex={1000}>
                {
                    checkBoxCompare && (
                        <Box component={'div'} className={classes.conCompareCheckBox}>
                            <input type='checkbox' />
                        </Box>
                    )
                }

                <Slide direction='up' in={openPop} >
                    <Box component={'div'} className={classes.conPopDetail} position={'absolute'}>
                        <Typography className={classes.textName} sx={{ color: '#044FA2' }}>{i18n.language === 'en' ? productItem?.nameEn : productItem?.nameVi}</Typography>
                        <Typography className={classes.textContact}>{t('contact_price_label')}</Typography>
                        <Typography sx={{ color: '#333' }} dangerouslySetInnerHTML={{ __html: productItem?.descriptionEn }}>
                        </Typography>
                    </Box>
                </Slide>
                <Box component={'a'} href='#'>
                    <Box
                        display={'inline-block'}
                    >
                        {isNewProduct && (
                            <Box component={'div'} className={classes.boxTag}>
                                <Typography sx={{ fontWeight: 'bold' }}>{t('new')}</Typography>
                            </Box>
                        )}
                        <Box
                            component={'img'}
                            src={productItem?.images && productItem?.images.length > 0 ? productItem?.images[0] : 'http://vietkhanggroup.vn/content/product/product_50_0.jpg'}
                            alt='product-image'
                            height={{ xs: 100, md: 150 }}
                            width="100%"
                            display='inherit'
                            borderRadius="md"
                            className="product-image"

                        />

                    </Box>
                    <Box component={'div'} my={2} className={classes.boxInfo}>
                        <Typography className={classes.textName}>{i18n.language === 'en' ? productItem?.nameEn : productItem?.nameVi}</Typography>
                        <Typography className={classes.textContact}>{t('contact_label')}</Typography>
                        <Typography component={'span'}
                            sx={{ color: '#767676', fontSize: '11px' }}>
                            {t('status_label')}:&nbsp;
                            <Typography
                                component={'span'}
                                sx={{ color: productItem?.qty && productItem?.qty > 0 ? '#258905' : 'red' }}
                            >
                                {t('exist_product_label')}</Typography>
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default CCardItemProduct