import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conHeader: {
            background: '#024AA0 !important',
            padding: '3px',
            lineHeight: '28px',
            paddingLeft: '1rem',
            textAlign: 'center'
        },
        conBoxInfo: {
            position: 'absolute',
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
            padding: '10px'
        },
        conItem: {
            display: 'flex',
            cursor: 'pointer'
        },
        textInfo: {
            fontWeight: 'bold !important',
            fontSize: '13px !important',
            color: 'white !important'
        },
        conTextTitle: {
            marginLeft: '1rem',
            color: 'red !important'
        },
        textTitle: {
            fontWeight: 'bold !important',
            padding: '0px 2px',
            color: '#000',
            fontSize: '13px !important',
            lineHeight: '16px !important',
            overflow: 'hidden',
            "&:hover": {
                textDecoration: 'underline !important'
            }
        }
    }
})