// LOGO
import LOGO from 'assets/images/vietkhang/logo.png'
// FLAG
import FLAG_VN from 'assets/images/flag/vn.png'
import FLAG_US from 'assets/images/flag/us.png'
// FOOTER
import ZALO from "assets/images/vietkhang/logo_zalo.png";

// USER LANDING PAGE
import bgHeader from 'assets/images/vietkhang/bg-header.png'
import manuFacture from 'assets/images/vietkhang/manufacturing.png'
import services24 from 'assets/images/vietkhang/24-hours.png'
import quality from 'assets/images/vietkhang/quality.png'
import onlineSupport from 'assets/images/vietkhang/online-support.png'

export const Assets = {
    LOGO,
    FLAG_VN,
    FLAG_US,
    bgHeader,
    manuFacture,
    services24,
    quality,
    onlineSupport,
    ZALO
}

export const COLOR = {
    MAIN: '#0e4d2f',
    TEXT: '#058548'
}

export const LottieAnimation = {
    delete: require("assets/lottie-file/delete.json"),
    success: require("assets/lottie-file/success.json"),
    error: require("assets/lottie-file/error.json"),
    no_result: require("assets/lottie-file/no-search-result.json"),
}