import { Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react'
import styles from './styles'
import i18n from 'services/language/i18n'

const CListNews = (
    {
        title = '',
        listNews = []
    }
) => {

    const classes = styles()


    const renderFirstNew = (item) => {
        return (
            <Box component={'a'} href='#' width={'100%'} position={'relative'} display={'block'}>
                <Box component={'div'} className={classes.conBoxInfo}>
                    <Typography variant='custWhite' fontWeight={'bold'} fontSize={'13px'}>
                        {i18n.language === 'vi' ? item?.titleVi : item?.titleEn}
                    </Typography>
                    <br />
                    <Typography variant='custWhite' fontWeight={'11px'} lineHeight={'18px'}>
                        {item?.createDate}
                    </Typography>
                </Box>
                <Box overflow={'hidden'} display={'inherit'} width={'100%'} height={{ xs: 170, md: 220 }} component={'img'} src={item.preview}>
                </Box>

            </Box>
        )
    }

    const renderNew = (item) => {
        return (
            <Box component={'a'} className={classes.conItem} href='#'>
                <ListItemAvatar sx={{ maxWidth: '60%' }}>
                    <Box height={'100%'} width={'100%'} component={'img'} src={item.preview}>

                    </Box>
                </ListItemAvatar>
                <ListItemText className={classes.conTextTitle}>
                    <Typography className={classes.textTitle} component={'a'} href='#'>
                        {i18n.language === 'vi' ? item?.titleVi : item?.titleEn}
                    </Typography>
                    <br />
                    <Typography variant='custWhite' sx={{ color: '#999' }} fontWeight={'11px'} lineHeight={'18px'}>
                        {item?.createDate}
                    </Typography>
                </ListItemText>
            </Box>
        )
    }

    return (
        <Box>
            <Box component={'div'} className={classes.conHeader} mt={1}>
                {/* icon */}
                <Typography variant='custWhite' sx={{ fontWeight: 'bold' }}>{title.toString().toUpperCase()}</Typography>
            </Box>
            <List>
                {
                    listNews.length > 0 && listNews.map((news, index) => {
                        if (index == 0) {
                            return (
                                <>
                                    <ListItem >
                                        {renderFirstNew(news)}
                                    </ListItem>
                                    <Divider />
                                </>


                            )
                        }
                        else {
                            return (
                                <>
                                    <ListItem >
                                        {renderNew(news)}
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        }
                    })
                }
            </List>
        </Box >
    )
}

export default CListNews