import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminLayout from 'components/Layout/AdminLayout'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton';
import SuiAvatar from 'components/SuiAvatar';

import images0 from "assets/images/team-3.jpg";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FormField from 'components/FormField';
import { Card, Grid } from '@mui/material';
import companyServices from 'redux/admin/company';
import { useDispatch } from 'react-redux';
import ModalSuccess from 'components/Modal/Success';
import ModalError from 'components/Modal/Error';


const CompanyManagement = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [logo, setLogo] = useState(null)
    const [fileUpload, setFileUpload] = useState(null);
    const [tmp, setTmp] = useState(null)
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [actionStatus, setActionStatus] = useState({ status: '' })

    useEffect(() => {
        getCompanyInfo();
    }, [])

    const getCompanyInfo = async () => {
        let res = await companyServices.getInformation(dispatch);
        if (res) {
            setTmp(res)
            setCompanyName(res.name)
            setPhone(res.hotline)
            setEmail(res.email)
            setAddress(res.address)
            setLogo(res.logoUrl)
        }
    }

    const onChangeLogo = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        setFileUpload(file);
        reader.addEventListener("load", () => setLogo(reader.result), false);
        reader.readAsDataURL(file);
    };

    const onCancel = () => {
        setCompanyName(tmp.name)
        setPhone(tmp.hotline)
        setEmail(tmp.email)
        setAddress(tmp.address)
    }

    const onSave = async () => {
        const body = new FormData();
        body.append('name', companyName);
        body.append('hotline', phone);
        body.append('address', address);
        body.append('email', email);
        body.append('file', fileUpload);
        let res = await companyServices.editCompanyInfo(body);
        setActionStatus({ status: res ? "success" : "error" })
        if (res) {
            getCompanyInfo();
        }
    }

    return (
        <AdminLayout>
            <SuiBox my={10}>
                <Grid container display="flex" alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} lg={6}>
                        <Card>
                            <Grid item xs={12}>
                                <SuiBox p={2} alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                                    <SuiBox position="relative">
                                        <SuiAvatar
                                            src={logo}
                                            alt="logo-image"
                                            variant="rounded"
                                            size="xxl"
                                            customClass="shadow-sm"
                                        />
                                        <label htmlFor="upload-logo">
                                            <input accept="image/*" id="upload-logo" type="file" onChange={onChangeLogo} style={{ display: 'none' }} />
                                            <SuiBox position="absolute" bottom={-10} right={-20} p={1} alignItems="center" justifyContent="center" display="flex" bgColor="white" borderRadius="50%" shadow="lg">
                                                <PhotoCameraIcon />
                                            </SuiBox>
                                        </label>
                                    </SuiBox>
                                </SuiBox>

                                {/* {fileUpload &&
                                    <SuiBox p={2}>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item>
                                                <SuiButton variant="outlined" color="info" size="small" onClick={onCancelUpload} fullWidth>
                                                    {t('cancel')}
                                                </SuiButton>
                                            </Grid>
                                            <Grid item>
                                                <SuiButton variant="gradient" color="info" size="small" onClick={onSaveUpload} fullWidth>
                                                    {t('save')}
                                                </SuiButton>
                                            </Grid>

                                        </Grid>
                                    </SuiBox>
                                } */}
                            </Grid>
                            <Grid item xs={12}>
                                <SuiBox px={3} pb={2}>
                                    <FormField type="text" label={t('company_name')} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                </SuiBox>
                            </Grid>
                            <Grid item xs={12}>
                                <SuiBox px={3} pb={2}>
                                    <FormField type="text" label={t('phone')} value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </SuiBox>
                            </Grid>
                            <Grid item xs={12}>
                                <SuiBox px={3} pb={2}>
                                    <FormField type="text" label={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                </SuiBox>
                            </Grid>
                            <Grid item xs={12}>
                                <SuiBox px={3} pb={2}>
                                    <FormField type="text" label={t('address')} value={address} onChange={(e) => setAddress(e.target.value)} />
                                </SuiBox>
                            </Grid>
                            <Grid item xs={12}>
                                <SuiBox p={2}>
                                    <Grid container spacing={3} justifyContent="center">
                                        <Grid item>
                                            <SuiButton variant="outlined" color="info" size="small" onClick={onCancel} fullWidth>
                                                {t('cancel')}
                                            </SuiButton>
                                        </Grid>
                                        <Grid item>
                                            <SuiButton variant="gradient" color="info" size="small" onClick={onSave} fullWidth>
                                                {t('save')}
                                            </SuiButton>
                                        </Grid>

                                    </Grid>
                                </SuiBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox>
            <ModalSuccess
                open={actionStatus.status === 'success'}
                onClose={() => setActionStatus({ status: "" })}
            />
            <ModalError
                title={t('an_error_occured')}
                open={actionStatus.status === 'error'}
                onClose={() => setActionStatus({ status: "" })}
            />
        </AdminLayout>
    )
}

export default CompanyManagement