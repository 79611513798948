export const Layout_Config = {
    minWidth: '1280px',
    mobile_maxWidth: '310px'
}

export const Data_Pageable = {
    itemPerPage: 10
}

export const System_Config = {
    enableLog: true
}