import { makeStyles } from "@mui/styles";

export default makeStyles(() => {
    return {
        conBreadCrumbs: {
            fontWeight: '600 !important',
            fontSize: '13px !important'
        },
        unactiveItem: {
            color: '#044FA2 !important'
        },
        activeItem: {
            color: 'red !important'
        }
    }
})