import { Box, Popper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    customPopoverPaper: {
        padding: "0 !important",
        color: "white",
        marginTop: '1rem !important',
        // border: 'red 1px solid',
        margin: 0,
        borderRadius: '0px !important'
    }

}));
const CPopover = (
    {
        titleCell = () => { },
        contentCell = () => { },
        transformOriginProp = {
            vertical: "top",
            horizontal: "right",
        },
        anchorOriginProp = {
            vertical: "bottom",
            horizontal: "center",
        }
    }
) => {

    const classes = useStyles();

    const [containerEl, setContainerEl] = useState(null);

    const handleOpen = (e) => {
        setContainerEl(e.currentTarget);
    };

    const handleClose = () => {
        setContainerEl(null);
    };

    const open = Boolean(containerEl);

    return (
        <Box
            component={'div'}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
        >
            {titleCell()}
            <Popper
                id="mouse-over-popover-header"
                sx={{
                    pointerEvents: 'auto',
                    zIndex: 100001
                }}
                open={open}
                anchorEl={containerEl}
                onClose={handleClose}
                anchorOrigin={anchorOriginProp}
                classes={{
                    paper: classes.customPopoverPaper,
                    root: classes.customPopoverPaper
                }}
                transformOrigin={transformOriginProp}

                // PaperProps={{ onMouseEnter: handleOpen, onMouseLeave: handleClose }}
                disableRestoreFocus
            >
                {contentCell()}
            </Popper>
        </Box>
    )
}

export default CPopover